import React from 'react'
import { Asset } from '@commonstock/common/src/types'
import { Routes } from '../nav/constants'
import { SecondaryButton } from '../../composing/CSButton'
import { useBroker } from '../broker/brokerHooks'
import { usePortfolio } from '../portfolio/PortfolioHooks'
import { useLinkBroker } from '../broker/BrokeragePrivacyOptions'
import { useGoto } from '../nav/navHooks'
import Tooltip from '../../components/Tooltip'
import { SpacerH } from '../../composing/Spacing'
import { paneHeaderButton, PaneHeaderLabel } from 'src/components/styles'
import { cx } from '@linaria/core'
import { useTheme } from 'src/theme/ThemeContext'

const AssetOrderLinks = ({ asset }: { asset?: Asset }) => {
  const { accounts } = useBroker()
  const { holdings } = usePortfolio()

  const openLinkBroker = useLinkBroker()

  const goToBuy = useGoto(asset ? Routes.tradeIntent('buy', asset.symbol, asset.type) : '')
  const goToSell = useGoto(asset ? Routes.tradeIntent('sell', asset.symbol, asset.type) : '')

  const position = holdings?.find(h => asset && h.ticker === asset.symbol)
  const { isMobile } = useTheme()

  if (!asset) return null
  return (
    <>
      <Tooltip tip={position ? 'Sell asset' : undefined}>
        <SecondaryButton
          className={cx(paneHeaderButton)}
          disabled={!position}
          onClick={accounts.length ? goToSell : openLinkBroker}
          mobile={isMobile}
        >
          <PaneHeaderLabel>Sell</PaneHeaderLabel>
        </SecondaryButton>
      </Tooltip>
      <SpacerH rem={0.5} />
      <Tooltip tip={'Buy asset'}>
        <SecondaryButton
          className={cx(paneHeaderButton)}
          onClick={accounts.length ? goToBuy : openLinkBroker}
          mobile={isMobile}
        >
          <PaneHeaderLabel>Buy</PaneHeaderLabel>
        </SecondaryButton>
      </Tooltip>
    </>
  )
}

export default AssetOrderLinks
