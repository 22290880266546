import { createUseFetch } from '@commonstock/client/src/react/createUseFetch'
import { createUsePagedFetch, PagerOptions } from '@commonstock/client/src/react/createUsePagedFetch'
import cacheLast from '@commonstock/client/src/utils/cacheLast'
import { Periods } from '../types'
import { TradeFeedItem, TradeFeedOutput } from './feed'

const tradeFeedOutputPagerOptions: PagerOptions<TradeFeedOutput, DefaultQueryParams> = {
  getCursor: (page, resource) => {
    let privacy = resource?.params.query.privacy
    let list = resource?.success?.payload.items
    let last = list && list[list.length - 1]
    // @NOTE: All trades except for portfolio page, should have privacy 'on'
    return { query: { ...(resource?.params.query || {}), limit: 10, last_order: last?.uuid, privacy: privacy || 'on' } }
  },
  isTerminal: ({ success }) => !!(success && success.payload.items.length === 0)
}

type DefaultQueryParams = {
  query: {
    // @TODO: Check this schema with BE
    last_order?: string
    limit?: number
    privacy: 'on' | 'off'
    order_side?: string
  }
}

export type UserTradeParams = DefaultQueryParams & {
  meta: {
    user_uuid: string
  }
  query: {
    period: Periods
  }
}

export const USER_TRADES_PATH = `/feed/v3/user-trades/`
export const useGetUserTrades = createUsePagedFetch<TradeFeedOutput, UserTradeParams>(
  {
    key: 'get-user-trades',
    path: ({ meta: { user_uuid } }) => `${USER_TRADES_PATH}${user_uuid}`
  },
  tradeFeedOutputPagerOptions
)

export type MyAssetTradesParams = DefaultQueryParams & {
  meta: {
    user_uuid: string
    symbol: string
    type: string
  }
  query: {
    exclude_options?: boolean
    period?: Periods
  }
}

export const useGetUserAssetTrades = createUseFetch<TradeFeedOutput, MyAssetTradesParams>({
  key: 'user-asset-trades',
  path: ({ meta: { user_uuid, symbol, type } }) => `/feed/v3/user-trades/${user_uuid}/${symbol}:${type}`
})

export type AssetTradesParams = DefaultQueryParams & {
  meta: {
    symbol: string
    type: string
  }
  query: {
    security?: string
    option_type?: string
    interval: Periods
  }
}

export const useGetCachedUserAssetTrades = cacheLast(useGetUserAssetTrades)

export const ASSET_TRADES_PATH = `/feed/v3/asset-trades/`
export const useGetAssetTrades = createUsePagedFetch<TradeFeedOutput, AssetTradesParams>(
  {
    key: 'get-asset-trades',
    path: ({ meta: { symbol, type } }) => `${ASSET_TRADES_PATH}${symbol}:${type}`
  },
  tradeFeedOutputPagerOptions
)

export const BASE_TRADE_PATH = '/feed/v3/trade/'
type TradeParams = { meta: { tradeId: string } }
export const useGetTrade = createUseFetch<TradeFeedItem, TradeParams>({
  key: 'get-trade',
  path: ({ meta: { tradeId } }) => `${BASE_TRADE_PATH}${tradeId}`
})
