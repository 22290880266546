import React, { ReactNode, useMemo, createContext, useContext, useState, useEffect } from 'react'

type DragContextValue = {
  isDragOver: boolean
}

const DragContext = createContext<DragContextValue>({
  isDragOver: false
})

DragContext.displayName = 'DragContext'

type DragProps = { children: ReactNode }

function DragController({ children }: DragProps) {
  const [isDragOver, setIsDragOver] = useState(false)
  let value: DragContextValue = useMemo(() => {
    return {
      isDragOver
    }
  }, [isDragOver])

  useEffect(() => {
    let dragOverTimeout: NodeJS.Timeout | null = null

    function enableDragover() {
      dragOverTimeout && clearTimeout(dragOverTimeout)
      if (!isDragOver) {
        setIsDragOver(true)
      }
    }

    function disableDragover() {
      dragOverTimeout && clearTimeout(dragOverTimeout)
      if (isDragOver) {
        dragOverTimeout = setTimeout(() => {
          setIsDragOver(false)
        }, 500)
      }
    }

    const body = document.body

    body.addEventListener('dragover', enableDragover, false)
    body.addEventListener('drop', disableDragover, false)
    body.addEventListener('dragleave', disableDragover, false)
    // body.addEventListener('drop', disableDragover, false)
    return () => {
      body.removeEventListener('dragover', enableDragover, false)
      body.removeEventListener('drop', disableDragover, false)
      body.removeEventListener('dragleave', disableDragover, false)
      // body.addEventListener('drop', disableDragover, false)
    }
  }, [isDragOver])

  return <DragContext.Provider value={value}>{children}</DragContext.Provider>
}

function useDrag() {
  return useContext(DragContext)
}

export { DragController, useDrag }
