import mixpanel from 'mixpanel-browser' // eslint-disable-line

import { track } from '../mixpanel'
import { getIsTracked, setIsTracked } from '../utils'

const BRANCH_MATCH_ID = '_branch_match_id'
const UTM_CAMPAIGN_TRACKED = 'UTM Campaign Tracked'

export function trackUTMCampaign(searchParams: URLSearchParams) {
  if (!searchParams || !searchParams.get(BRANCH_MATCH_ID)) {
    return
  }
  const source = searchParams.get('utm_source')
  const campaign = searchParams.get('utm_campaign')
  const medium = searchParams.get('utm_medium')

  const isAlreadyTracked = getIsTracked(UTM_CAMPAIGN_TRACKED)
  if (isAlreadyTracked) {
    return
  }
  // don't fire event again unless 10 minutes has passed
  setIsTracked(UTM_CAMPAIGN_TRACKED, 600000)

  track(UTM_CAMPAIGN_TRACKED, { utm_source: source, utm_campaign: campaign, utm_medium: medium })
  mixpanel.people.set_once({ first_utm_source: source, first_utm_campaign: campaign, first_utm_medium: medium })
}
