import { DragEvent, useCallback, useState, useMemo, useEffect } from 'react'
import { DateTime, LocaleOptions } from 'luxon'
import { Message, isAdminMessage, isFileMessage, isUserMessage, Messages } from './types'
import { sendbird } from './ChatContext'
import { useChannels } from '../chat/ChannelContext'
import { GroupChannel, UserMessage } from 'sendbird'
import { isFileAttachment, isGifAttachment, AttachmentType, IAttachment } from '../../components/Attachment'
import { MessageData } from '@commonstock/common/src/types/chat'
import { useCreateConversation } from '@commonstock/common/src/api/chat'
import useEventListener from '@commonstock/common/src/utils/useEventListener'
import { trackChatMessageSent } from '../analytics/events.v1'
import { track } from '../analytics/mixpanel'
import { PostStage } from '@commonstock/common/src/types'
import { captureException } from '../../dev/sentry'
import config from '../../config'

export function blockEvent(e: DragEvent<HTMLDivElement>) {
  e.preventDefault()
  e.stopPropagation()
}

const TIME_GAP = 5 * 60 * 1000
const today = DateTime.local()
  .startOf('day')
  .toMillis()
const yesterday = DateTime.local()
  .plus({ days: -1 })
  .startOf('day')
  .toMillis()

function useMessageComparing() {
  const isMessageFromUser = useCallback(
    (m: Message, userId: string) => !isAdminMessage(m) && m.sender.userId === userId,
    []
  )

  const isSameDay = useCallback((m1: Message, m2: Message) => {
    const date1 = new Date(m1.createdAt)
    const date2 = new Date(m2.createdAt)
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    )
  }, [])

  const isSameUser = useCallback((m1: Message, m2: Message) => {
    if (m1.customType === 'trade_alert' && m2.customType === 'trade_alert') {
      try {
        const trade1 = m1.data && JSON.parse(m1.data)
        const trade2 = m2.data && JSON.parse(m2.data)
        return trade1.user.uuid === trade2.user.uuid
      } catch (err) {
        console.error('failed to parse trade alert')
        return false
      }
    } else {
      return !isAdminMessage(m1) && !isAdminMessage(m2) && m1.sender.userId === m2.sender.userId
    }
  }, [])

  const isshortGapped = useCallback((a: Message, b: Message) => Math.abs(a.createdAt - b.createdAt) < TIME_GAP, [])

  const getDateString = useCallback((m: Message) => {
    const messageDate = DateTime.fromMillis(m.createdAt).startOf('day')
    const options: LocaleOptions & Intl.DateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric' }
    return messageDate.toMillis() === today
      ? 'Today'
      : messageDate.toMillis() === yesterday
      ? 'Yesterday'
      : messageDate.toLocaleString(options)
  }, [])

  const getMessageFlags = useCallback(
    (m1: Message, m2: Message) => {
      const sameDay = m2 && isSameDay(m1, m2)
      const sameUser = m2 && isSameUser(m1, m2)
      const shortGapped = m2 && isshortGapped(m1, m2)
      return { sameDay, sameUser, shortGapped }
    },
    [isSameDay, isSameUser, isshortGapped]
  )

  return { getMessageFlags, isMessageFromUser, getDateString }
}

type UseChatSendProps = {
  channel: GroupChannel | null
  onSentMessage: (message: Message) => void
}
function useSendChatText() {
  const sendText = (
    text: string,
    channel: GroupChannel | null,
    onSentMessage: (message: Message) => void,
    data?: MessageData,
    message?: UserMessage,
    onError?: (text: string) => void,
    mentions?: { mentioned_assets: string[]; mentioned_users: string[] }
  ) => {
    if (channel) {
      const params = new sendbird.UserMessageParams()
      params.message = text.trim()
      if (data) {
        if (data.user_mentions) params.mentionedUserIds = data.user_mentions.map(p => p.uuid)
        const updatedMessage = message ? { user_updated_at: new Date().getTime() } : {}
        params.data = JSON.stringify(Object.assign(data, updatedMessage))
      }

      const sendMessage = () => {
        if (message) {
          channel.updateUserMessage(message.messageId, params, (message, err) => {
            if (err) {
              console.error('## chat: failed to update messge', err)
              if (onError) {
                onError(text)
              }
              return
            }
            onSentMessage(message)
          })
        } else {
          try {
            channel.sendUserMessage(params, (message, err) => {
              if (err) {
                console.error('## chat: failed to send message', err)
                if (onError) {
                  onError(text)
                }
                return
              }
              isUserMessage(message) && trackChatMessageSent(channel, message, mentions)
              onSentMessage(message)
            })
          } catch (err) {
            // ignore error
          }
        }
      }
      sendMessage()
    }
  }

  return sendText
}

type ProgressStatus = Array<{
  item: string
  progress: number
}>
function useSendChatAttachments({ channel }: UseChatSendProps) {
  const [sendingStatus, setSendingStatus] = useState<ProgressStatus>([])

  const [fileAttachments, setFileAttachments] = useState<Set<IAttachment>>(new Set())

  const removeAttachment = useCallback(
    (attachment: IAttachment) => {
      fileAttachments.delete(attachment)
      setFileAttachments(new Set(fileAttachments))
    },
    [fileAttachments]
  )

  const clearAttachments = useCallback(() => {
    setFileAttachments(new Set())
  }, [])

  useEffect(() => {
    clearAttachments()
  }, [channel, clearAttachments])

  const addAttachment = useCallback(
    (attachment: IAttachment) => {
      setFileAttachments(new Set(fileAttachments.add(attachment)))
    },
    [fileAttachments]
  )

  const formatSbAttachment = (file: File) => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onload = e => resolve({ file, item: e.target?.result, type: AttachmentType.File })
      reader.readAsDataURL(file)
    })
  }

  const getFileParams = useCallback((att: IAttachment) => {
    const params = new sendbird.FileMessageParams()
    if (isFileAttachment(att)) {
      params.file = att.file
      params.fileName = att.file.name
      params.fileSize = att.file.size
      params.mimeType = att.file.type
      params.customType = 'file'
      params.thumbnailSizes = [
        { maxWidth: 600, maxHeight: 300 },
        { maxWidth: 200, maxHeight: 200 }
      ]
    } else if (isGifAttachment(att)) {
      // @ts-ignore bad typings
      params.fileUrl = att.item
      params.mimeType = 'image/gif'
      params.customType = 'gif'
      const file = att.gif.images.original
      params.data = JSON.stringify({ sizing_information: { width: Number(file.width), height: Number(file.height) } })
    }
    return params
  }, [])

  const sendAttachments = useCallback(
    (channel: GroupChannel | null, onSentMessage: (message: Message) => void, onError?: (text?: string) => void) => {
      if (channel) {
        Array.from(fileAttachments).map(att => setSendingStatus(prev => [...prev, { item: att.item, progress: 0 }]))
        // @NOTE: Not using attachments here to prevent getting previus version on file lists
        Array.from(fileAttachments).map(async att => {
          const params = getFileParams(att)
          if (!params) return
          if ('file' in att) params.data = JSON.stringify({ sizing_information: await getSizingInfo(att.file) })
          channel.sendFileMessage(
            params,
            event => {
              const progress = Math.floor((event.loaded / event.total) * 100)
              setSendingStatus(prev => prev.map(p => (p.item === att.item ? { item: p.item, progress } : p)))
            },
            (fileMessage, error) => {
              if (error) {
                console.log('### Error while uploading file', att)
                if (onError) {
                  onError()
                }
                return
              }
              isFileMessage(fileMessage) && trackChatMessageSent(channel, fileMessage)
              onSentMessage(fileMessage)
              removeAttachment(att)
              setSendingStatus(prev => prev.filter(p => p.item !== att.item))
            }
          )
        })
      }
    },
    [fileAttachments, getFileParams, removeAttachment]
  )

  return {
    attachments: fileAttachments,
    removeAttachment,
    addAttachment,
    sendAttachments,
    formatSbAttachment,
    clearAttachments,
    sendingStatus
  }
}

function getSizingInfo(file: File) {
  let p1 = getImageSize(file)
  let p2 = getVideoSize(file)
  return Promise.all([p1, p2]).then(results => {
    return results[0] || results[1]
  })
}

function getImageSize(file: File) {
  return new Promise(resolve => {
    if (!file.type.startsWith('image')) resolve(null)
    let img = new Image()
    img.onload = function() {
      // @ts-ignore
      resolve({ width: this.width, height: this.height })
    }
    img.src = URL.createObjectURL(file)
  })
}

function getVideoSize(file: File) {
  return new Promise(resolve => {
    if (!file.type.startsWith('video')) resolve(null)
    let video = document.createElement('video')
    video.oncanplay = function() {
      // @ts-ignore
      resolve({ width: this.videoWidth, height: this.videoHeight })
    }
    video.src = URL.createObjectURL(file)
  })
}

type UseReactionProps = {
  channel: GroupChannel | undefined
  message: Message
  onReactionApplied?: () => void
}

function useApplyReaction({ channel, message, onReactionApplied }: UseReactionProps) {
  const currentUserId = sendbird.currentUser?.userId
  const [reactions, setReactions] = useState(message.reactions)

  const optimistacllyUpdate = useCallback(
    (key: string, operation: 'add' | 'delete') => {
      setReactions(reactions => {
        const index = reactions.findIndex(r => r.key === key)
        const reaction = reactions.find(r => r.key === key)
        if (reaction) {
          if (operation === 'add' && !reaction.userIds.includes(currentUserId)) {
            reaction.userIds = [...reaction.userIds, currentUserId]
          } else if (operation === 'delete' && reaction.userIds.includes(currentUserId)) {
            reaction.userIds = reaction.userIds.filter(id => id !== currentUserId)
          }
          reactions[index] = reaction
        } else if (operation === 'add') {
          // @ts-ignore
          reactions = [...reactions, { key, updatedAt: Date.now(), userIds: [currentUserId] }]
        }
        return reactions
      })
    },
    [currentUserId]
  )

  const applyReaction = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      const emojiKey = e.currentTarget.id
      track('Chat:Reaction', { reaction: emojiKey })
      const reaction = message.reactions.find(reaction => reaction.key === emojiKey)
      if (channel && reaction && reaction.userIds.includes(currentUserId)) {
        optimistacllyUpdate(emojiKey, 'delete')
        channel.deleteReaction(message, emojiKey, function(reactionEvent, error) {
          if (error) return console.log('## deleteReaction err:', error)
          message.applyReactionEvent(reactionEvent)
          setReactions(message.reactions)
          if (onReactionApplied) onReactionApplied()
        })
      } else if (channel) {
        optimistacllyUpdate(emojiKey, 'add')
        channel.addReaction(message, emojiKey, function(reactionEvent, error) {
          if (error) return console.log('## addReaction err:', error)
          message.applyReactionEvent(reactionEvent)
          setReactions(message.reactions)
          if (onReactionApplied) onReactionApplied()
        })
      }
    },
    [channel, currentUserId, message, onReactionApplied, optimistacllyUpdate]
  )

  return { applyReaction, reactions }
}

function useChannelTitle(channel: GroupChannel | undefined) {
  const isDirectMessage = useMemo(() => channel?.isDistinct, [channel])

  const currentUserId = sendbird.currentUser?.userId
  const getTitle = useCallback(() => {
    if (!channel) return ''
    if (!isDirectMessage) {
      return channel.name
    } else {
      const names = channel.members.filter(m => m.userId !== currentUserId)
      // @ts-ignore not sure how to get proper metaData types into sendbird
      if (names.length === 1) return names[0].metaData.display_name || names[0].nickname
      const formattedNames = names
        .slice(0, 2)
        // @ts-ignore not sure how to get proper metaData types into sendbird
        .map(n => n.metaData.first_name || n.nickname)
        .join(', ')
      let final = names.length >= 3 ? `+${names.length - 2}` : ''
      return formattedNames + final
    }
  }, [channel, isDirectMessage, currentUserId])

  return getTitle
}

function useChannelFocus({
  channel,
  messages,
  receivedMessages
}: {
  channel: GroupChannel | null
  messages: Messages | undefined
  receivedMessages: Messages
}) {
  const [channelFocus, setChannelFocus] = useState(true)

  useEffect(() => {
    if (channelFocus && channel?.unreadMessageCount && !receivedMessages.length) {
      try {
        channel.markAsRead()
      } catch (err) {
        // ignore this error
      }
    }
  }, [channel, channelFocus, messages, receivedMessages])

  useEventListener('focus', () => setChannelFocus(true))
  useEventListener('blur', () => setChannelFocus(false))
}

function useContactSupport() {
  const { getUpdatedChannelAndSelect } = useChannels()
  const createConversation = useCreateConversation()
  const [postStage, setPostStage] = useState<PostStage>(PostStage.Latent)
  const currentUserId = sendbird.currentUser?.userId

  const contactSupport = async () => {
    try {
      if (postStage === PostStage.Pending) return
      setPostStage(PostStage.Pending)
      const response = await createConversation({
        json: { user_uuids: [currentUserId, config.feedbackUuid] }
      })
      if (!response.fail && response.success?.payload) {
        await getUpdatedChannelAndSelect(response.success?.payload.channel_url)
      }
    } catch (err) {
      captureException(err)
    } finally {
      setPostStage(PostStage.Latent)
    }
  }

  return contactSupport
}

export {
  useApplyReaction,
  useMessageComparing,
  useSendChatAttachments,
  useSendChatText,
  useChannelTitle,
  useChannelFocus,
  useContactSupport
}
