import { FeedItem, isPostFeedItem, isTradeFeedItem } from '@commonstock/common/src/api/feed';
import { isLoggedIn } from '@commonstock/common/src/auth';
import { css, cx } from '@linaria/core';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { ReactNode, Ref, useCallback } from 'react';
import { cardLinkPointerClass, ContentCard, DetailCard } from 'src/components/styles';
import { FlexRow } from 'src/composing/Flex';
import { scrollY } from 'src/theme/AtomicClasses';
import { useTheme } from 'src/theme/ThemeContext';
import { trackPostViewAction, trackTradeViewAction } from '../analytics/events.v1';
import { CardLayoutTypes, isUpvoteLayout } from '../feed/constants';
import { ModalScope, useEphemeralModal } from '../modal/Modal';
import { Routes } from '../nav/constants';
import ContentColumnActions from './ContentColumnActions';
const PostDetail = dynamic(() => import('../post/PostDetail'));
const TradeDetail = dynamic(() => import('../trade/TradeDetail'));
type Props = {
  feedItem: FeedItem;
  isModal?: boolean;
  children: ReactNode;
  cardLayout: CardLayoutTypes;
};
export function DetailContentWrapper({
  feedItem,
  isModal,
  children,
  cardLayout
}: Props) {
  const _isUpvoteLayout = isUpvoteLayout(cardLayout);

  return <DetailCard className={cx(isModal && 'modal', isModal && scrollY, _isUpvoteLayout && 'hasColumnActions')}>
      {_isUpvoteLayout ? <FlexRow>
          <ContentColumnActions feedItem={feedItem} />
          <div className={detailMainContentPadding}>{children}</div>
        </FlexRow> : children}
    </DetailCard>;
}
const detailMainContentPadding = "d1fak9ky";
type CardProps = {
  feedItem: FeedItem;
  children: ReactNode;
  cardLayout: CardLayoutTypes;
};
export const CardContentWrapper = React.forwardRef(({
  feedItem,
  children,
  cardLayout
}: CardProps, ref: Ref<HTMLDivElement>) => {
  const _isUpvoteLayout = isUpvoteLayout(cardLayout);

  return <ContentCard ref={ref} className={cx(!!feedItem.reason_text && 'hasReason', _isUpvoteLayout && 'hasColumnActions')}>
        <ContentDetailLink feedItem={feedItem} cardLayout={cardLayout}>
          {_isUpvoteLayout ? <FlexRow>
              <ContentColumnActions feedItem={feedItem} />
              <div className={cardMainContentPadding}>{children}</div>
            </FlexRow> : children}
        </ContentDetailLink>
      </ContentCard>;
});
CardContentWrapper.displayName = 'CardContentWrapper';
const cardMainContentPadding = "c1j5dbwu";
export function getContentLink(feedItem: FeedItem) {
  if (isPostFeedItem(feedItem)) return Routes.postDetail(feedItem.uuid);
  if (isTradeFeedItem(feedItem)) return Routes.tradeDetail(feedItem.uuid);
  return '';
}
type ContentDetailLinkProps = {
  feedItem: FeedItem;
  children: ReactNode;
  cardLayout: CardLayoutTypes;
  hash?: string;
};
export function ContentDetailLink({
  feedItem,
  children,
  cardLayout,
  hash
}: ContentDetailLinkProps) {
  const {
    isMobile
  } = useTheme();
  const modal = useEphemeralModal();
  const router = useRouter();
  const onClick = useCallback((e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    const contentLink = getContentLink(feedItem);

    if (isMobile || !isLoggedIn()) {
      router.push({
        pathname: contentLink,
        hash: hash
      });
    } else {
      const options = {
        shallowUrl: `${contentLink}${hash ? `#${hash}` : ''}`,
        scope: ModalScope.Details
      };

      if (isPostFeedItem(feedItem)) {
        trackPostViewAction(feedItem.uuid);
        modal(<PostDetail isModal postFeedItem={feedItem} cardLayout={cardLayout} />, options);
      } else if (isTradeFeedItem(feedItem)) {
        trackTradeViewAction(feedItem.uuid);
        modal(<TradeDetail isModal tradeFeedItem={feedItem} cardLayout={cardLayout} />, options);
      }
    }
  }, [cardLayout, feedItem, hash, isMobile, modal, router]);
  const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLElement>) => e.keyCode === 32 ? onClick(e) : null, [onClick]);
  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    requestIdleCallback(() => !document.getSelection()?.toString() && onClick(e), {
      timeout: 500
    });
  }, [onClick]);
  return (// eslint-disable-next-line
    <article role="article" tabIndex={0} className={cardLinkPointerClass} onClick={handleClick} onKeyUp={onKeyUp}>
      {children}
    </article>
  );
}

require("../../../.linaria-cache/packages/oxcart/src/scopes/content/DetailContentWrapper.linaria.module.css");