import { useCallback } from 'react'
import { useCollectAnalytics } from './AnalyticsNode'
import { AnalyticsNodeValue } from './constants'
import { track } from './mixpanel'

type MixpanelData = { [key: string]: any }
export default function createUseEvent<LocalData extends MixpanelData | void>(event: string) {
  return () => {
    let collect = useCollectAnalytics()
    // it is important this is stable across renders so that we do not fire duplicate events
    return useCallback(
      (localData: LocalData) => {
        let contextData = collect()
        let data: MixpanelData = { ...localData, ...contextData }
        track(event, data)
      },
      [collect]
    )
  }
}

type AnalyticsKey = keyof AnalyticsNodeValue

// This is factory is an interim measure while we align on the structured data proposal.
// The difference is this method require data is explicitly selected via propertiese array.
export function createUseEventCustomized<LocalData extends MixpanelData>(
  event: string,
  properties: Array<AnalyticsKey> = []
) {
  return () => {
    let collect = useCollectAnalytics()
    // it is important this is stable across renders so that we do not fire duplicate events
    return useCallback(
      (localData: LocalData) => {
        let contextData = collect()
        let data: MixpanelData = { ...localData }
        properties.forEach(key => {
          if (contextData[key]) data[key] = contextData[key]
        })
        track(event, data)
      },
      [collect]
    )
  }
}
