import React from 'react'
import { useGetProfile } from '@commonstock/common/src/api/profile'
import { DangerButton } from '../../composing/CSButton'
import { TextBodyRegular } from '../../composing/CSText'
import { SpacerV } from '../../composing/Spacing'
import { ActionModal } from '../modal/Modals'

type Props = {
  userUuid: string
  onUnfollow: () => Promise<any>
}
function Unfollow({ userUuid, onUnfollow }: Props) {
  const [profile] = useGetProfile({ meta: { user_uuid: userUuid } })

  return (
    <ActionModal title="Unfollow user" onEnter={onUnfollow}>
      <TextBodyRegular>
        @{profile?.username}`s trades and other activity will no longer be included in your feed.
      </TextBodyRegular>
      <SpacerV rem={1.5} />
      <DangerButton block large onClick={onUnfollow}>
        Unfollow
      </DangerButton>
    </ActionModal>
  )
}

export default Unfollow
