import React, { createContext, ReactNode, useCallback, useMemo, useState } from 'react'

type DevValue = {
  skeletonShow: boolean
  setSkeletonShow: (n: boolean) => void
}

const DevContext = createContext<DevValue>({
  skeletonShow: false,
  setSkeletonShow: () => {}
})
DevContext.displayName = 'DevContext'

function DevProvider({ children }: { children: ReactNode }) {
  const [skeletonShow, _setSkeletonShow] = useState<boolean>(false)

  const setSkeletonShow = useCallback((n: boolean) => _setSkeletonShow(n), [])

  const value = useMemo(
    () => ({
      skeletonShow,
      setSkeletonShow
    }),
    [skeletonShow, setSkeletonShow]
  )

  return <DevContext.Provider value={value}>{children}</DevContext.Provider>
}

function useDev(): DevValue {
  const context = React.useContext(DevContext)

  // convenience jest checks
  return context as DevValue
}

export { DevProvider, useDev }
