import { ReactNode } from 'react'
import { useEphemeralModal } from '../modal/Modal'
import { useLoggedOutAction } from '../analytics/events/loggedOutAction'

function useLoggedOutModal(tap_location: string) {
  const modal = useEphemeralModal()
  const loggedOutAction = useLoggedOutAction()
  return (action_type: string, node: ReactNode) => {
    loggedOutAction({
      action_type,
      tap_location
    })
    modal(node)
  }
}

export default useLoggedOutModal
