import React from 'react'
import { getColor, Colors, getChartLineColor } from '../../theme/constants'
import { AssetChartData } from '@commonstock/common/src/api/asset'
import { ChartData } from '@commonstock/common/src/types'
import LineChart from './LineChart'

const chartMargin = { top: 0, right: 0, left: 0, bottom: 0 }
type ChartOptions = {
  width?: number
  height?: number
  bg?: string
}

const MiniChart = ({ data, options }: { data: AssetChartData | ChartData; options?: ChartOptions }) => {
  const { period_return } = data
  const chartColor = getColor(period_return)
  const chartLineColor = getChartLineColor(period_return)
  return (
    <LineChart
      width={options?.width || 64}
      height={options?.height || 32}
      margin={chartMargin}
      color={chartColor}
      lineColor={chartLineColor}
      showReferenceLine={'never'}
      chartData={data}
      strokeWidth={1}
      dataKey={'c'}
      bgColor={options?.bg ? options.bg : Colors.BackgroundPrimary}
    />
  )
}

export default MiniChart
