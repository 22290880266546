import { FeedType, GlobalFeedSortType } from '@commonstock/common/src/api/feed'
import { track } from '../mixpanel'

type ItemLikeObject = {
  item_uuid: string
  item_type: string
  like_count: number
  item_user_uuid: string
  feed_type?: FeedType | GlobalFeedSortType
}

export function itemLike(data: ItemLikeObject) {
  track('Item Like', {
    item_uuid: data.item_uuid,
    item_type: data.item_type,
    like_count: data.like_count,
    item_user_uuid: data.item_user_uuid
  })
}
