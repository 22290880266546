export enum OgImageApiPath {
  TRADE = '/og/trade/',
  POST = '/og/post/',
  PROFILE = '/og/profile/'
}

export enum OgImageNamePath {
  TRADE = '/trade.png',
  POST = '/post.png',
  PROFILE = '/profile.png'
}
