import { Action, ActionTypes } from './actions'

type ZoomState = {
  isZoomed: boolean
  translateX: number
  translateY: number
  prevMouseX: number
  prevMouseY: number
  scale: number
}

export const initialState: ZoomState = {
  isZoomed: false,
  translateX: 0,
  translateY: 0,
  prevMouseX: 0,
  prevMouseY: 0,
  scale: 1
}

export const reducer = (state: ZoomState, action: Action): ZoomState => {
  switch (action.type) {
    case ActionTypes.PAN_START:
      return {
        ...state,
        prevMouseX: action.clientX,
        prevMouseY: action.clientY
      }

    case ActionTypes.PAN: {
      const deltaMouseX = action.clientX - state.prevMouseX
      const deltaMouseY = action.clientY - state.prevMouseY
      return {
        ...state,
        translateX: state.translateX + deltaMouseX,
        translateY: state.translateY + deltaMouseY,
        prevMouseX: action.clientX,
        prevMouseY: action.clientY
      }
    }

    case ActionTypes.ZOOM: {
      if (state.isZoomed) {
        return initialState
      } else {
        const scaledTranslate = getScaledTranslate(state, action.zoomFactor)
        const mousePositionOnScreen = { x: action.clientX, y: action.clientY }
        const zoomOffset = getZoomOffset(action.containerRect, mousePositionOnScreen, action.zoomFactor)
        return {
          ...state,
          isZoomed: !state.isZoomed,
          scale: action.zoomFactor,
          translateX: scaledTranslate.x - zoomOffset.x,
          translateY: scaledTranslate.y - zoomOffset.y
        }
      }
    }

    default:
      return state
  }
}

type MousePosition = {
  x: number
  y: number
}

const getZoomOffset = (containerRect: DOMRect, mousePositionOnScreen: MousePosition, zoomFactor: number) => {
  const zoomOrigin = {
    x: mousePositionOnScreen.x - containerRect.left,
    y: mousePositionOnScreen.y - containerRect.top
  }
  const currentDistanceToCenter = {
    x: containerRect.width / 2 - zoomOrigin.x,
    y: containerRect.height / 2 - zoomOrigin.y
  }
  const scaledDistanceToCenter = {
    x: currentDistanceToCenter.x * zoomFactor,
    y: currentDistanceToCenter.y * zoomFactor
  }
  const zoomOffset = {
    x: currentDistanceToCenter.x - scaledDistanceToCenter.x,
    y: currentDistanceToCenter.y - scaledDistanceToCenter.y
  }
  return zoomOffset
}

const getScaledTranslate = (state: ZoomState, zoomFactor: number) => ({
  x: state.translateX * zoomFactor,
  y: state.translateY * zoomFactor
})
