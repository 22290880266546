import { getPersistedState, usePersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { useCallback } from 'react'
import { uniq } from 'lodash'

const StoredTokenInviteKeys = 'cs:token-invites2'

export function useStoredTokens() {
  const [storedTokenInvites, setStoredTokenInvites] = usePersistedState<string>(StoredTokenInviteKeys, '')

  const addTokenInvites = useCallback(
    async (tokens: string) => {
      const currentTokens = ((await getPersistedState(StoredTokenInviteKeys)) || '').split(',').filter(t => !!t)
      const newTokens = tokens.split(',')
      setStoredTokenInvites(uniq([...currentTokens, ...newTokens]).join(','))
    },
    [setStoredTokenInvites]
  )

  const removeTokenInvite = useCallback(
    async (token: string) => {
      const tokens = ((await getPersistedState(StoredTokenInviteKeys)) || '').split(',').filter(t => !!t && t !== token)
      setStoredTokenInvites([...tokens].join(','))
    },
    [setStoredTokenInvites]
  )

  const tokenInvites = storedTokenInvites ? storedTokenInvites.split(',') : []

  return { tokenInvites, addTokenInvites, removeTokenInvite }
}
