import React, { useMemo } from 'react';
import { useGetUsersKnownFollowers, UserProfile } from '@commonstock/common/src/api/profile';
import { ProfileImageStackContainer } from '../../components/styles';
import { FlexRow } from '../../composing/Flex';
import { SpacerH, SpacerV } from '../../composing/Spacing';
import { TextCaption } from '../../composing/CSText';
import { StackedProfileAvatar } from '../../components/Avatar';
import { UsersYouKnowSkeleton } from './Profile.skeleton';
import { css } from '@linaria/core';
type Props = {
  profile: UserProfile;
};

function UsersYouKnow({
  profile
}: Props) {
  const [knownFollowers] = useGetUsersKnownFollowers({
    meta: {
      uuid: profile?.user_uuid || 'n/a'
    }
  });
  const profiles = knownFollowers?.profiles;
  const text = useMemo(() => {
    if (!profiles || !profiles.length) return '';
    let restLength = Math.max(0, (knownFollowers?.count || 0) - 2);
    const [user1, user2] = profiles;
    let text = `Followed by ${user1.name}`;
    if (user2) text += (!restLength ? ` and` : `,`) + ` ${user2.name}`;
    if (restLength) text += `, and ${restLength} ${restLength === 1 ? 'other' : 'others'} you know`;
    return text;
  }, [profiles, knownFollowers]);
  if (!profiles) return <UsersYouKnowSkeleton />;
  if (!profiles.length) return null;
  return <>
      <SpacerV />
      <FlexRow alignCenter>
        <ProfileImageStackContainer>
          {profiles.slice(0, 3).map((profile, index) => <StackedProfileAvatar key={index} avatar={profile.picture} name={profile.name} index={index} size={1.25} />)}
        </ProfileImageStackContainer>
        <SpacerH rem={0.5} />
        <TextCaption ellipsis className={textClass}>
          {text}
        </TextCaption>
      </FlexRow>
    </>;
}

export default UsersYouKnow;
const textClass = "t1ycg1p4";

require("../../../.linaria-cache/packages/oxcart/src/scopes/profile/UsersYouKnow.linaria.module.css");