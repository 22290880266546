import { createContext, useContext } from 'react'
import { MentionAttachments } from '@commonstock/common/src/types/mentions'

let MentionsContext = createContext<MentionAttachments>({})
MentionsContext.displayName = 'MentionsContext'

export default MentionsContext
export function useMentionsContext() {
  return useContext(MentionsContext)
}
