import React from 'react'
import { FlexColumn, FlexRow } from '../composing/Flex'
import { Spacing, SpacerH, SpacerV } from '../composing/Spacing'
import { SkeletonCircle, SkeletonBlock } from './Skeleton'
import { Expander } from '../composing/Flex'

export function UserBlockSkeleton() {
  return (
    <>
      <FlexRow>
        <SkeletonCircle h={'2rem'} />
        <SpacerH rem={1} />
        <FlexColumn h={'2rem'}>
          <SkeletonBlock w={'50%'} h={'0.8rem'} />
          <Expander />
          <SkeletonBlock w={'30%'} h={'0.8rem'} />
        </FlexColumn>
      </FlexRow>
    </>
  )
}

export function UserListItemSkeleton() {
  return (
    <Spacing padding={'1rem'}>
      <FlexColumn>
        <UserBlockSkeleton />
        <SpacerV />
        <FlexRow>
          <FlexColumn>
            <SkeletonBlock w={'100%'} h={'0.8rem'} />
            <SpacerV rem={0.5} />
            <SkeletonBlock w={'15%'} h={'0.8rem'} />
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
    </Spacing>
  )
}

export function UserListSkeleton() {
  return (
    <>
      {[...Array(7)].map((_, i) => (
        <UserListItemSkeleton key={i} />
      ))}
    </>
  )
}
