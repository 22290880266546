import { MouseEvent } from 'react'

export enum ActionTypes {
  PAN = 'PAN',
  PAN_START = 'PAN_START',
  ZOOM = 'ZOOM'
}

export type Action =
  | { type: ActionTypes.PAN; clientX: number; clientY: number }
  | { type: ActionTypes.PAN_START; clientX: number; clientY: number }
  | { type: ActionTypes.ZOOM; zoomFactor: number; clientX: number; clientY: number; containerRect: DOMRect }

export const startPan = (event: MouseEvent) => ({
  type: ActionTypes.PAN_START,
  clientX: event.clientX,
  clientY: event.clientY
})

export const pan = (event: globalThis.MouseEvent) => ({
  type: ActionTypes.PAN,
  clientX: event.clientX,
  clientY: event.clientY
})

export const zoom = (event: MouseEvent, containerRect: DOMRect, zoomFactor: number) => ({
  type: ActionTypes.ZOOM,
  zoomFactor: zoomFactor,
  clientX: event.clientX,
  clientY: event.clientY,
  containerRect: containerRect
})
