import React from 'react';
import { Periods } from '@commonstock/common/src/types';
import { REM20 } from '../theme/Types';
import { UnstyledButton } from '../composing/CSButton';
import { TextCaptionEmphasis } from '../composing/CSText';
import { css, cx } from '@linaria/core';
import { flexCentered } from '../theme/AtomicClasses';
import { Colors } from '../theme/constants';
import { DefaultAnimation } from '../theme/Shadows';
type Props = {
  currentPeriod: Periods;
  setPeriod: (value: Periods) => void;
  availablePeriods: Periods[];
};

const PeriodSelector = ({
  currentPeriod,
  setPeriod,
  availablePeriods
}: Props) => {
  if (!availablePeriods.length) return null;
  return <div className={flexCentered}>
      {availablePeriods.map(period => {
      const isActive = period.toLowerCase() === currentPeriod.toLowerCase();
      return <UnstyledButton key={period} data-cy={`portfolio-period-${period}`} onClick={() => setPeriod(period)} className={cx(periodPill, period === Periods.YTD && ytdClass, isActive && 'active')} aria-label={`Set period filter to ${period}`}>
            <TextCaptionEmphasis>{period}</TextCaptionEmphasis>
          </UnstyledButton>;
    })}
    </div>;
};

export default PeriodSelector;
const periodPill = "p1922q81";
const ytdClass = "y6wkq6e";

require("../../.linaria-cache/packages/oxcart/src/components/PeriodSelector.linaria.module.css");