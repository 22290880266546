import { createUseFetch, createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods } from '@commonstock/client/src/constants'
import { OptionContract } from '@commonstock/common/src/api/feed'
import { ChartData, WatchlistAsset, AssetType, Periods } from '../types'
import cacheLast from '@commonstock/client/src/utils/cacheLast'

export type UserChartDataParams = {
  meta: {
    type: 'simple' | 'time-weighted'
  }
  query: {
    period: string
    user_id: string
    privacy?: 'on'
    benchmark?: string
  }
}

export const useGetUserChartData = createUseFetch<ChartData, UserChartDataParams>({
  key: 'user-chart',
  path: ({ meta: { type } }) => `/pds/${type}-return`
})

export const useGetUserChartDataCached = cacheLast(useGetUserChartData)

export type BrokerPosition = {
  canonical: string
  name: string
  website: string
  account: {
    source_id: string
    quantity: number
  }
}

export type Position = {
  // asset_id: number
  asset_type: AssetType
  avg_cost_basis: number
  change_percentage: number
  change_value: number
  currency: string
  cusip: number | null
  daily_change_percentage: number
  fxrate: number
  last_price: number
  portfolio_percentage: number
  price: number
  quantity: number
  security: string
  short_name: string
  source_id: string
  ticker: string
  ticker_name: string
  value: number
  brokerages: BrokerPosition[]
  period_price: number
  period_value: number
  option_contract: OptionContract | null
}

type UserHoldingsParams = {
  meta: {
    user_uuid: string
  }
  query?: {
    period?: Periods
    privacy: 'on'
  }
}
export const useGetUserHoldings = createUseFetch<Position[], UserHoldingsParams>({
  key: 'user-positions',
  path: ({ meta: { user_uuid } }) => `/pds/positions/${user_uuid}`,
  refetchInterval: 30000
})

export const useGetCachedUserHoldings = cacheLast(useGetUserHoldings)

export enum OrderType {
  Buy = 'buy',
  Sell = 'sell',
  LimitBuy = 'limit buy',
  LimitSell = 'limit sell',
  MarketBuy = 'market buy',
  MarketSell = 'market sell',
  SellShort = 'sell short'
}

export enum OrderStatus {
  Cancelled = 'cancelled',
  Filled = 'filled',
  PartiallyFilled = 'partially_filled',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type Transaction = {
  asset_type: AssetType
  currency: string
  cusip: number
  date: string
  fxrate: number
  price: number
  quantity: number
  source_id: string
  ticker: string
  ticker_name: string
  value: number
}

export type Order = {
  asset_type: AssetType
  date: string
  price: number
  quantity: number
  source_id: string
  status: OrderStatus
  ticker: string
  ticker_name: string
  type: OrderType
  value: number
}

export const useGetOrderList = createUseFetch<Order[]>({
  key: 'user-orders',
  path: '/pds/orders'
})

export const useGetTransactionList = createUseFetch<Transaction[]>({
  key: 'user-transactions',
  path: '/pds/transactions'
})

export type Watchlist = WatchlistAsset[]

type UserUuidParams = {
  meta: {
    user_uuid: string
  }
}

export const USER_WATCHLIST_PATH = '/watchlist/watched_public/'
export const useGetUserWatchlist = createUseFetch<Watchlist, UserUuidParams>({
  key: 'watchlist-list',
  path: ({ meta: { user_uuid } }) => `${USER_WATCHLIST_PATH}${user_uuid}`
})

export type LinkStatus = {
  has_visible_broker: boolean
  has_visible_synced_broker: boolean
}
export const useGetLinkStatus = createUseFetch<LinkStatus, UserUuidParams>({
  key: 'user-link-status',
  path: ({ meta: { user_uuid } }) => `/pds/user-link-status/${user_uuid}`
})

type DeleteWatchlistParams = {
  meta: {
    id: number
  }
}
export const useDeleteUserWatchlist = createUseFetchAction<any, DeleteWatchlistParams>({
  key: 'watchlist-delete',
  method: HttpMethods.Delete,
  path: ({ meta: { id } }) => `/watchlist/watched/${id}`
})

export type AddWatchlistParams = {
  json: {
    asset_symbol: string
    asset_type: string
  }
}

export const usePostWatchlistAction = createUseFetchAction<WatchlistAsset, AddWatchlistParams>({
  key: 'watchlist-add',
  method: HttpMethods.Post,
  path: '/watchlist/watched'
})

export type FollowedInvestor = {
  name: string
  picture: string
  user_uuid: string
  username: string
}

type FollowedInvestorsParams = {
  query: {
    symbol: string
  }
}

export const FOLLOWED_INVESTORS_PATH = '/pds/followed-investors'
export const useGetFollowedInvestors = createUseFetch<FollowedInvestor[], FollowedInvestorsParams>({
  key: 'followed-investors',
  path: FOLLOWED_INVESTORS_PATH
})

type TopHoldersParams = {
  query: {
    symbol: string
  }
}

export type TopHolder = {
  name: string
  picture: string
  user_uuid: string
  username: string
  held_since: number
}

export const TOP_HOLDERS_PATH = '/pds/hodlers'
export const useGetTopHolders = createUseFetch<TopHolder[], TopHoldersParams>({
  key: 'top-holders',
  path: TOP_HOLDERS_PATH
})
