import { isLoggedIn } from '@commonstock/common/src/auth'
import useInterval from '@use-it/interval'
import { ReactNode, useState } from 'react'
import { isServer } from 'src/utils/isServer'
import { flagsReady, useFlags } from './useFlags'

// this component will block rendering children when: user is logged in and flags are not yet loaded
// for design considerations see https://www.notion.so/commonstock/Server-Side-Rendering-f60751986efa4059a2077235673221ba
export function FlagGate({ children }: { children?: ReactNode }) {
  const flags = useFlags()
  const [forceReady, setForceReady] = useState(flags[flagsReady] || isServer || !isLoggedIn())

  useInterval(() => {
    setForceReady(true)
  }, 2000)

  // render children if we are logged out, server-side, timed out, OR flags are ready
  return forceReady || flags[flagsReady] || isServer || !isLoggedIn() ? <>{children || null}</> : null
}
