import {
  FeedResourceType,
  Link,
  PostFeedItem,
  PostResource,
  TradeFeedItem,
  TradeResource
} from '@commonstock/common/src/api/feed'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { MentionAttachments } from '@commonstock/common/src/types/mentions'

type DestructedPost = PostFeedItem & {
  post?: PostResource
  user?: HydratedUserProfile
  mentionAttachments: MentionAttachments
  linkedTrades: TradeResource[]
}
export function destructPostFeedItem(postFeedItem: PostFeedItem): DestructedPost {
  const { resources } = postFeedItem
  let post: PostResource | undefined = resources.memos[postFeedItem.uuid]
  let user: HydratedUserProfile | undefined = resources.users[postFeedItem.user_uuid]

  // BE return a wrong object when it fails to grab it from DB, so we need to check for the current uuid of them
  post = postFeedItem.uuid === post?.uuid ? post : undefined
  user = postFeedItem.user_uuid === user?.uuid ? user : undefined

  const asset_mentions = post?.asset_mentions.map(id => resources.assets[id] || null).filter(Boolean)
  const user_mentions = post?.user_mentions.map(id => resources.users[id] || null).filter(Boolean)
  const mentionAttachments: MentionAttachments = { asset_mentions, user_mentions }

  const linkedTrades: TradeResource[] =
    post?.related_trades?.map(id => resources.trades[id] || null).filter(Boolean) || []

  return { ...postFeedItem, post, user, mentionAttachments, linkedTrades }
}

export function feedItemToTrade(postFeedItem: PostFeedItem, trade: TradeResource): TradeFeedItem | null {
  if (!postFeedItem.resources.trades[trade.uuid]) return null
  return {
    ...postFeedItem,
    created_at: trade.created_at,
    resource_type: FeedResourceType.TRADE,
    uuid: trade.uuid,
    user_uuid: trade.user_uuid
  }
}

export function getPostPreviewLink(post: PostResource): Link | undefined {
  const linksList = Object.values(post.links)
  const lastLink = linksList.length ? linksList[linksList.length - 1] : undefined
  return lastLink
}

// We use the same regex as BE, so avoid changing it without changing on BE first, otherwise, your link wont be displayed after saving
const urlRegexString = /(?:^|\s)((?:(?:https?|http?):\/\/)?[a-z0-9-]+\.[a-z]{2,}[^\s,]*)/
export const urlRegex = new RegExp(urlRegexString, 'gi')

export const getLinks = (text?: string) => {
  return text?.match(urlRegex)?.map(e => e.trim()) || []
}
