import React, { useState, useCallback } from 'react'
import { UserProfile } from '@commonstock/common/src/api/profile'
import {
  BadgeUserItems,
  BanItems,
  BlockProfileItem,
  DeleteItems,
  MuteToggleItem,
  ShareProfileItem
} from './ProfileButtons'
import { More } from '../../theme/NewIcons'
import useCloseMenu from 'src/utils/useCloseMenu'
import { Menu, paneHeaderIconButton } from '../../components/styles'
import ReportContentMenuItem from '../../components/ReportContent'
import { ReportContentType } from '@commonstock/common/src/api/report'
import { SecondaryButton } from '../../composing/CSButton'
import { useAuth } from '../auth/AuthContext'
import { cx } from '@linaria/core'
import { relative } from 'src/theme/AtomicClasses'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { useTheme } from 'src/theme/ThemeContext'

const CONTAINER_ID = 'profile-menu'
function HeaderActions({ profile, isMe }: { profile?: UserProfile; isMe?: boolean }) {
  const [isOpen, setIsOpen] = useState(false)
  useCloseMenu(`#${CONTAINER_ID}`, () => setIsOpen(false))
  const { isModerator, isAdmin } = useAuth()

  const toggle = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      setIsOpen(!isOpen)
    },
    [isOpen]
  )
  const { isMobile } = useTheme()

  if (!profile || !isLoggedIn()) return null
  return (
    <div id={CONTAINER_ID} className={relative}>
      <SecondaryButton
        onClick={toggle}
        aria-label={'Show more profile actions'}
        className={cx(paneHeaderIconButton)}
        mobile={isMobile}
      >
        <More />
      </SecondaryButton>
      <Menu hidden={!isOpen}>
        {(!profile.blocked || isMe) && <ShareProfileItem profile={profile} />}
        {!profile.blocked && !isMe && (
          <>
            <MuteToggleItem profile={profile} />
            <BlockProfileItem profile={profile} />
          </>
        )}
        {isModerator && !isMe && <BadgeUserItems profile={profile} />}
        {isModerator && !isMe && <BanItems profile={profile} />}
        {isAdmin && !isMe && <DeleteItems profile={profile} />}
        {!isMe && (
          <ReportContentMenuItem
            uuid={profile.user_uuid}
            title={profile.username}
            contentType={ReportContentType.Profile}
          />
        )}
      </Menu>
    </div>
  )
}

export default HeaderActions
