import useKeyPress from '@commonstock/common/src/utils/useKeyPress'

export default function useEditorKeybind() {
  useKeyPress('z', e => {
    if (e.metaKey) {
      document.execCommand?.('undo')
    }
    if (e.metaKey && e.shiftKey) {
      document.execCommand?.('redo')
    }
  })
}
