import React from 'react';
import { Invite as InviteIcon } from '../../theme/NewIcons';
import { PrimaryButton } from '../../composing/CSButton';
import { useEphemeralModal } from '../modal/Modal';
import Invite from '../feed/Invite';
import { css } from '@linaria/core';
import { track } from '../analytics/mixpanel';

function InviteFriends() {
  const modal = useEphemeralModal();

  const onClick = () => {
    track('MainInvite:Tap');
    modal(<Invite />);
  };

  return <PrimaryButton onClick={onClick} className={inviteButton}>
      <InviteIcon className={iconClass} />
      Invite a friend
    </PrimaryButton>;
}

export default InviteFriends;
const inviteButton = "i12izejj";
const iconClass = "i6u2fj8";

require("../../../.linaria-cache/packages/oxcart/src/scopes/chat/InviteFriends.linaria.module.css");