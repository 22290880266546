import { Resource } from '@commonstock/client/src/constants'
import { useCallback, useState } from 'react'

export default function useFetchSubmit<Payload, Params>(
  submitMethod: (params: Params) => Promise<Resource<Payload, Params>>,
  onFail?: (fail: Resource<Payload, Params>) => any
) {
  const [resource, resourceSet] = useState<Resource<Payload, Params>>()
  const [pending, pendingSet] = useState(false)
  const submit = useCallback(
    async (params: Params) => {
      try {
        pendingSet(true)
        let resource = await submitMethod(params)
        resourceSet(resource)
        return resource
      } catch (err) {
        // fail will be a resource in most cases, if not massage it into a similar shape
        // @ts-ignore missing a bunch of resource values that arent really needed
        let resource = err.stage ? err : { fail: err }
        onFail?.(resource)
        resourceSet(resource)
        return {
          success: undefined,
          fail: resource.fail
        } as Resource<Payload, Params>
      } finally {
        pendingSet(false)
      }
    },
    [submitMethod, onFail]
  )

  const reset = useCallback(() => {
    resourceSet(undefined)
    pendingSet(false)
  }, [])
  return {
    pending,
    fail: resource?.fail,
    success: resource?.success,
    submit,
    reset
  }
}
