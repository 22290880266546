import React, { useState } from 'react'
import { Colors } from '../../theme/constants'
import { SpacerV } from '../../composing/Spacing'
import { TextSubheadEmphasis, TextBodyEmphasis } from '../../composing/CSText'
import { Tag as TagItemType } from '@commonstock/common/src/api/tag'
import Tag from './Tag'
import { UnstyledButton } from '../../composing/CSButton'
import { FlexColumn, FlexRow } from '../../composing/Flex'

type Props = {
  onClick: (tag: TagItemType) => void
  selectedTags: TagItemType[]
  tags: TagItemType[]
  title?: string
  stroke?: boolean
  limit?: number
}

const TagList = ({ onClick, selectedTags, tags, title, limit }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [tagList, setTagList] = useState(limit ? tags.slice(0, limit) : tags)
  const onToggleExpanded = () => {
    let tagsExpanded = tags
    if (limit) {
      if (tagList.length < tags.length) {
        tagsExpanded = tagList.concat(tags.slice(tagList.length, tagList.length + limit))
      } else {
        tagsExpanded = tags.slice(0, limit)
      }
    }
    setIsExpanded(() => tagsExpanded.length === tags.length)
    setTagList(tagsExpanded)
  }

  return (
    <FlexColumn alignCenter>
      {title && (
        <>
          <TextBodyEmphasis>{title}</TextBodyEmphasis>
          <SpacerV />
        </>
      )}
      <FlexRow justifyCenter wrap>
        {tagList.map((tag, index) => {
          let inactive = !selectedTags.map(tag => tag.canonical_name).includes(tag.canonical_name)
          return <Tag key={index} tag={tag} onClick={onClick} inactive={inactive}></Tag>
        })}
      </FlexRow>

      {limit && tags.length > limit && (
        <>
          <SpacerV rem={0.5} />
          <UnstyledButton onClick={onToggleExpanded}>
            <TextSubheadEmphasis color={Colors.TextLinks}>{isExpanded ? 'See less' : 'See more'}</TextSubheadEmphasis>
          </UnstyledButton>
        </>
      )}
    </FlexColumn>
  )
}

export default TagList
