import React, { ReactNode, useCallback, useRef, useState, useEffect, Suspense } from 'react';
import { motion, Transition, usePresence, Variants } from 'framer-motion';
import { css, cx } from '@linaria/core';
import { BrandSpinner } from '../../components/Spinner';
const pageVariants: Variants = {
  enter: (direction: number) => ({
    x: (direction || 1) * 100 + '%'
  }),
  center: {
    x: 0
  },
  exit: (direction: number) => ({
    x: -(direction || 1) * 100 + '%'
  })
};
const pageTransition: Transition = {
  ease: [0.25, 1, 0.5, 1],
  duration: 0.15
};
type Props = {
  children: ReactNode;
  scope: string;
  firstModal?: boolean;
  hide?: boolean;
  modalContainerRef: React.RefObject<HTMLDivElement>;
};

function ModalInterior({
  children,
  scope,
  hide,
  firstModal,
  modalContainerRef
}: Props) {
  let containerRef = useRef<HTMLDivElement>(null); // ResizeObserver so we can dynamicall animate the container height

  const [resizeObserver] = useState<ResizeObserver>(() => {
    return new ResizeObserver(entries => {
      if (entries.length > 1) console.error('## Modal: ResizeObserver entries should never be more than 1'); // query by hardcoded id. Alternatively we can pass down the parent as a ref prop.

      let el = modalContainerRef.current;

      if (el) {
        const {
          height = 0,
          width = 0
        } = entries[0].contentRect;
        el.style.height = height ? height + 'px' : 'auto';
        el.style.width = width ? width + 'px' : 'auto';
      }
    });
  });
  const [isPresent, safeToRemove] = usePresence();
  useEffect(() => {
    if (isPresent) {
      containerRef.current && resizeObserver.observe(containerRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }

    !isPresent && safeToRemove && setTimeout(safeToRemove, 500);
  }, [isPresent, safeToRemove, resizeObserver, scope]);
  const onAnimationStart = useCallback(() => {
    let el = modalContainerRef.current;

    if (el) {
      el.classList.add('animating');
      el.classList.remove('animated');
    }
  }, [modalContainerRef]);
  const onAnimationComplete = useCallback(() => {
    let el = document.getElementById('modal-switch--' + scope);

    if (el) {
      el.classList.remove('animating');
      el.classList.add('animated');
    } // Remove transform position after some time it has ended, to prevent fixed position issues, specially for mention box
    // https://www.darrenlester.com/blog/why-fixed-position-element-not-relative-to-viewport


    setTimeout(() => containerRef.current?.style.removeProperty('transform'), 400);
  }, [scope]);
  return <div className={cx(positioner, isPresent && 'present', hide && hideClass)}>
      <motion.div ref={containerRef} className={modalInteriorContainer} // @TODO make this configurable in case we want enter animations
    initial={firstModal ? undefined : 'enter'} animate="center" exit="exit" variants={pageVariants} transition={pageTransition} onAnimationStart={onAnimationStart} onAnimationComplete={onAnimationComplete}>
        <Suspense fallback={<BrandSpinner />}>{children}</Suspense>
      </motion.div>
    </div>;
}

export default ModalInterior; // @NOTE if we do position absolute, we rely on the ResizeObserver to get any sizes
// basically position absolute makes dynamic widths work, width 0 makes missing ResizeObserver work

const positioner = "p15k22qf";
const hideClass = "h1r5xyo4";
const modalInteriorContainer = "mia4ak8";

require("../../../.linaria-cache/packages/oxcart/src/scopes/nav/ModalInterior.linaria.module.css");