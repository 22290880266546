import { PostFeedItem } from '@commonstock/common/src/api/feed'
import { useGetProfile } from '@commonstock/common/src/api/profile'
import { useAuth } from '../auth/AuthContext'
import PaneHeader from 'src/scopes/nav/PaneHeader'
import ProfileBlock from './ProfileBlock'

type Props = {
  feedItem: PostFeedItem
}

function AuthorBlock({ feedItem }: Props) {
  const { userUuid } = useAuth()
  const [profile] = useGetProfile({ meta: { user_uuid: feedItem.user_uuid } })

  return (
    <>
      <PaneHeader title="Author" />
      <ProfileBlock isMe={userUuid === profile?.user_uuid} profile={profile} pending={!profile} followButton />
    </>
  )
}

export default AuthorBlock
