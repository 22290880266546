import React from 'react'
import { useLineChart } from './LineChartContext'
import { useChartHover } from './ChartHoverContext'
import { Colors } from '../../theme/constants'

function ReferenceLine() {
  const { width, yScale, margin, showReferenceLine, referenceLineValue } = useLineChart()
  const { hoverIndex } = useChartHover()
  if (showReferenceLine === 'never' || referenceLineValue === undefined) return null
  const yPosition = yScale(referenceLineValue)
  return (
    <g className="reference-line">
      <line
        y="0"
        stroke={showReferenceLine !== 'hover' || hoverIndex !== null ? Colors.SeparatorPrimary : 'transparent'}
        strokeDasharray={'4 2'}
        fill="none"
        fillOpacity="1"
        strokeWidth="1"
        x1={margin.left}
        y1={yPosition}
        x2={width - margin.left - margin.right}
        y2={yPosition}
        className="reference-line"
      ></line>
    </g>
  )
}

export default React.memo(ReferenceLine)
