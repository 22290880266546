import { UserProfile, UserProfileType } from '@commonstock/common/src/api/profile'
import React from 'react'
import { BlockedProfileButton } from './ProfileButtons'
import FollowProfile from './FollowProfile'
import ProfileAlerts from './ProfileAlerts'
import SendMessage from './SendMessage'
import { useTheme } from 'src/theme/ThemeContext'

const HeaderButtons = ({ profile }: { profile?: UserProfile }) => {
  const { isMobile } = useTheme()

  if (!profile) return null
  const { blocked, blocked_me } = profile || {}
  const isPolitician = profile.type === UserProfileType.Politician

  return (
    <>
      {blocked ? (
        <BlockedProfileButton profile={profile} />
      ) : !blocked_me ? (
        <>
          {!isMobile && !isPolitician && <SendMessage profilePortfolio profile={profile} />}
          {profile.followed_by_me && <ProfileAlerts profile={profile} />}
          <FollowProfile profilePortfolio profile={profile} />
        </>
      ) : null}
    </>
  )
}

export default HeaderButtons
