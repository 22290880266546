import { createUseFetch } from '@commonstock/client/src/react/createUseFetch'
import { Apis } from '../types/apis'

type OriginalImage = {
  frames: string
  hash: string
  height: string
  mp4: string
  mp4_size: string
  size: string
  url: string
  webp: string
  webp_size: string
  width: string
}

type FixedWidthImage = {
  height: string
  mp4: string
  mp4_size: string
  size: string
  url: string
  webp: string
  webp_size: string
  width: string
}

type OriginalStill = {
  height: string
  size: string
  url: string
  width: string
}

export function isGif(gif: string | File | Gif): gif is Gif {
  return !!gif && typeof gif === 'object' && gif?.type === 'gif' && 'images' in gif
}

export type Gif = {
  type: string
  id: string
  url: string
  slug: string
  bitly_gif_url: string
  bitly_url: string
  embed_url: string
  username: string
  source: string
  title: string
  rating: string
  content_url: string
  source_tld: string
  source_post_url: string
  is_sticker: number
  import_datetime: string
  trending_datetime: string
  analytics_response_payload: string
  images: {
    fixed_width: FixedWidthImage
    original: OriginalImage
    original_still: OriginalStill
    [key: string]: {
      [key: string]: string
    }
  }
}
type Response = {
  data: Gif[]
  pagination: {
    total_count: number
    count: number
    offset: number
  }
  meta: {
    status: number
    msg: string
    response_id: string
  }
}

type GifParams = {
  meta: {
    apiKey: string
    query?: string
  }
}
const useGetGifs = createUseFetch<Response, GifParams>({
  key: 'gif-list',
  path: ({ meta: { apiKey, query } }) => {
    return `/v1/gifs/${query ? 'search' : 'trending'}?api_key=${apiKey}&q=${query}`
  },
  api: Apis.Giphy
})

export { useGetGifs }
