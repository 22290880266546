import React from 'react';
import LikeIcon from '../likes/LikeIcon';
import { Spacing } from '../../composing/Spacing';
import { FeedItem } from '@commonstock/common/src/api/feed';
import { css } from '@linaria/core';
import { Colors } from 'src/theme/constants';
import { destructContentFeedItem, getContentLikeType } from 'src/scopes/content/utils';

function ContentColumnActions({
  feedItem
}: {
  feedItem: FeedItem;
  detail?: boolean;
}) {
  const {
    content,
    user
  } = destructContentFeedItem(feedItem);
  if (!content || !user) return null;
  return <div className={backgroundColumn}>
      <Spacing margin={[0, 1, 0, 0.5]}>
        <div>
          <LikeIcon likes={content.likes} parentUuid={content.uuid} parentType={getContentLikeType(feedItem)} authorUuid={user.uuid} columnLayout />
        </div>
      </Spacing>
    </div>;
}

export default ContentColumnActions;
const backgroundColumn = "b1nw4fny";

require("../../../.linaria-cache/packages/oxcart/src/scopes/content/ContentColumnActions.linaria.module.css");