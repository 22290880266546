import useEventListener from './useEventListener'

export type Keys = Array<string | number> | string | number

type Handler = (e: KeyboardEvent) => any
/**
 * @param keys either a key or keyCode, can optionally be array to match many
 * @param handler callback to be invoked when key or keyCode is matched
 */
function useKeyPress(keys: Keys, handler: Handler, element?: Element | Window) {
  if (!element && typeof window !== 'undefined') {
    element = window
  }
  useEventListener(
    'keyup',
    (e: KeyboardEvent) => {
      if (
        !Array.isArray(keys) ? keys === e.key || keys === e.keyCode : keys.includes(e.key) || keys.includes(e.keyCode)
      )
        handler(e)
    },
    element
  )
}

// useful if we need to act before other handlers
export function useKeyDown(keys: Array<string | number> | string | number | RegExp, handler: Handler) {
  useEventListener('keydown', (e: KeyboardEvent) => {
    if (
      Array.isArray(keys)
        ? keys.includes(e.key) || keys.includes(e.keyCode)
        : keys instanceof RegExp
        ? e.key.match(keys) || String(e.keyCode).match(keys)
        : keys === e.key || keys === e.keyCode
    ) {
      handler(e)
    }
  })
}

export default useKeyPress
