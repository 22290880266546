import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAddToWatchlist, useDeleteWatchlistAsset } from '../portfolio/PortfolioHooks'
import { usePortfolio } from '../portfolio/PortfolioHooks'
import { Asset } from '@commonstock/common/src/types'
import { useUser } from '../auth/AuthContext'
import Tooltip from '../../components/Tooltip'
import { SecondaryButton } from '../../composing/CSButton'
import { cx } from '@linaria/core'
import { paneHeaderButton, PaneHeaderLabel } from 'src/components/styles'
import { JoinModalPublic } from 'src/scopes/public/JoinModalPublic'
import useLoggedOutModal from 'src/scopes/public/useLoggedOutModal'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { useTheme } from 'src/theme/ThemeContext'

const WatchAsset = ({ asset }: { asset?: Asset }) => {
  const [optimisticState, setOptimisticState] = useState<undefined | boolean>(undefined)
  const addToWatchlist = useAddToWatchlist()
  const deleteWatchlistAsset = useDeleteWatchlistAsset()
  const { refetchWatchlist, watchlist } = usePortfolio()
  const [user] = useUser()
  const loggedOutModal = useLoggedOutModal('Asset Header')

  const isOnWatchList = useMemo(
    () =>
      optimisticState !== undefined
        ? optimisticState
        : !!asset && !!watchlist && watchlist.some(a => a.asset_symbol === asset.symbol),
    [asset, watchlist, optimisticState]
  )

  useEffect(() => {
    setOptimisticState(undefined)
  }, [asset])

  const onClick = useCallback(async () => {
    if (!isLoggedIn()) {
      loggedOutModal('Join', <JoinModalPublic />)
      return
    }
    if (!asset || !user) throw new Error('Unexpected error, asset or user is not found')
    setOptimisticState(!isOnWatchList)
    try {
      if (isOnWatchList) {
        const item = watchlist?.find(item => item.asset_symbol === asset.symbol)
        if (item) {
          await deleteWatchlistAsset({ id: item.id })
        }
      } else {
        await addToWatchlist({
          json: {
            asset_symbol: asset.symbol,
            asset_type: asset.kind
          }
        })
      }
    } catch (err) {
      alert('Unable to update watchlist')
      setOptimisticState(undefined)
    }
    refetchWatchlist()
  }, [addToWatchlist, asset, deleteWatchlistAsset, isOnWatchList, loggedOutModal, refetchWatchlist, user, watchlist])

  const { isMobile } = useTheme()

  if (!asset) return null
  return (
    <Tooltip tip={isOnWatchList ? 'Remove from watchlist' : 'Add to watchlist'}>
      <SecondaryButton className={cx(paneHeaderButton)} onClick={onClick} mobile={isMobile}>
        <PaneHeaderLabel>{isOnWatchList ? 'Watched' : 'Watch'}</PaneHeaderLabel>
      </SecondaryButton>
    </Tooltip>
  )
}

export default WatchAsset
