import React, { useMemo } from 'react';
import { css, cx } from '@linaria/core';
import { FlexRow } from '../composing/Flex';
import { TextCaption } from '../composing/CSText';
import { Colors } from '../theme/constants';
import { DotCircle } from '../theme/NewIcons';
import { formatLargeMoney, formatTimeRelative } from '@commonstock/common/src/utils/format';
import { PostFeedItem, TradeFeedItem, isPostFeedItem } from '@commonstock/common/src/api/feed';
import { destructPostFeedItem } from '../scopes/post/utils';
import { destructTradeFeedItem } from '../scopes/trade/utils';
import { SpacerH, Spacing } from '../composing/Spacing';
import { ProfileAvatar } from './Avatar';
import CSLink from 'src/composing/CSLink';
import { Routes } from 'src/scopes/nav/constants';
import { flexCentered } from 'src/theme/AtomicClasses';
import { ContentDetailLink, getContentLink } from 'src/scopes/content/DetailContentWrapper';
import { CardLayoutTypes } from 'src/scopes/feed/constants';
type Props = {
  feedItem: PostFeedItem | TradeFeedItem;
  omitUser?: boolean;
};

function SmallCardFooter({
  feedItem,
  omitUser
}: Props) {
  const {
    post,
    trade,
    user
  } = useMemo(() => {
    if (isPostFeedItem(feedItem)) {
      const {
        post,
        user
      } = destructPostFeedItem(feedItem);
      return {
        post,
        user
      };
    } else {
      const {
        trade,
        user
      } = destructTradeFeedItem(feedItem);
      return {
        trade,
        user
      };
    }
  }, [feedItem]);
  if (!post && !trade || !user) return null;
  const comments = post?.comments || trade?.comments;
  const likes = post?.likes || trade?.likes;
  const published_at = post?.published_at || trade?.created_at;
  return <FlexRow alignCenter className={overflow}>
      <CSLink href={Routes.usernameProfile(user.username)} capture unstyled className={cx(flexCentered, overflow)}>
        {!omitUser && <>
            <ProfileAvatar border avatar={user.picture} name={user.name} size={0.75} />
            <SpacerH rem={0.375} />
            <TextCaption color={Colors.TextSecondary} ellipsis className={textClass}>
              {user.name}
            </TextCaption>
            <DotSeparator />
          </>}
      </CSLink>
      <TextCaption color={Colors.TextSecondary} className={flexCentered}>
        {published_at && <CSLink href={getContentLink(feedItem)} capture unstyled>
            {formatTimeRelative(published_at)}
          </CSLink>}
        {!!comments?.count && <>
            <DotSeparator />
            <ContentDetailLink feedItem={feedItem} cardLayout={CardLayoutTypes.Default} hash={'comments'}>
              {comments.count} {comments.count === 1 ? 'Comment' : 'Comments'}
            </ContentDetailLink>
          </>}
        {likes?.value ? <>
            <DotSeparator />
            {formatLargeMoney(likes.value)} likes
          </> : likes?.count ? <>
            <DotSeparator />
            {likes.count} {likes.count === 1 ? 'Upvote' : 'Upvotes'}
          </> : null}
      </TextCaption>
    </FlexRow>;
}

function DotSeparator() {
  return <Spacing margin={[0, 0.25, 0.125]}>
      <DotCircle size={0.25} color={Colors.TextSecondary} className={dotClass} />
    </Spacing>;
}

export default SmallCardFooter;
const dotClass = "d1kzir2r";
const textClass = "t3e823o";
const overflow = "oduxs1r";

require("../../.linaria-cache/packages/oxcart/src/components/SmallCardFooter.linaria.module.css");