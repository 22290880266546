import { Routes } from '../nav/constants'
import { useGoto } from '../nav/navHooks'
import { useGoToWelcome } from './authHooks'

export const MIN_PASSWORD_LENGTH = 8

const isPasswordStrong = (password: string) => {
  return password.length >= MIN_PASSWORD_LENGTH
}

export const validateEmail = (value: string) => {
  if (!value.trim()) return "Please make sure it's a valid email address"
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) return "Please make sure it's a valid email address"
}

export const validateName = (value: string) => {
  if (!value.trim()) return 'Please enter your name'
}

export const validateUsername = (value: string) => {
  if (!value.trim()) return 'Please enter your username'
  if (value.length < 3) return 'Username must be at least 3 characters long'
}

export const validatePasswordLogin = (value: string) => {
  if (!value.trim()) return `At least ${MIN_PASSWORD_LENGTH} characters, please`
}

export const validatePasswordSignup = (value: string) => {
  if (!value.trim()) return `At least ${MIN_PASSWORD_LENGTH} characters, please`
  if (!isPasswordStrong(value)) return `At least ${MIN_PASSWORD_LENGTH} characters, please`
}

export function useGoToNextPage(current: string) {
  const onboardingDestination = {
    [Routes.OnboardProfile]: useGoto(Routes.OnboardTwitter), // useGoto(Routes.OnboardTags), shortened onboarding experiment
    [Routes.OnboardTags]: useGoto(Routes.OnboardTwitter),
    [Routes.OnboardTwitter]: useGoto(Routes.OnboardTopInvestors),
    [Routes.OnboardTwitterFollow]: useGoto(Routes.OnboardTopInvestors),
    [Routes.OnboardTopInvestors]: useGoToWelcome(), // useGoto(Routes.OnboardLinkAccount), shortened onboarding experiment
    [Routes.OnboardLinkAccount]: useGoToWelcome()
  }

  if (Object.keys(onboardingDestination).includes(current) === false)
    throw 'You need to provide a valid Onboarding Route'

  return onboardingDestination[current]
}
