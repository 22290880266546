export function setIsTracked(key: string, ttl: number) {
  const now = new Date()

  const item = {
    wasTracked: true,
    expiry: now.getTime() + ttl
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getIsTracked(key: string) {
  const itemStr = localStorage.getItem(key)

  if (!itemStr) {
    return false
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return false
  }
  return true
}
