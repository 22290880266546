import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { TextEditorContext } from '../text-editor/TextEditorContext';
import { Colors } from '../../theme/constants';
import { SpinnerSVG } from '../../components/Spinner';
import { Send } from '../../theme/NewIcons';
import useKeyPress from '@commonstock/common/src/utils/useKeyPress';
import { GroupChannel } from 'sendbird';
import { IconButton, UnstyledButton } from '../../composing/CSButton';
import { css } from '@linaria/core';
type Props = {
  loading?: boolean;
  onSubmit: (text?: string) => any;
  channel?: GroupChannel;
  hasFiles: boolean;
}; // handles conditional showing only when text exists, and typing indicator

export function MessageTextSubmit({
  onSubmit,
  loading,
  channel,
  hasFiles
}: Props) {
  let editor = useContext(TextEditorContext);
  let [ready, setReady] = useState(false);
  const typing = useRef<ReturnType<typeof setTimeout>>();
  const prevTextRef = useRef('');
  const signalTypingIndicator = useCallback((currentText: string) => {
    if (!channel) return;
    if (typing.current) clearInterval(typing.current);

    try {
      typing.current = setTimeout(() => channel.endTyping(), 2000);
      if (currentText === prevTextRef.current) return;
      prevTextRef.current = currentText;
      if (currentText) channel.startTyping();else channel.endTyping();
    } catch (err) {// ignore this error
    }
  }, [channel]);
  useEffect(() => {
    return () => typing.current && clearInterval(typing.current);
  }, []);
  useEffect(() => {
    editor.listen(el => {
      setReady(Boolean(el.textContent?.trim() !== '' || el.querySelector('img')));
      signalTypingIndicator(el?.textContent || '');
    });
  }, [editor, signalTypingIndicator]);
  let textSubmit = useCallback(() => {
    let text = editor.getMarkdown();
    onSubmit(text);
  }, [onSubmit, editor]); // only enable submit if we have text and the helper is not active

  return ready || hasFiles ? <MessageSubmit loading={loading} onSubmit={textSubmit} /> : <IconButton className={sendButton} disabled aria-label={'Send message'}>
      <Send size={1.25} />
    </IconButton>;
}
type MessageSubmitProps = {
  loading?: boolean;
  onSubmit: () => void;
};

function MessageSubmit({
  onSubmit,
  loading
}: MessageSubmitProps) {
  let editor = useContext(TextEditorContext);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  useKeyPress('Enter', e => {
    if (editor.inputRef !== document.activeElement && submitButtonRef.current !== document.activeElement || editor.inputRef?.classList.contains('mentioning')) return null;
    if (!e.shiftKey && !e.altKey) onSubmit();
  });
  return <UnstyledButton onClick={onSubmit} className={sendButton} aria-label={'Send message'}>
      {loading ? <SpinnerSVG /> : <>
          <Send size={1.25} color={Colors.IconHover} />
          <Send size={1.25} active />
        </>}
    </UnstyledButton>;
}

const sendButton = "s481pa3";

require("../../../.linaria-cache/packages/oxcart/src/scopes/chat/MessageInputSubmit.linaria.module.css");