import { ActionModal } from '../modal/Modals'
import { TextBodyRegular } from 'src/composing/CSText'
import { SpacerV } from 'src/composing/Spacing'
import { useModal } from 'src/scopes/modal/Modal'
import { DangerButton, PrimaryButton } from 'src/composing/CSButton'
import { useState } from 'react'
import { useBanUser, UserProfile, useUnbanUser } from '@commonstock/common/src/api/profile'
import { captureException } from '@sentry/react'

export enum BanAction {
  Ban = 'ban',
  Unban = 'unban'
}

type Props = {
  profile: UserProfile
  action: BanAction
}

function BanModal({ profile, action }: Props) {
  const { actions: modalActions } = useModal()
  const banUser = useBanUser()
  const unbanUser = useUnbanUser()
  const [pending, setPending] = useState(false)
  const [fail, setFail] = useState(false)

  const onClick = async () => {
    try {
      setPending(true)
      const params = { meta: { uuid: profile.user_uuid } }
      action === BanAction.Ban ? await banUser(params) : await unbanUser(params)
      modalActions.close()
    } catch (err) {
      captureException(err)
      setFail(true)
      setPending(false)
    }
  }

  const text =
    action === BanAction.Ban
      ? `@${profile.username} will no longer be able to use Commonstock`
      : `@${profile.username} will be allowed to use Commonstock`

  return (
    <ActionModal title={`${action === BanAction.Ban ? 'Ban' : 'Unban'} User`}>
      <TextBodyRegular centered>{text}</TextBodyRegular>
      <SpacerV rem={1.5} />
      {action === BanAction.Ban ? (
        <DangerButton block large onClick={onClick} loading={pending}>
          {fail ? 'Ban Failed' : 'Ban'}
        </DangerButton>
      ) : (
        <PrimaryButton block large onClick={onClick} loading={pending}>
          {fail ? 'Unban Failed' : 'Unban'}
        </PrimaryButton>
      )}
    </ActionModal>
  )
}

export default BanModal
