import { Gif, isGif } from '@commonstock/common/src/api/gifs'
import React from 'react'
import { getRangeOrEnd, resetSelectionRange } from './utils'
import { EditingImageBlock } from './EditingImageBlock'
import ReactDOM from 'react-dom'

type createMediaLinkType = (file: string | File | Gif, external?: boolean | undefined) => Promise<string | undefined>
type DefaultInsertFilesType = {
  target: HTMLDivElement | null
  files: Array<File | string | Gif>
  isMediaLink?: boolean
  createMediaLink: createMediaLinkType
  setIsUploading: (v: boolean) => void
}
export async function defaultInsertFiles({
  target,
  files,
  isMediaLink,
  createMediaLink,
  setIsUploading
}: DefaultInsertFilesType) {
  if (!target) return console.error('missing targetRef')
  setIsUploading(true)
  let range = getRangeOrEnd(target)

  if (range.collapsed && range.endContainer.nodeName === 'LI') {
    if (!(range.endContainer.textContent || '').trim()) document.execCommand('insertParagraph')
    else {
      document.queryCommandState('insertorderedlist') && document.execCommand('insertorderedlist')
      document.queryCommandState('insertunorderedlist') && document.execCommand('insertunorderedlist')
    }
  }

  resetSelectionRange(getRangeOrEnd(target))

  document.execCommand('insertParagraph')

  const div = document.createElement('DIV')
  div.id = `${Math.random()}`
  div.contentEditable = 'false'
  document.execCommand('insertHtml', false, `${div.outerHTML}<br/>`)
  ReactDOM.render(
    <>
      {files.map((file, idx) => {
        const url =
          file instanceof File ? URL.createObjectURL(file) : isGif(file) ? file.images.original_still.url : file
        return (
          <EditingImageBlock
            key={idx}
            src={url}
            file={file}
            createMediaLink={createMediaLink}
            isMediaLink={isMediaLink}
          />
        )
      })}
    </>,
    document.getElementById(div.id)
  )

  resetSelectionRange(getRangeOrEnd(target))
  setIsUploading(false)
}

// Upload images from pasted text to media-service, if successfull, we replace the img src, if it fails we replace with a hint text
// @TODO: better identify if all images got replaced and block Posting button
export async function handlePastedImages(createMediaLink: createMediaLinkType) {
  const imgs = document.querySelectorAll('img.pasted-image') as NodeListOf<HTMLImageElement>
  imgs.forEach(img => {
    const fragment = document.createDocumentFragment()
    ReactDOM.render(<EditingImageBlock src={img.src} file={img.src} createMediaLink={createMediaLink} />, fragment)
    img.replaceWith(fragment)
  })
}
