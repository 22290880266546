import { HookOptions } from '../react/createUseFetch'
import { ReturnTuple } from '../constants'
import { useRef, useMemo } from 'react'

export default function cacheLast<Payload, Params>(
  useFetch: (params: Params, hookOptions?: HookOptions) => ReturnTuple<Payload, Params>
) {
  return (params: Params, hookOptions?: HookOptions) => {
    let returnTuple = useFetch(params, hookOptions)
    let activeValueRef = useRef<ReturnTuple<Payload, Params>>(returnTuple)
    if (returnTuple[0] && !returnTuple[2]) activeValueRef.current = returnTuple
    let cachedReturn = useMemo(() => {
      // return the cached value, but with a loading value of the present value
      let r = [...activeValueRef.current]
      r.splice(2, 1, returnTuple[2])
      return r as ReturnTuple<Payload, Params>
    }, [returnTuple])
    return cachedReturn
  }
}
