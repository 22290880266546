import React, { useCallback, useState } from 'react'
import { FollowStatus, UserProfile } from '@commonstock/common/src/api/profile'
import { useCreateConversation } from '@commonstock/common/src/api/chat'
import { useChat } from '../chat/ChatContext'
import { SecondaryButton } from '../../composing/CSButton'
import { useChannels } from '../chat/ChannelContext'
import { Message } from '../../theme/NewIcons'
import { cx } from '@linaria/core'
import { paneHeaderIconButton } from 'src/components/styles'
import useLoggedOutModal from '../public/useLoggedOutModal'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { JoinModalPublic } from '../public/JoinModalPublic'
import { useTheme } from 'src/theme/ThemeContext'

function useSendDirectMessage() {
  const { sendbird, isConnected } = useChat()
  const [pending, setPending] = useState(false)
  const createConversation = useCreateConversation()
  const { getUpdatedChannelAndSelect } = useChannels()

  let userId = sendbird.currentUser?.userId

  const onClickSendMessage = useCallback(
    async profileUuid => {
      setPending(true)
      if (!userId) throw new Error('## Chat: sendbird user not known')
      if (!isConnected) {
        setTimeout(onClickSendMessage, 100)
        return
      }
      const user_uuids = [userId, profileUuid]
      try {
        const response = await createConversation({ json: { user_uuids } })
        if (response.success?.payload) {
          await getUpdatedChannelAndSelect(response.success?.payload.channel_url)
        }
      } catch (error) {
        console.error('## SendMessage: Error while creating conversation', error)
      } finally {
        setPending(false)
      }
    },
    [createConversation, getUpdatedChannelAndSelect, isConnected, userId]
  )

  return { pending, onClickSendMessage }
}

function SendMessage({ profile, profilePortfolio }: { profile: UserProfile; profilePortfolio?: boolean }) {
  const { pending, onClickSendMessage } = useSendDirectMessage()

  const loggedOutModal = useLoggedOutModal(`Message User`)
  const onClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      if (!isLoggedIn()) {
        loggedOutModal('Join', <JoinModalPublic />)
        return
      }
      await onClickSendMessage(profile.user_uuid)
    },
    [loggedOutModal, onClickSendMessage, profile.user_uuid]
  )
  const { isMobile } = useTheme()

  if (profile.follow_status !== FollowStatus.FOLLOWED && profile.private) return null
  if (profilePortfolio) {
    return (
      <SecondaryButton
        onClick={onClick}
        loading={pending}
        className={cx(paneHeaderIconButton)}
        mobile={isMobile}
        aria-label={'Message user'}
      >
        <Message />
      </SecondaryButton>
    )
  }
  return <SecondaryButton onClick={onClick}>Message</SecondaryButton>
}

export default SendMessage
