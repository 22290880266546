import React, { ReactNode, useState, useMemo, useEffect, useRef, RefObject } from 'react';
import useEventListener from '@commonstock/common/src/utils/useEventListener';
import { Colors, defaultSpringConfig, ZIndexLayers } from '../theme/constants';
import { motion } from 'framer-motion';
import { css } from '@linaria/core';
type Props = {
  tip: string;
  containerRef: RefObject<HTMLDivElement | undefined>;
  deactivate: () => void;
};

function TooltipView({
  tip,
  containerRef,
  deactivate
}: Props) {
  let [[x, y], setXY] = useState<[number, number]>([-1, -1]);
  useEventListener('mousemove', (e: MouseEvent) => {
    // we do this check because the mouseLeave event is not reliable if there are hidden children (e.g. drop down)
    let elements = document.elementsFromPoint(e.clientX, e.clientY);
    let matched = elements.some(el => el === containerRef.current);
    if (!matched) deactivate();
    let {
      clientX,
      clientY
    } = e;
    if (Math.abs(clientX - x) > 12 || Math.abs(clientY - y) > 12) setXY([clientX, clientY]);
  });
  let style = {
    left: x,
    top: y,
    opacity: 1
  };
  let initial = {
    left: x,
    top: y,
    opacity: 0
  };
  return x === -1 ? null : <motion.div className={tipClass} initial={initial} animate={style} transition={defaultSpringConfig}>
      {tip}
    </motion.div>;
}

export default function Tooltip({
  tip,
  children,
  disable
}: {
  tip?: string;
  children?: ReactNode;
  disable?: boolean;
}) {
  let [active, setActive] = useState(false);
  const [activate, deactivate] = useMemo(() => {
    return [() => setActive(true), () => setActive(false)];
  }, []);
  let ref = useRef<HTMLDivElement>();
  useEffect(() => {
    let nodes = ref.current?.querySelectorAll('[title]'); // remove title attributes from all children to avoid the native tooltip conflicting with ours.

    nodes?.forEach(n => n.removeAttribute('title'));
  }, []);
  useEffect(() => {
    if (disable && active) {
      setActive(false);
    }

    return () => {};
  }, [active, disable]);
  return (// @ts-ignore not sure issue here with ref type
    <div ref={ref} onMouseEnter={!disable ? activate : () => {}} onMouseLeave={deactivate}>
      {!!tip && active && !disable && <TooltipView tip={tip} containerRef={ref} deactivate={deactivate} />}
      {children}
    </div>
  );
}
const tipClass = "tt7l7b4";

require("../../.linaria-cache/packages/oxcart/src/components/Tooltip.linaria.module.css");