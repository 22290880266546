import { useEffect } from 'react'
import createUseEvent from '../createUseEvent'

const useTrackWebOnboardingScreenAction = createUseEvent<void>('Web Onboarding Screen')
export const useTrackWebOnboardingScreen = () => {
  let trackScreen = useTrackWebOnboardingScreenAction()
  useEffect(() => trackScreen(), [trackScreen])
}

export enum OnboardingAction {
  Skip = 'Skip',
  Back = 'Back',
  Next = 'Next',
  FollowAll = 'Follow All',
  Follow = 'Follow'
}
export const useTrackWebOnboardingAction = createUseEvent<{ action: OnboardingAction }>('Web Onboarding Action')
