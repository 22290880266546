import { createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods } from '@commonstock/client/src/constants'
import { Apis } from '../types/apis'

type Media = {
  url: string
}

type MediaParams = {
  body: Blob
}

const usePostMediaAction = createUseFetchAction<Media, MediaParams>({
  key: 'media-create',
  method: HttpMethods.Post,
  path: '/media/upload',
  api: Apis.Media
})

export { usePostMediaAction }
