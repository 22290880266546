import React from 'react'
import { Routes } from 'src/scopes/nav/constants'
import CSLink from '../../composing/CSLink'
import { paneHeaderButton, PaneHeaderLabel, ProfileHeaderButton } from '../../components/styles'
import { cx } from '@linaria/core'
import { useTheme } from 'src/theme/ThemeContext'

const EditProfileButton = () => {
  const { isMobile } = useTheme()

  return (
    <CSLink href={Routes.Account}>
      <ProfileHeaderButton className={cx(paneHeaderButton)} mobile={isMobile}>
        <PaneHeaderLabel>Edit profile</PaneHeaderLabel>
      </ProfileHeaderButton>
    </CSLink>
  )
}
export default EditProfileButton
