import * as React from 'react'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { cx } from '@linaria/core'
import { HTMLDivProps } from 'src/utils/types'

export const dynamicHydrateClass = 'dynamic'

if (isLoggedIn()) {
  let dynamicEls = document.getElementsByClassName(dynamicHydrateClass)
  Array.from(dynamicEls).forEach(el => el.remove())
}

// this component will remove itself from the dom when rendering client side for logged in users, that way we avoid SSR issues
// for design considerations see https://www.notion.so/commonstock/Server-Side-Rendering-f60751986efa4059a2077235673221ba
export default function DynamicHydrateDiv({
  children,
  className,
  id,
  ...restProps
}: { children?: React.ReactNode } & HTMLDivProps) {
  return (
    <div className={cx(dynamicHydrateClass, className)} id={id} {...restProps}>
      {children}
    </div>
  )
}
