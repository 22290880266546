import React from 'react'
import { Expander, FlexRow } from '../../composing/Flex'
import { SkeletonBlock } from '../../components/Skeleton'
import { SpacerH, Spacing } from '../../composing/Spacing'
import { UserBlockSkeleton } from '../../components/UserBlock.skeleton'
import { useDev } from '../dev/DevContext'
import { cx } from '@linaria/core'
import { devTransparent, devTransparentOutline, scrollY } from '../../theme/AtomicClasses'

export function LeaderboardListSkeleton() {
  const { skeletonShow } = useDev()

  return (
    <div className={cx(scrollY, skeletonShow && devTransparent, skeletonShow && devTransparentOutline)}>
      {[...Array(5)].map((_v, i) => (
        <Spacing key={i} padding={[0, 0, 1.5]}>
          <FlexRow alignCenter>
            <SkeletonBlock h={1.125} w={1.5} />
            <SpacerH rem={1} />
            <UserBlockSkeleton />
            <Expander />
            <SkeletonBlock w={4} h={2} />
            <SpacerH rem={1} />
            <SkeletonBlock w={3} h={1} />
          </FlexRow>
        </Spacing>
      ))}
    </div>
  )
}
