import { useEffect, useRef } from 'react'
import { Screen } from '../constants'

import { track } from '../mixpanel'

// reexport for backwards compat, this will go away once we deprecate v1 events
export { Screen as PageViewType }

/*
 * Triggers "Page View" event
 * And Registers "page" superproperty when a user views a page
 */
type Props = {
  page: Screen
  page_id?: string | null
  loading?: boolean
}

export function usePageView(data: Props) {
  const pageRef = useRef('')
  useEffect(() => {
    if (data.loading) return
    const page_id = data.page + data.page_id
    if (page_id != pageRef.current) {
      track('Page View', { page: data.page, page_id: data.page_id })
      pageRef.current = page_id
    }
  }, [data])
}
