import { cx } from '@linaria/core'
import { ClassName } from '@linaria/core/types/cx'
import React, { ReactNode } from 'react'
import { isServer } from 'src/utils/isServer'
import { useDev } from '../scopes/dev/DevContext'
import { relative } from '../theme/AtomicClasses'

// Documentation: https://www.notion.so/commonstock/SkeletonWrapper-Proposed-7f12c28956d044d7afcdc12c7ee0f640

type Props = {
  pending: boolean
  children: ReactNode
  skeleton: ReactNode
  failed?: ReactNode | false
  className?: ClassName[] | ClassName
}

export function SkeletonWrapper({ pending, children, skeleton, failed, className }: Props) {
  const { skeletonShow } = useDev()

  // Dont render dynamic content on Server, as NextJS and Linaria get messy because of this
  // Rendering as null on server side can generate some className issues
  if (isServer) return <div></div>

  if (pending) return <div>{skeleton}</div>
  if (failed) return React.isValidElement(failed) ? failed : <div></div>
  return (
    <div className={cx(relative, ...(className ? (Array.isArray(className) ? className : [className]) : []))}>
      {children}
      {skeletonShow && skeleton}
    </div>
  )
}
