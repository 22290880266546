import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CommentSummary, ParentTypes } from '@commonstock/common/src/api/comment'
import { BrandSpinner } from '../../components/Spinner'
import Comments from './Comments'
import { SpacerV } from '../../composing/Spacing'
import { CardLayoutTypes } from '../feed/constants'
import HorizontalStroke from 'src/composing/HorizontalStroke'

type Props = {
  initialComments?: CommentSummary
  uuid: string
  type: ParentTypes
  isModal: boolean
  rootUserUuid: string
  cardLayout: CardLayoutTypes
}
function RootComments({ uuid, type, isModal, initialComments, rootUserUuid, cardLayout }: Props) {
  const commentsRef = useRef<HTMLDivElement>(null)
  const [hasScrolled, setHasScrolled] = useState(false)

  const loadedAllContents = useMemo(() => {
    const allComments = initialComments?.comments.flatMap(c => [c, ...c.replies])
    return allComments?.length === initialComments?.count
  }, [initialComments])

  useEffect(() => {
    if (!hasScrolled && loadedAllContents && location.hash === `#comments`) {
      requestAnimationFrame(() => {
        commentsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        setHasScrolled(true)
      })
    }
  }, [hasScrolled, loadedAllContents])

  return (
    <>
      <div ref={commentsRef} />
      <SpacerV />
      <HorizontalStroke />
      {!loadedAllContents || !initialComments ? (
        <>
          <SpacerV />
          <BrandSpinner size={'2rem'} />
        </>
      ) : (
        <Comments
          comments={initialComments.comments}
          uuid={uuid}
          type={type}
          rootUuid={uuid}
          rootType={type}
          isModal={isModal}
          rootUserUuid={rootUserUuid}
          cardLayout={cardLayout}
        />
      )}
    </>
  )
}

export default RootComments
