import { HttpMethods } from '@commonstock/client/src/constants'
import { createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { createUsePagedFetch, PagerOptions } from '@commonstock/client/src/react/createUsePagedFetch'
import { FeedOutput } from './feed'

type BookmarkContentParams = {
  json: {
    item_uuid: string
  }
}

export const useBookmarkContentAction = createUseFetchAction<string, BookmarkContentParams>({
  key: 'post-pin',
  method: HttpMethods.Put,
  path: `/user-content/bookmarks/`
})
export const useUnbookmarkContentAction = createUseFetchAction<string, BookmarkContentParams>({
  key: 'post-pin',
  method: HttpMethods.Delete,
  path: `/user-content/bookmarks/`
})

const pagerOptions: PagerOptions<FeedOutput, any> = {
  getCursor: (page, resource) => {
    let list = resource?.success?.payload?.items
    let last = list && list[list.length - 1]
    return { query: { limit: 10, last_uuid: last?.uuid } }
  },
  // @NOTE: checking payload since BE is returning null for empty lists
  isTerminal: resource =>
    !!(resource.success && (!resource.success.payload || resource.success.payload.items.length < 10))
}

type BookmarksParams = {
  query: {
    last_uuid?: string
    limit?: number
  }
}

export const useGetMyBookmarks = createUsePagedFetch<FeedOutput, BookmarksParams>(
  {
    key: 'get-users-posts',
    method: HttpMethods.Get,
    path: '/user-content/bookmarks/'
  },
  pagerOptions
)
