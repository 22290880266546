import { FeedItem, isPostFeedItem, isTradeFeedItem } from '@commonstock/common/src/api/feed'
import { LikeTypes } from '@commonstock/common/src/api/like'
import { ShareType } from '@commonstock/common/src/api/link'
import { destructPostFeedItem } from 'src/scopes/post/utils'
import { destructTradeFeedItem } from 'src/scopes/trade/utils'

export function destructContentFeedItem(feedItem: FeedItem) {
  let content, user
  if (isPostFeedItem(feedItem)) {
    ;({ post: content, user } = destructPostFeedItem(feedItem))
  } else if (isTradeFeedItem(feedItem)) {
    ;({ trade: content, user } = destructTradeFeedItem(feedItem))
  }
  return { content, user, type: feedItem.resource_type }
}

export function getContentShareType(feedItem: FeedItem) {
  if (isPostFeedItem(feedItem)) {
    return ShareType.POST
  } else if (isTradeFeedItem(feedItem)) {
    return ShareType.TRADE
  }
  return null
}

export function getContentLikeType(feedItem: FeedItem) {
  if (isPostFeedItem(feedItem)) {
    return LikeTypes.POST
  } else if (isTradeFeedItem(feedItem)) {
    return LikeTypes.TRADE
  }
  return LikeTypes.POST
}

export function getFeedItemTypeBoolean(feedItem: FeedItem) {
  return {
    post: isPostFeedItem(feedItem) && feedItem,
    trade: isTradeFeedItem(feedItem) && feedItem
  }
}
