import React, { useCallback, useMemo, useState, useEffect } from 'react'
import SelectUserItem from './SelectUserItem'
import EmptyListItem from '../../components/EmptyListItem'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { GroupChannel } from 'sendbird'
import { useEphemeralModal } from '../modal/Modal'
import dynamic from 'next/dynamic'

const RemoveMember = dynamic(() => import('../chat/RemoveMember'))

type MembersListProps = {
  profiles: HydratedUserProfile[]
  setProfiles?: React.Dispatch<React.SetStateAction<HydratedUserProfile[]>>
  channel?: GroupChannel
}
function MembersList({ profiles, setProfiles, channel }: MembersListProps) {
  const modal = useEphemeralModal()

  const onRemoveUser = useCallback(
    ({ uuid }: HydratedUserProfile) => {
      const member = channel?.members?.find(m => m.userId === uuid)
      if (channel && member) {
        modal(<RemoveMember channel={channel} member={member} />)
      } else {
        setProfiles && setProfiles(prev => prev.filter(p => p.uuid !== uuid))
      }
    },
    [channel, modal, setProfiles]
  )

  const onUninviteUser = useCallback(({ uuid }: HydratedUserProfile) => {
    console.log('## Uninviting user', uuid)
    alert('@TODO implement user uninvite')
  }, [])

  const [operators, setOperators] = useState<SendBird.User[] | null>(null)
  useEffect(() => {
    if (channel && !operators) {
      const operatorListQuery = channel.createOperatorListQuery()
      operatorListQuery.next(function(operators, error) {
        if (error) return
        setOperators(operators)
      })
    }
  }, [channel, operators])

  const isOperator = useMemo(() => (channel ? channel.myRole === 'operator' : true), [channel])

  const sortedProfiles = useMemo(
    () =>
      profiles
        .filter(p => isOperator || channel?.members?.find(m => m.userId === p.uuid)?.state !== 'invited')
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          else return 1
        }),
    [channel, isOperator, profiles]
  )

  return (
    <>
      {sortedProfiles.map(profile => (
        <SelectUserItem
          key={profile.uuid}
          memberStatus={channel?.members?.find(m => m.userId === profile.uuid)?.state}
          profile={profile}
          onRemove={onRemoveUser}
          onUninvite={onUninviteUser}
          isOperator={isOperator}
          isAdmin={Boolean(!channel?.isDistinct && operators?.find(p => p.userId === profile.uuid))}
        />
      ))}
      {profiles.length === 0 && <EmptyListItem text="No user profile selected" />}
    </>
  )
}

export default MembersList
