import { useBookmarkContentAction, useUnbookmarkContentAction } from '@commonstock/common/src/api/content'
import { useCallback } from 'react'

export function useBookmarkContent() {
  const bookmarkContent = useBookmarkContentAction()
  const unbookmarkContent = useUnbookmarkContentAction()
  const bookmarkedPost = useCallback(
    ({ item_uuid, isBookmarked }: { item_uuid: string; isBookmarked: boolean }) => {
      if (isBookmarked) {
        return unbookmarkContent({ json: { item_uuid } })
          .then(data => data)
          .catch(err => {
            console.error('## bookmarkContent err:', err)
            // @TODO implement real error handling
            alert('Something went wrong. Please try again.')
            return err
          })
      } else {
        return bookmarkContent({ json: { item_uuid } })
          .then(data => data)
          .catch(err => {
            console.error('## bookmarkContent err:', err)
            // @TODO implement real error handling
            alert('Something went wrong. Please try again.')
            return err
          })
      }
    },
    [bookmarkContent, unbookmarkContent]
  )
  return bookmarkedPost
}
