import { css } from '@linaria/core';
import { UnstyledButton } from 'src/composing/CSButton';
import { Colors } from 'src/theme/constants';
import { XCircle } from 'src/theme/NewIcons';
export default function RemoveMediaButton({
  onClick
}: {
  onClick?: () => any;
}) {
  return <UnstyledButton data-remove-media={true} className={removeMedia} onClick={onClick}>
      <XCircle fill={Colors.IconDefault} color={Colors.BackgroundPrimary} size={1.25} />
    </UnstyledButton>;
}
const removeMedia = "r118tf6v";

require("../../../.linaria-cache/packages/oxcart/src/scopes/text-editor/RemoveMediaButton.linaria.module.css");