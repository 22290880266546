import { track } from '../mixpanel'

export enum SuggestedFollowsType {
  Twitter = 'Twitter',
  TopInvestors = 'Top Investors'
}

type SuggestedFollowsObject = {
  type: SuggestedFollowsType
  possible_new_follows: number
  suggestionPrompt: string
  followingUUIDs: string[]
  clickedFollowAll: boolean
  isOnboarding: boolean
}

export function newUserSignup() {
  track('New User Signup V2')
}

export function suggestedFollowsAction(data: SuggestedFollowsObject) {
  track('SuggestedFollows:Action', {
    new_follows: data.followingUUIDs.length,
    possible_new_follows: data.possible_new_follows,
    type: data.type,
    suggestion_prompt: data.suggestionPrompt,
    following_uuids: data.followingUUIDs,
    clicked_follow_all: data.clickedFollowAll,
    is_onboarding: data.isOnboarding
  })
}
