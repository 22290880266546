import { useRef, useEffect, useCallback } from 'react'

type Handler = (e: any) => any
const useEventListener = (eventName: string, handler: Handler, element?: Document | Element | Window) => {
  if (!element && typeof window !== 'undefined') {
    element = window
  }
  const savedHandler = useRef<Handler>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    // @ts-ignore need better type for global
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    const eventListener = (event: Event) => savedHandler.current?.(event)
    element?.addEventListener(eventName, eventListener)
    return () => {
      element?.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}

export function useAnimationFrameEventListener(
  eventName: string,
  handler: Handler,
  element?: Document | Element | Window
) {
  if (!element && typeof window !== 'undefined') {
    element = window
  }
  const requestedFrame = useRef<number>()

  const animationFrameHandler = useCallback(
    (event: Event) => {
      if (requestedFrame.current) {
        window.cancelAnimationFrame(requestedFrame.current)
      }
      requestedFrame.current = window.requestAnimationFrame(function() {
        handler(event)
      })
    },
    [handler]
  )
  return useEventListener(eventName, animationFrameHandler, element)
}

export default useEventListener
