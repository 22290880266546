import React from 'react';
import EmptyTab from './EmptyTab';
import { UserProfile } from '@commonstock/common/src/api/profile';
import { Blocked, Lock } from '../../theme/NewIcons';
import { TextBodyEmphasis, TextCaption } from '../../composing/CSText';
import { SpacerV } from '../../composing/Spacing';
import { Colors } from '../../theme/constants';
import { css } from '@linaria/core';

function HiddenProfile({
  profile
}: {
  profile: UserProfile;
}) {
  const {
    blocked_me,
    blocked
  } = profile;
  return <EmptyTab icon={blocked_me || blocked ? <Blocked size={2.625} gradient /> : <Lock size={2.625} gradient />} className={styledEmptyTab}>
      <TextBodyEmphasis block>
        {blocked_me ? <>You&apos;re blocked</> : blocked ? <>@{profile.username} is blocked</> : <>This account is private</>}
      </TextBodyEmphasis>
      <SpacerV rem={0.5} />
      <TextCaption block color={Colors.TextSecondary}>
        {blocked_me ? <>
            You can&apos;t follow or see @{profile.username}&apos;s <br />
            posts or trades
          </> : blocked ? <>
            You have blocked @{profile.username} from <br />
            following you
          </> : <>
            Send @{profile.username} a follow request to see <br />
            their investments, trade alerts, and content
          </>}
      </TextCaption>
    </EmptyTab>;
}

export default HiddenProfile;
const styledEmptyTab = "s1oj7ow8";

require("../../../.linaria-cache/packages/oxcart/src/scopes/profile/HiddenProfile.linaria.module.css");