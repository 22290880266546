import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'
import { BadgeType, useBadgeUsers, useUnbadgeUsers } from '@commonstock/common/src/api/profile'
import { useEffect } from 'react'
import { DangerButton, PrimaryButton } from 'src/composing/CSButton'
import { TextBodyRegular } from 'src/composing/CSText'
import { SpacerV } from 'src/composing/Spacing'
import { reportFailedResource } from 'src/dev/sentry'
import useFetchSubmit from 'src/hooks/useFetchSubmit'
import { useModal } from '../modal/Modal'
import { ActionModal } from '../modal/Modals'

export enum BadgeAction {
  ADD_BADGE = 'add_badge',
  REMOVE_BADGE = 'remove_badge'
}

type BadgeModalProps = {
  actionType: BadgeAction
  badgeType: BadgeType
  username: string
}

const badgeTitleMap = {
  [BadgeType.VERIFIED]: 'Verified Member',
  [BadgeType.TITAN]: 'Titan Club',
  [BadgeType.SHARED_BROKERAGE]: 'Public Portfolio',
  [BadgeType.CONGRESS]: 'Congressional Investor'
}

function BadgeModal({ actionType, badgeType, username }: BadgeModalProps) {
  const { actions: modalActions } = useModal()
  const addBadge = useFetchSubmit(useBadgeUsers(), reportFailedResource)
  const removeBadge = useFetchSubmit(useUnbadgeUsers(), reportFailedResource)
  // this is a bit heavy handed as we only need to refetch the profile for the current username, but it is a mod action so simplicity preferred over efficiency
  let refetchAllProfiles = useRefetchByKeys('get-user-profile', 'get-profile-username')

  const onClick = async () => {
    const submit = actionType === BadgeAction.ADD_BADGE ? addBadge.submit : removeBadge.submit
    await submit({ json: { badge_type: badgeType, usernames: [username] } })
    refetchAllProfiles()
  }

  useEffect(() => {
    if (addBadge.success || removeBadge.success) {
      modalActions.close()
    }
  }, [addBadge.success, modalActions, removeBadge.success])

  const title = actionType === BadgeAction.ADD_BADGE ? 'Add Badge' : 'Remove Badge'
  const text =
    actionType === BadgeAction.ADD_BADGE
      ? `Add "${badgeTitleMap[badgeType]}" badge to @${username}`
      : `Remove "${badgeTitleMap[badgeType]}" badge from @${username}`

  return (
    <ActionModal title={title} onEnter={onClick}>
      <TextBodyRegular centered>{text}</TextBodyRegular>
      <SpacerV rem={1.5} />
      {actionType === BadgeAction.ADD_BADGE ? (
        <PrimaryButton block large onClick={onClick} loading={addBadge.pending}>
          {addBadge.fail ? 'Add Badge Failed' : 'Add Badge'}
        </PrimaryButton>
      ) : (
        <DangerButton block large onClick={onClick} loading={removeBadge.pending}>
          {removeBadge.fail ? 'Remove Badge Failed' : 'Remove Badge'}
        </DangerButton>
      )}
    </ActionModal>
  )
}

export default BadgeModal
