import React, { Fragment } from 'react'
import { SkeletonBlock } from './Skeleton'
import { SpacerH } from '../composing/Spacing'

export function PeriodSelectorSkeleton({ large }: { large?: boolean }) {
  return (
    <>
      {[...Array(large ? 6 : 5)].map((_v, i) => (
        <Fragment key={i}>
          {!(large && i === 0) && <SpacerH rem={large ? 0.25 : 0.5} />}
          <SkeletonBlock w={'2rem'} h={'1.25rem'} borderRadius={100} />
        </Fragment>
      ))}
    </>
  )
}
