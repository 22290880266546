import { useCallback, useMemo, useState } from 'react'
import {
  PreviewBroker,
  useGetBrokerAccounts,
  useGetBrokerList,
  useGetBrokerRedirectAction,
  usePostAuthenticateBrokerAction,
  usePostConfirmBrokerOrderAction,
  REDIRECT_URL
} from '@commonstock/common/src/api/broker'
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'
import { track } from '../analytics/mixpanel'
import { Routes } from '../nav/constants'
import { useAuth } from '../auth/AuthContext'
import { Authenticated } from '../auth/constants'

export enum BrokerLinkEvents {
  Loading = 'Loading',
  Success = 'Success',
  Fail = 'Fail'
}

export function useBroker() {
  const { authenticated } = useAuth()
  const [brokers] = useGetBrokerList(null, { paused: authenticated !== Authenticated.Yes })
  const [accounts = []] = useGetBrokerAccounts(null, { paused: authenticated !== Authenticated.Yes })

  const buyingPower = useMemo(() => {
    return accounts.reduce((acc, cur) => acc + cur.buying_power, 0)
  }, [accounts])

  return { accounts, brokers, buyingPower }
}

// store broker return listener as a singleton so we can clean up orphaned ones before creating new
let lastBrokerReturnListener: null | ((e: any) => any) = null
function useConnectBroker() {
  const getBrokerRedirect = useGetBrokerRedirectAction()
  const postAuthenticateBroker = usePostAuthenticateBrokerAction()
  const refetchDependents = useRefetchByKeys('broker-accounts', 'broker-connections', 'broker-sessions')

  const authenticateBroker = useCallback(
    async (e: any, broker: string, isPrivate: boolean) => {
      try {
        window.dispatchEvent(new CustomEvent(BrokerLinkEvents.Loading))
        await postAuthenticateBroker({
          json: {
            broker,
            auth_data: { ...e.detail, redirect_uri: REDIRECT_URL },
            is_hidden_default: isPrivate
          }
        })
        track('BrokerAuth:Success', { broker_name: broker })
        window.dispatchEvent(new CustomEvent(BrokerLinkEvents.Success))
        refetchDependents()
      } catch (err) {
        window.dispatchEvent(new CustomEvent(BrokerLinkEvents.Fail))
        console.error('## authenticateBroker err:', err)
        // @TODO: ui to handle error
      }
    },
    [postAuthenticateBroker, refetchDependents]
  )

  const connectBroker = useCallback(
    async (broker: string, isPrivate: boolean) => {
      try {
        const data = await getBrokerRedirect({ meta: { broker } })
        if (data.success) {
          lastBrokerReturnListener && window.removeEventListener('broker-auth-return', lastBrokerReturnListener)

          let onBrokerReturn = (e: any) => {
            return authenticateBroker(e, broker, isPrivate)
          }

          // store broker return listener as a singleton so we can clean up orphaned ones before creating new
          lastBrokerReturnListener = onBrokerReturn

          window.open(data.success.payload.auth_url, 'Broker Login')
          window.addEventListener('broker-auth-return', onBrokerReturn)
        }
      } catch (err) {
        console.error('## connectBroker err:', err)
        // @TODO: handle error
      }
    },
    [authenticateBroker, getBrokerRedirect]
  )

  return connectBroker
}

export function useConfirmOrder() {
  const confirmBrokerOrder = usePostConfirmBrokerOrderAction()

  let confirmOrder = useCallback(
    ({ preview, broker }: { preview: PreviewBroker; broker: string }): ReturnType<typeof confirmBrokerOrder> => {
      return confirmBrokerOrder({
        json: {
          broker,
          order_data: {
            ...preview
          }
        }
      })
    },
    [confirmBrokerOrder]
  )

  return confirmOrder
}

export function useSelectBroker(): [(broker: string, isPrivate: boolean) => any, boolean] {
  let [loading, setLoading] = useState(false)
  const connectBroker = useConnectBroker()

  let selectBroker = useCallback(
    async (broker: string, isPrivate: boolean) => {
      setLoading(true)

      // open tab in loading state while we wait for broker auth logic to kick in
      window.open(location.protocol + '//' + location.host + Routes.LoadingPopup, 'Broker Login')

      await connectBroker(broker, isPrivate)
    },
    [connectBroker]
  )
  return [selectBroker, loading]
}
