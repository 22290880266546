import React, { useCallback, useState } from 'react'
import { More } from '../../theme/NewIcons'
import { IconButton } from '../../composing/CSButton'
import { CardIcon, Menu, MenuItem } from '../../components/styles'
import useCloseMenu from 'src/utils/useCloseMenu'
import { Routes } from '../nav/constants'
import FollowProfile from '../profile/FollowProfile'
import { useGetProfile } from '@commonstock/common/src/api/profile'
import { ArchivedContentType } from '@commonstock/common/src/types'
import { MuteToggleItem } from '../profile/ProfileButtons'
import { useAuth } from '../auth/AuthContext'
import ReportContentMenuItem from '../../components/ReportContent'
import Unarchive from '../../components/Unarchive'
import { ContentTypeLabels, FeedReason, PostFeedItem } from '@commonstock/common/src/api/feed'
import { useEphemeralModal, useModal } from '../modal/Modal'
import dynamic from 'next/dynamic'
import { useBookmarkContent } from 'src/scopes/content/contentHooks'
import { useRefetchPostDependents } from 'src/scopes/post/postHooks'
import router from 'next/router'
import { destructPostFeedItem } from '../post/utils'
import { ReportContentType } from '@commonstock/common/src/api/report'

const DeletePost = dynamic(() => import('../post/DeletePost'))
const PinContent = dynamic(() => import('src/scopes/content/PinnedContent'))

const PostShareModal = dynamic(async () => {
  const module = await import('../share/ShareModal')
  return module.PostShareModal
})

type Props = {
  feedItem: PostFeedItem
}
function ContentCardActions({ feedItem }: Props) {
  const { userUuid, isModerator } = useAuth()
  const isMyContent = userUuid === feedItem.user_uuid

  const modal = useEphemeralModal()
  const { activeModal } = useModal()

  const [isOpen, setIsOpen] = useState(false)
  const refetchPostDependents = useRefetchPostDependents()
  const bookmarkContent = useBookmarkContent()

  let { post, user } = destructPostFeedItem(feedItem)

  const [userProfile] = useGetProfile({ meta: { user_uuid: feedItem.user_uuid } }, { paused: !isOpen })
  user = userProfile ? { ...userProfile, uuid: userProfile.user_uuid } : user

  const onClickMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(prev => !prev)
  }, [])

  useCloseMenu(`#actions-${feedItem.uuid}`, () => setIsOpen(false))

  // @TODO: Turn this into a LINK
  const onClickEdit = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      router.push(Routes.editPost(feedItem.uuid))
    },
    [feedItem]
  )

  const postDetailMatch = window.location.href.match(Routes.postDetail(feedItem.uuid))
  const editPostMatch = window.location.href.match(Routes.editPost(feedItem.uuid))
  const postRoute = !!postDetailMatch || !!editPostMatch

  const handleDeleteContent = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      modal(<DeletePost uuid={feedItem.uuid} postRoute={postRoute} shallowRoute={postRoute && !!activeModal} />)
    },
    [modal, feedItem, postRoute, activeModal]
  )

  const handlePinContent = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      modal(
        <PinContent
          uuid={feedItem.uuid}
          contentRoute={postRoute}
          shallowRoute={postRoute && !!activeModal}
          pinned={feedItem.reason_code === FeedReason.Pinned}
        />
      )
    },
    [activeModal, feedItem, modal, postRoute]
  )

  const handleShare = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      modal(<PostShareModal postFeedItem={feedItem} />)
    },
    [feedItem, modal]
  )

  const handleBookmark = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      bookmarkContent({ item_uuid: feedItem.uuid, isBookmarked: !!post?.bookmarked_at_ts })
      refetchPostDependents()
      setIsOpen(false)
    },
    [bookmarkContent, post, feedItem.uuid, refetchPostDependents]
  )

  if (!post || !user) return null
  return (
    <CardIcon id={`actions-${feedItem.uuid}`}>
      <IconButton onClick={onClickMenu} aria-label={`Show more ${ContentTypeLabels[feedItem.resource_type]} actions`}>
        <More active={isOpen} size={1.25} />
      </IconButton>
      <Menu hidden={!isOpen}>
        <MenuItem disabled={user.private} onClick={handleBookmark}>
          {user.private ? 'Bookmark disabled' : post.bookmarked_at_ts ? 'Remove Bookmark' : 'Bookmark'}
        </MenuItem>
        <MenuItem disabled={user.private} onClick={handleShare}>
          {user.private ? 'Share disabled' : `Share ${ContentTypeLabels[feedItem.resource_type]}`}
        </MenuItem>
        {isMyContent ? (
          <>
            <MenuItem onClick={onClickEdit}>Edit {ContentTypeLabels[feedItem.resource_type]}</MenuItem>
            <MenuItem isDestructive onClick={handleDeleteContent}>
              Delete {ContentTypeLabels[feedItem.resource_type]}
            </MenuItem>
            {isModerator && (
              <>
                <MenuItem isModerator onClick={handlePinContent}>
                  {feedItem.reason_code === FeedReason.Pinned ? 'Unpin' : 'Pin'}{' '}
                  {ContentTypeLabels[feedItem.resource_type]}
                </MenuItem>
              </>
            )}
          </>
        ) : (
          <>
            <FollowProfile profile={user} isDropdown />
            <MuteToggleItem profile={user} />
            <ReportContentMenuItem uuid={feedItem.uuid} title={post.title || ''} contentType={ReportContentType.Post} />
            {isModerator && (
              <>
                <MenuItem isModerator onClick={onClickEdit}>
                  Edit {ContentTypeLabels[feedItem.resource_type]}
                </MenuItem>
                {post.archived_at ? (
                  <Unarchive uuid={post.uuid} contentType={ArchivedContentType.POST} />
                ) : (
                  <MenuItem isModerator onClick={handleDeleteContent}>
                    Archive {ContentTypeLabels[feedItem.resource_type]}
                  </MenuItem>
                )}
                <MenuItem isModerator onClick={handlePinContent}>
                  {feedItem.reason_code === FeedReason.Pinned ? 'Unpin' : 'Pin'}{' '}
                  {ContentTypeLabels[feedItem.resource_type]}
                </MenuItem>
                <MenuItem isModerator onClick={handleDeleteContent}>
                  Delete {ContentTypeLabels[feedItem.resource_type]}
                </MenuItem>
              </>
            )}
          </>
        )}
      </Menu>
    </CardIcon>
  )
}

export default ContentCardActions
