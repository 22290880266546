import React from 'react';
import { FlexRow, Expander } from '../../composing/Flex';
import { SpacerH, SpacerV } from '../../composing/Spacing';
import { SectionCard } from '../../components/styles';
import { SkeletonBlock, SkeletonCircle } from '../../components/Skeleton';
import { TopPostsSkeletonItem } from '../dashboard/TopPosts.skeleton';
import { css, cx } from '@linaria/core';
import { useDev } from '../dev/DevContext';
import { devTransparent, devTransparentOutline } from '../../theme/AtomicClasses';
import { InvestorCarouselSkeleton, CarouselSectionSkeleton, AssetCarouselSkeleton } from '../../components/CardCarousel.Skeleton';
export function DiscoverTodaysMarketWinnersSkeleton() {
  return <CarouselSectionSkeleton className={topMoversClass}>
      <SpacerV />
      <AssetCarouselSkeleton />
    </CarouselSectionSkeleton>;
}
export function DiscoverMostTalkedAboutSkeleton() {
  return <CarouselSectionSkeleton className={mostTalkedAboutClass}>
      <SpacerV rem={1.25} />
      <AssetCarouselSkeleton />
    </CarouselSectionSkeleton>;
}
export function DiscoverMostBoughtSoldSkeleton() {
  return <CarouselSectionSkeleton className={mostBoughtSouldClass}>
      <SpacerV rem={1.25} />
      <AssetCarouselSkeleton />
    </CarouselSectionSkeleton>;
}
export function DiscoverTopPerformersSkeleton() {
  return <CarouselSectionSkeleton className={topPerformersClass}>
      <SpacerV rem={1.125} />
      <InvestorCarouselSkeleton badge />
    </CarouselSectionSkeleton>;
}
export function DiscoverBestContentSkeleton() {
  return <CarouselSectionSkeleton className={bestContentClass}>
      <SpacerV />
      <InvestorCarouselSkeleton badge />
    </CarouselSectionSkeleton>;
}
export function DiscoverInvestorsToFollowSkeleton() {
  return <CarouselSectionSkeleton className={investorsToFollowClass}>
      <SpacerV rem={1.0625} />
      <InvestorCarouselSkeleton button />
    </CarouselSectionSkeleton>;
}
export function DiscoverInvestorsGainingPopularitySkeleton() {
  const {
    skeletonShow
  } = useDev();
  return <SectionCard className={cx(investorsGainingPopularityClass, skeletonShow && devTransparent, skeletonShow && devTransparentOutline)}>
      <SkeletonBlock h={1.375} w={'40%'} />
      <SpacerV rem={0.375} />
      <>
        {[...Array(4)].map((_, i) => <div key={i}>
            <SpacerV rem={1.125} />
            <FlexRow alignCenter>
              <SkeletonBlock h={1} w={0.5} />
              <SpacerH />
              <SkeletonCircle h={2} />
              <SpacerH />
              <div>
                <SkeletonBlock h={0.875} w={6} />
                <SpacerV rem={0.125} />
                <SkeletonBlock h={0.875} w={6} />
              </div>
              <Expander />
              <SkeletonBlock h={1.25} w={'30%'} />
            </FlexRow>
          </div>)}
      </>
    </SectionCard>;
}
export function DiscoverTopPostsSkeleton() {
  const {
    skeletonShow
  } = useDev();
  return <SectionCard className={cx(topMemosClass, skeletonShow && devTransparent, skeletonShow && devTransparentOutline)}>
      <SkeletonBlock h={1.375} w={'40%'} />
      {[...Array(3)].map((_, i) => <div key={i}>
          <SpacerV />
          <TopPostsSkeletonItem />
        </div>)}
    </SectionCard>;
}
const investorsGainingPopularityClass = "iy32blk";
const topMemosClass = "t1nve1ky";
const topMoversClass = "tnx04gt";
const mostTalkedAboutClass = "mtdoksm";
const mostBoughtSouldClass = "ma937tb";
const topPerformersClass = "tlci67j";
const bestContentClass = "b1xmdvwt";
const investorsToFollowClass = "i171ebvd";

require("../../../.linaria-cache/packages/oxcart/src/scopes/discover/Discover.skeleton.linaria.module.css");