import { createUseFetch, createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { CacheType } from '@commonstock/client/src/constants'
import { AssetType } from '../types'

type AssetCompany = {
  symbol: string
  companyName: string
  industry: string
  description: string
  CEO: string
  sector: string
  employees: number | null
  tags: string[]
  state: string
  city: string
  country: string
}

type AssetQuote = {
  open: number
  high: number
  low: number
  latestPrice: number
  previousClose: number
  changePercent: number
  volume: number
  avgTotalVolume: number
}

type AssetStats = {
  week52high: number
  week52low: number
  avg10Volume: number
  avg30Volume: number
  ttmEPS: number
  peRatio: number
  beta: number
  dividendYield: number
  marketcap: number
}

export type AssetProfile = {
  asset_id: number
  asset_symbol: 'UBER'
  asset_type: AssetType
  company: AssetCompany
  quote: AssetQuote
  stats: AssetStats
}

type AssetProfileParams = {
  meta: {
    symbol: string
    type: string
  }
}

export const useGetAssetProfile = createUseFetch<AssetProfile, AssetProfileParams>({
  key: 'asset-profile',
  path: ({ meta: { symbol, type } }) => `/mds/asset/${symbol}:${type}/profile`,
  cache: CacheType.Disk
})

export const useGetAssetProfileAction = createUseFetchAction<AssetProfile, AssetProfileParams>({
  key: 'asset-profile-action',
  path: ({ meta: { symbol, type } }) => `/mds/asset/${symbol}:${type}/profile`,
  cache: CacheType.Disk
})
