import { createUseFetch, createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { Asset, AssetType } from '../types'

type MarketSessionPayload = {
  latest_market_time: string
  reference_time: string
  is_market_open: boolean
}
export const useMarketSession = createUseFetch<MarketSessionPayload>({
  key: 'market-session',
  path: '/mds/market/session/latest'
})

type AssetSearchParams = {
  query: {
    limit: number
    term: string
  }
}

export const useAssetSearchAction = createUseFetchAction<Asset[], AssetSearchParams>({
  key: 'market-asset-search-action',
  path: '/mds/asset/search'
})

export const useAssetSearch = createUseFetch<Asset[], AssetSearchParams>({
  key: 'market-asset-search',
  path: '/mds/asset/search'
})

export type TopMovers = {
  reference_price: number
  current_price: number
  ticker: string
  short_name: string
  change_value: number
  change_percentage: number
  asset_type: string
}

export const useTopMovers = createUseFetch<TopMovers[]>({
  key: 'top-movers',
  path: '/mds/top-movers'
})

export type Index = {
  symbol: string
  name: string
  short_name: string
  current_price: number
  price_chart: { t: number; v: number }[]
  type: AssetType
  change_percentage: number
  currency: string
  id: number
  change_value: number
  reference_price: number
}

type MarketIndices = {
  indices: Index[]
}

export const useMarketIndices = createUseFetch<MarketIndices>({
  key: 'market-indices',
  path: '/mds/market/indices'
})
