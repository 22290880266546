import React, { useCallback, useEffect, useRef, useMemo, ReactNode } from 'react'
import lodashDebounce from 'lodash.debounce'
import { ScrollerClass } from '../scopes/nav/StandardScreen'

type DebounceOptions = Parameters<typeof lodashDebounce>[2]

const createCallback = (debounce: number, options: DebounceOptions, handleOnScroll?: () => void): (() => void) => {
  if (!handleOnScroll) return () => {}
  if (debounce) {
    return lodashDebounce(handleOnScroll, debounce, options)
  } else {
    return handleOnScroll
  }
}

/**
 *  A react hook that invokes a callback when user scrolls to the bottom
 *
 * @param onBottom callback that will be invoked when scrolled to bottom
 * @param onScroll callback that will be invoked when scrolled
 * @param offset Offset from bottom of page in pixels. E.g. 300 will trigger onBottom 300px from the bottom of the page
 * @param debounce Optional debounce in milliseconds, defaults to 200ms
 * @param debounceOptions Options passed to lodash.debounce, see https://lodash.com/docs/4.17.15#debounce
 * @return React.MutableRefObject Optionally you can use this to pass to a element to use that as the scroll container
 */
function useScrollSentinel(
  onBottom: undefined | (() => void),
  onScroll: undefined | ((e: React.UIEvent<HTMLDivElement, UIEvent>) => void) = undefined,
  closestSelector = '.' + ScrollerClass,
  offset = 200,
  debounce = 200,
  debounceOptions: DebounceOptions = { leading: true }
) {
  const debouncedOnBottom = useMemo(() => createCallback(debounce, debounceOptions, onBottom), [
    debounce,
    onBottom,
    debounceOptions
  ])
  const containerRef = useRef<any>(null)
  const handleOnScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (containerRef.current) {
        const scrollNode = containerRef.current.closest(closestSelector)
        if (!scrollNode) {
          console.error('## Unable to locate scrollNode')
          return
        }
        const scrollContainerBottomPosition = Math.round(scrollNode.scrollTop + scrollNode.clientHeight)
        const scrollPosition = Math.round(scrollNode.scrollHeight - offset)

        if (scrollPosition <= scrollContainerBottomPosition) {
          debouncedOnBottom()
        }
        onScroll && onScroll(e)
      }

      // ref dependency needed for the tests, doesn't matter for normal execution
    },
    [offset, debouncedOnBottom, closestSelector, onScroll]
  )

  useEffect((): (() => void) => {
    const ref = containerRef.current?.closest(closestSelector)
    ref && ref.addEventListener('scroll', handleOnScroll)

    return () => {
      ref && ref.removeEventListener('scroll', handleOnScroll)
    }
  }, [handleOnScroll, debounce, closestSelector])

  return containerRef
}

export default useScrollSentinel

export function ScrollSentinel({ children, loadMore }: { children: ReactNode; loadMore: () => void }) {
  const sentinelRef = useScrollSentinel(loadMore)
  return <div ref={sentinelRef}>{children}</div>
}
