// @TODO deprecate usage of these in favor of Popover component
import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'

type BiasValues = {
  displayBias: number
  setDisplayBias: (displayBias: number) => void
  leavingTimeout: MutableRefObject<ReturnType<typeof setTimeout> | null | undefined>
  activeId: MutableRefObject<number | null>
}

const BiasContext = createContext<BiasValues | undefined>(undefined)
BiasContext.displayName = 'BiasContext'

export const BiasProvider = ({ children }: { children: ReactNode }) => {
  const [displayBias, setDisplayBias] = useBias()
  const leavingTimeout = useRef<ReturnType<typeof setTimeout> | null>()
  const activeId = useRef<number | null>(null)
  const popoverContextValues = useMemo(() => ({ activeId, displayBias, leavingTimeout, setDisplayBias }), [
    activeId,
    displayBias,
    setDisplayBias
  ])
  return <BiasContext.Provider value={popoverContextValues}>{children}</BiasContext.Provider>
}

export function useBias(): [
  number,
  (bias: number) => void,
  MutableRefObject<ReturnType<typeof setTimeout> | null | undefined>
] {
  const id = useMemo(Math.random, [])
  const [localBias, setLocalBias] = useState(-1)
  const localLeavingTimeout = useRef<ReturnType<typeof setTimeout> | null>()
  const popoverContext = useContext(BiasContext)

  const setBias = useCallback(
    (bias: number) => {
      if (popoverContext) {
        if (!popoverContext.activeId.current && bias > -1) {
          popoverContext.activeId.current = id
          popoverContext.setDisplayBias(bias)
        } else if (bias === -1) {
          popoverContext.activeId.current = null
          popoverContext.setDisplayBias(bias)
        }
      } else {
        setLocalBias(bias)
      }
    },
    [id, popoverContext]
  )

  const bias = popoverContext ? (popoverContext.activeId.current === id ? popoverContext.displayBias : -1) : localBias
  const leavingTimeout = popoverContext ? popoverContext.leavingTimeout : localLeavingTimeout

  return [bias, setBias, leavingTimeout]
}
