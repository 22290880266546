import { track } from '../mixpanel'
import { Comment, ParentTypes } from '@commonstock/common/src/api/comment'
import { FeedType, GlobalFeedSortType } from '@commonstock/common/src/api/feed'

export enum CommentEventType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  ARCHIVED = 'ARCHIVED'
}

type CommentEventObject = Comment & {
  type: CommentEventType
  root_user_uuid: string
  feed_type?: FeedType | GlobalFeedSortType
}

export function commentEvent(data: CommentEventObject) {
  track('Comment Event', {
    type: data.type,
    comment_id: data.uuid,
    root_uuid: data.root_uuid,
    root_type: data.root_type,
    root_user_uuid: data.root_user_uuid,
    mentioned_users: data.user_mentions.map(um => um.uuid),
    mentioned_user_count: data.user_mentions.length,
    mentioned_assets: data.asset_mentions.map(am => `${am.symbol}:${am.type}`),
    mentioned_asset_count: data.asset_mentions.length,
    is_reply: data.parent_type === ParentTypes.COMMENT,
    text_length: data.body.length,
    feed_type: data.feed_type
  })
}
