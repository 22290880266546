import React, { ReactNode, useEffect, useState } from 'react'

type ClientGateProps = {
  children: ReactNode
  fallback?: ReactNode
}
export const ClientGate = ({ children, fallback = null }: ClientGateProps) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return <>{isMounted ? children : fallback}</>
}
