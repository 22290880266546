import React, { useMemo } from 'react';
import { isAdminMessage, isUserMessage, isFileMessage, Message as MessageType } from './types';
import AdminMessage from './AdminMessage';
import UserMessage from './UserMessage';
import FileMessage from './FileMessage';
import { GroupChannel } from 'sendbird';
import { css } from '@linaria/core';
import { Colors } from '../../theme/constants';
const MessageClass = 'message';
type MessageProps = {
  message: MessageType;
  channel: GroupChannel;
  isFirstMessage: boolean;
  isLastMessage: boolean;
  sameUser: boolean;
};

const Message = ({
  message,
  channel,
  isFirstMessage,
  isLastMessage,
  sameUser
}: MessageProps) => {
  const isMedia = useMemo(() => isFileMessage(message) && (message.type.includes('image') || message.type.includes('video')), [message]);
  return <div className={MessageClass} id={`message-${message.messageId}`}>
      {isAdminMessage(message) && <AdminMessage message={message} isFirstMessage={isFirstMessage} isLastMessage={isLastMessage} />}
      {isUserMessage(message) && <UserMessage message={message} channel={channel} isFirstMessage={isFirstMessage} isLastMessage={isLastMessage} sameUser={sameUser} />}
      {isMedia && <FileMessage channel={channel} message={(message as SendBird.FileMessage
    /* coerce here as our isMedia boolean doesnt do it */
    )} isFirstMessage={isFirstMessage} isLastMessage={isLastMessage} sameUser={sameUser} />}
    </div>;
};

export default Message;
export const HighlightMessage = "h13hn6bd";

require("../../../.linaria-cache/packages/oxcart/src/scopes/chat/Message.linaria.module.css");