import { useCallback } from 'react'
import { useBias } from './animation'

export function useDropDownSetBias(leaveDelay?: number, changeBiasRatio = 0.6) {
  const [displayBias, setDisplayBias, leavingTimeout] = useBias()

  const onMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (displayBias !== -1 && !leavingTimeout.current) return setDisplayBias(-1)
      if (leavingTimeout.current) clearTimeout(leavingTimeout.current)
      const top = (e.currentTarget as HTMLDivElement).getBoundingClientRect().top
      let vBias = top < window.innerHeight * changeBiasRatio ? 1 : 2
      setDisplayBias(vBias)
    },
    [changeBiasRatio, displayBias, leavingTimeout, setDisplayBias]
  )

  const onMouseLeave = useCallback(() => {
    if (leavingTimeout.current) clearTimeout(leavingTimeout.current)
    leavingTimeout.current = setTimeout(() => {
      setDisplayBias(-1)
      leavingTimeout.current = null
    }, leaveDelay || 0)
  }, [leaveDelay, leavingTimeout, setDisplayBias])

  return { displayBias, onMouseEnter, onMouseLeave }
}
