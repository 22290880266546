import { useMemo } from 'react'
import { useLikeAction, useUnlikeAction, LikeTypes, Likes } from '@commonstock/common/src/api/like'
import { itemLike } from '../analytics/events.v1'
import { useLikeMap } from './LikeContext'
import { FeedItem, isPostFeedItem } from '@commonstock/common/src/api/feed'
import { useUser } from 'src/scopes/auth/AuthContext'
import { useFeedType } from 'src/scopes/feed/utils'

export const LikeTypeLabels = {
  [LikeTypes.POST]: 'post',
  [LikeTypes.COMMENT]: 'comment',
  [LikeTypes.TRADE]: 'trade'
}

const useLike = () => {
  const likeAction = useLikeAction()
  const { updateLikes, removeLikes } = useLikeMap()
  const { feedType } = useFeedType()

  const like = async (item_uuid: string, item_type: LikeTypes, item_user_uuid: string) => {
    try {
      const res = await likeAction({ json: { item_uuid, item_type } })
      if (res.success) {
        updateLikes({ [item_uuid]: res.success.payload }, item_type)
        itemLike({ like_count: res.success.payload.count, item_type, item_user_uuid, item_uuid, feed_type: feedType })
      }
    } catch (err) {
      // need to remove like from context if request fails
      // LikeIcon will use old value if no like in context
      removeLikes(item_uuid, item_type)
      console.log('## like err:', err)
    }
  }

  return like
}

const useUnlike = () => {
  const unlikeAction = useUnlikeAction()
  const { updateLikes, removeLikes } = useLikeMap()

  const unlike = async (item_uuid: string, item_type: LikeTypes) => {
    try {
      const res = await unlikeAction({ json: { item_uuid, item_type } })
      if (res.success) {
        updateLikes({ [item_uuid]: res.success.payload }, item_type)
      }
    } catch (err) {
      removeLikes(item_uuid, item_type)
      console.log('## unlike err:', err)
    }
  }

  return unlike
}

export const useToggleLike = () => {
  const [myProfile] = useUser()
  const { updateLikes } = useLikeMap()

  const like = useLike()
  const unlike = useUnlike()

  const toggleLike = async (
    item_uuid: string,
    item_type: LikeTypes,
    likes: Likes,
    authorUuid: string,
    dismissToolTip?: () => void
  ) => {
    const { liked_by_me } = likes
    dismissToolTip && dismissToolTip()

    let updatedLikes = { ...likes }
    // @NOTE: zero like value when only current user has liked it, this prevents not synced decimal errors
    if (likes.liked_by_me && likes.count === 1) {
      updatedLikes = { count: 0, liked_by_me: false, value: null }
    } else {
      const { aggregate_portfolio_value } = myProfile || {}
      updatedLikes.liked_by_me = !updatedLikes.liked_by_me
      updatedLikes.value = updatedLikes.value
        ? updatedLikes.value + (aggregate_portfolio_value || 0) * (liked_by_me ? -1 : 1)
        : null
      updatedLikes.count += liked_by_me ? -1 : 1
    }

    // add optimistic update to like context
    updateLikes({ [item_uuid]: updatedLikes }, item_type)

    liked_by_me ? await unlike(item_uuid, item_type) : await like(item_uuid, item_type, authorUuid)
  }

  return toggleLike
}

export function useGetLikes(uuid: string, type: LikeTypes, feedItem?: FeedItem) {
  const { parentLikeMaps } = useLikeMap()

  let likes = useMemo(() => {
    const likesFromContext = parentLikeMaps[type][uuid]
    if (likesFromContext) {
      return likesFromContext
    } else if (feedItem) {
      let likes = feedItem?.resources.trades[feedItem.uuid]?.likes
      if (isPostFeedItem(feedItem)) {
        likes = feedItem?.resources.memos[feedItem.uuid]?.likes
      }
      return likes
    }
  }, [feedItem, parentLikeMaps, type, uuid])

  return likes
}
