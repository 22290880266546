import React from 'react'
import AuthorBlock from 'src/scopes/profile/AuthorBlock'
import RelatedBy, { RelatedContentTypes } from 'src/scopes/discover/RelatedBy'
import PaneHeader from 'src/scopes/nav/PaneHeader'
import { PostFeedItem } from '@commonstock/common/src/api/feed'

type Props = {
  feedItem: PostFeedItem
}

function RelatedContent({ feedItem }: Props) {
  return (
    <>
      <AuthorBlock feedItem={feedItem} />
      <PaneHeader title="Related" />
      <RelatedBy relatedBy={RelatedContentTypes.ByAuthor} feedItem={feedItem} />
      <RelatedBy relatedBy={RelatedContentTypes.ByAsset} feedItem={feedItem} />
      <RelatedBy relatedBy={RelatedContentTypes.ByTrending} feedItem={feedItem} />
    </>
  )
}

export default RelatedContent
