import { createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { PagerOptions, createUsePagedFetch } from '@commonstock/client/src/react/createUsePagedFetch'
import { HttpMethods } from '@commonstock/client/src/constants'
import { UserProfile } from './profile'

export enum LikeTypes {
  POST = 'MEMO_PLUS',
  COMMENT = 'COMMENT',
  TRADE = 'TRADE'
}

export type Likes = {
  count: number
  liked_by_me: boolean
  value: number | null
}

type LikeParams = {
  json: {
    item_uuid: string
    item_type: LikeTypes
  }
}

export const useUnlikeAction = createUseFetchAction<Likes, LikeParams>({
  key: 'unlike',
  method: HttpMethods.Post,
  path: `/unlike`
})

export const useLikeAction = createUseFetchAction<Likes, LikeParams>({
  key: 'like',
  method: HttpMethods.Post,
  path: `/like`
})

const likeListPagerOptions: PagerOptions<UserProfile[], LikeListParams> = {
  getCursor: (page, resource) => {
    const lastItem = resource?.success?.payload.slice(-1)[0]
    const after_uuid = lastItem && lastItem.user_uuid
    return { query: { after_uuid, limit: 10 } }
  },
  isTerminal: resource => !!((resource?.success?.payload.length || 0) < 10)
}

type LikeListParams = {
  meta: {
    uuid: string
  }
  query: {
    after_uuid?: string
    limit?: number
  }
}

export const useGetLikeList = createUsePagedFetch<UserProfile[], LikeListParams>(
  {
    key: 'like-list',
    path: ({ meta: { uuid } }) => `/like/${uuid}/users`
  },
  likeListPagerOptions
)
