import React, { useCallback, useState } from 'react';
import { UserProfile, useAcceptFollowRequest, useRejectFollowRequest, useGetPendingRequests } from '@commonstock/common/src/api/profile';
import { X } from '../../theme/NewIcons';
import { useUser } from '../auth/AuthContext';
import { SecondaryButton } from '../../composing/CSButton';
import AnimatedEllipsis from '../../components/AnimatedEllipsis';
import { SpacerH } from '../../composing/Spacing';
import { css } from '@linaria/core';
import { FlexRow } from '../../composing/Flex';
import { profileRoundHeaderButton } from '../../components/styles';
enum Actions {
  Accept = 'accept',
  Reject = 'reject',
}

function PendingRequestButton({
  profile
}: {
  profile: UserProfile;
}) {
  const [user] = useUser();
  const [,,, refetchPendingRequests] = useGetPendingRequests();
  const [loading, setLoading] = useState('');
  const accept = useAcceptFollowRequest();
  const reject = useRejectFollowRequest();
  const onAccept = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(Actions.Accept);
    await accept({
      meta: {
        user_uuid: user?.user_uuid || 'n/a',
        follower_uuid: profile.user_uuid
      }
    });
    refetchPendingRequests();
    setLoading('');
  }, [accept, profile.user_uuid, refetchPendingRequests, user]);
  const onReject = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(Actions.Reject);
    await reject({
      meta: {
        user_uuid: user?.user_uuid || 'n/a',
        follower_uuid: profile.user_uuid
      }
    });
    refetchPendingRequests();
    setLoading('');
  }, [profile.user_uuid, refetchPendingRequests, reject, user]);
  return <FlexRow alignCenter shrink className={actions}>
      <SecondaryButton onClick={onAccept}>
        {loading === Actions.Accept ? <AnimatedEllipsis /> : 'Accept'}
      </SecondaryButton>
      <SpacerH rem={0.5} />
      <SecondaryButton onClick={onReject} className={profileRoundHeaderButton} aria-label={'Reject'}>
        {loading === Actions.Reject ? <AnimatedEllipsis /> : <X size={1} />}
      </SecondaryButton>
    </FlexRow>;
}

export default PendingRequestButton;
const actions = "a1x685rv";

require("../../../.linaria-cache/packages/oxcart/src/scopes/nav/PendingRequestButton.linaria.module.css");