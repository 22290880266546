import React, { useRef, useEffect, useCallback, useMemo, useState } from 'react'
import { event, select, selectAll } from 'd3-selection'
import { useLineChart } from './LineChartContext'
import { Colors } from '../../theme/constants'
import { TradeFeedItem, TradeResource } from '@commonstock/common/src/api/feed'
import { OrderType } from '@commonstock/common/src/api/user'
import { cx } from '@linaria/core'
import { easeCircleIn } from 'd3-ease'
import { groupBy } from 'lodash'
import { useTheme } from 'src/theme/ThemeContext'
import AnnotatedTradePopover from 'src/scopes/charts/AnnotatedTradePopover'
import { replaceMediaUrls } from 'src/utils/mediaUrls'
import { isServer } from 'src/utils/isServer'

type Coordinate = {
  x: number
  y: number
  b: number | null
}

type CoordinatePoint = {
  coordinate?: Coordinate
}

type TradesWithCoordinate = (TradeFeedItem & CoordinatePoint)[]

function InnerAnnotatedLineChartOverlay() {
  const {
    width,
    height,
    margin,
    data,
    trades,
    chartData,
    coordinates,
    getNearestCoordinate,
    tradesPending,
    disabledAnimations
  } = useLineChart()
  const rootSize =
    (!isServer && 'getComputedStyle' in window && parseInt(getComputedStyle(document.documentElement).fontSize)) || 16

  const half_height = (height - margin.top - margin.bottom) / 2
  const lineChartRef = useRef<SVGSVGElement>(null)
  const [displayedTrades, setDisplayedTrades] = useState<{ [key: string]: TradesWithCoordinate }>({})

  const { isMobile } = useTheme()
  const getTooltipXPosition = useCallback(
    (x: number) => {
      if (!isMobile) return x
      // @NOTE 139 is half the width of trade tooltip
      if (x < 139) return 139
      if (width - x < 139) return width - 139
      return x
    },
    [isMobile, width]
  )

  const groupedTrades = useMemo(() => {
    const tradesWithCoordinate = trades?.map(trade => {
      const tradeItem = trade.resources.trades[trade.uuid]
      const index = getNearestCoordinate(tradeItem.created_at_ts, 't')
      const coordinate = coordinates[index]
      return { ...trade, coordinate }
    })
    return groupBy(tradesWithCoordinate, trade => trade.coordinate?.x)
  }, [coordinates, getNearestCoordinate, trades])

  const positionFunction = useCallback(
    (d: TradeFeedItem, scaleValue = 1, xOffset = 0, yOffset = 0) => {
      const tradeItem = d.resources.trades[d.uuid]
      const nearestIndex = getNearestCoordinate(tradeItem.created_at_ts, 't')
      let posx
      let posy
      if (nearestIndex !== -1 && coordinates[nearestIndex]) {
        posx = coordinates[nearestIndex].x
        posy = coordinates[nearestIndex].y
      } else {
        posy = half_height
        posx = 0
      }

      return { x: posx + xOffset, y: posy + yOffset, scale: scaleValue }
    },
    [coordinates, getNearestCoordinate, half_height]
  )

  const highlightAvatar = useCallback(
    (trade: TradeFeedItem) => {
      select(lineChartRef.current)
        .selectAll('defs')
        .select(`#avatar-clip-${trade.uuid}`)
        .select('circle')
        .raise()
        .transition()
        .attr('r', rootSize)

      select(lineChartRef.current)
        .selectAll('g')
        .select(`.trade-user-${trade.uuid}`)
        .raise()
        .transition()
        .attr('width', rootSize * 2)
        .attr('height', rootSize * 2)
        .attr('x', positionFunction(trade, 1, -rootSize, -rootSize * 2.25).x)
        .attr('y', positionFunction(trade, 1, -rootSize, -rootSize * 2.25).y)
    },
    [positionFunction, rootSize]
  )

  const dehighlightAvatar = useCallback(
    (trade: TradeFeedItem) => {
      select(lineChartRef.current)
        .selectAll('defs')
        .select(`#avatar-clip-${trade.uuid}`)
        .select('circle')
        .raise()
        .transition()
        .attr('r', rootSize * 0.75)

      select(lineChartRef.current)
        .selectAll('g')
        .select(`.trade-user-${trade.uuid}`)
        .raise()
        .transition()
        .attr('width', rootSize * 1.5)
        .attr('height', rootSize * 1.5)
        .attr('x', positionFunction(trade, 1, -rootSize * 0.75, -rootSize * 2).x)
        .attr('y', positionFunction(trade, 1, -rootSize * 0.75, -rootSize * 2).y)
    },
    [positionFunction, rootSize]
  )

  const removePopoverLine = useCallback(() => {
    selectAll('.annotated-trade').attr('r', rootSize * 0.25)
    selectAll('.line').remove()
    selectAll('.annotated-trade-user').style('display', 'block')
  }, [rootSize])

  const createPopoverLine = useCallback(
    (tradeItem: TradeResource, coordinate: Coordinate) => {
      selectAll('.annotated-trade-user')
        .filter((d: any) => groupedTrades[coordinate.x]?.some(t => t.uuid === d.uuid))
        .style('display', 'none')

      selectAll('.annotated-trade')
        .filter((d: any) => groupedTrades[coordinate.x]?.some(t => t.uuid === d.uuid))
        .transition()
        .duration(disabledAnimations ? 0 : 500)
        .attr('r', rootSize * 0.375)

      selectAll('.annotatedLineChartSvg')
        .append('line')
        .attr('class', 'line')
        .attr('x1', coordinate.x)
        .attr('y1', coordinate.y)
        .attr('x2', coordinate.x)
        .attr('y2', coordinate.y)
        .style('stroke', tradeItem.transaction_type === OrderType.Buy ? Colors.ChartsPositive : Colors.ChartsNegative)
        .style('stroke-width', 2)
        .transition()
        .duration(disabledAnimations ? 0 : 500)
        .attr('x2', getTooltipXPosition(coordinate.x))
        .attr('y2', coordinate.y - 55)
    },
    [disabledAnimations, rootSize, getTooltipXPosition, groupedTrades]
  )

  const showTrades = useCallback(
    (trade: TradeFeedItem) => {
      event.stopPropagation()
      dehighlightAvatar(trade)
      const tradeItem = trade.resources.trades[trade.uuid]
      const index = getNearestCoordinate(tradeItem.created_at_ts, 't')
      const coordinate = coordinates[index]
      if (coordinate) {
        removePopoverLine()
        createPopoverLine(tradeItem, coordinate)
        setDisplayedTrades({ [`${coordinate.x}`]: groupedTrades[coordinate.x] })
      }
    },
    [coordinates, dehighlightAvatar, getNearestCoordinate, groupedTrades, createPopoverLine, removePopoverLine]
  )

  const updatePath = useCallback(() => {
    if (!trades) return
    const DURATION = disabledAnimations ? 0 : 250
    // This generates the trade dots on the chart.
    select(lineChartRef.current)
      .selectAll('g')
      .selectAll('.annotated-trade')
      .data(trades, function(d) {
        return '' + (d as TradeFeedItem).uuid
      })
      .join(
        enter =>
          enter
            .append('circle')
            .attr('class', d => `annotated-trade trade-${d.uuid}`)
            .attr('cx', d => positionFunction(d, 0).x)
            .attr('cy', d => positionFunction(d, 0).y)
            .attr('fill', d => {
              const tradeItem = d.resources.trades[d.uuid]
              const isBuy = tradeItem.transaction_type === OrderType.Buy || false
              return isBuy ? Colors.ChartsPositive : Colors.ChartsNegative
            })
            .attr('r', rootSize * 0.25)
            .call(enter =>
              enter
                .transition()
                .ease(easeCircleIn)
                .duration(DURATION)
                .attr('r', rootSize * 0.25)
                .attr('cx', d => positionFunction(d, 0).x)
                .attr('cy', d => positionFunction(d, 0).y)
            ),
        update =>
          update.call(update =>
            update
              .attr('fill', d => {
                const tradeItem = d.resources.trades[d.uuid]
                const isBuy = tradeItem.transaction_type === OrderType.Buy || false
                return isBuy ? Colors.ChartsPositive : Colors.ChartsNegative
              })
              .transition()
              .ease(easeCircleIn)
              .duration(DURATION)
              .attr('cx', d => positionFunction(d, 0).x)
              .attr('cy', d => positionFunction(d, 0).y)
          ),
        exit => exit.call(exit => exit.remove())
      )

    // Circle masks
    select(lineChartRef.current)
      .selectAll('defs')
      .selectAll('.annotated-trade-user-clip')
      .data(trades, function(d) {
        return '' + (d as TradeFeedItem).uuid
      })
      .join(
        enter =>
          enter
            .append('clipPath')
            .attr('id', d => `avatar-clip-${d.uuid}`)
            .append('circle')
            .attr('class', d => `annotated-trade-user-clip avatar-clip-${d.uuid}`)
            .attr('r', rootSize * 0.75)
            .attr('cx', d => positionFunction(d, 1, 0, -rootSize * 1.25).x)
            .attr('cy', d => positionFunction(d, 1, 0, -rootSize * 1.25).y)
            .call(enter =>
              enter
                .transition()
                .ease(easeCircleIn)
                .duration(DURATION)
                .attr('r', 12)
                .attr('cx', d => positionFunction(d, 1, 0, -rootSize * 1.25).x)
                .attr('cy', d => positionFunction(d, 1, 0, -rootSize * 1.25).y)
            ),
        update =>
          update.call(update =>
            update
              .transition()
              .ease(easeCircleIn)
              .duration(DURATION)
              .attr('r', rootSize * 0.75)
              .attr('cx', d => positionFunction(d, 1, 0, -rootSize * 1.25).x)
              .attr('cy', d => positionFunction(d, 1, 0, -rootSize * 1.25).y)
          ),
        exit =>
          exit
            .attr('r', d => {
              select(lineChartRef.current)
                .selectAll('defs')
                .selectAll(`#avatar-clip-${d.uuid}`)
                .remove()
              return 0
            })
            .remove()
      )

    // This generates the user pictures on the chart.
    select(lineChartRef.current)
      .selectAll('g')
      .selectAll('.annotated-trade-user')
      .data(trades, function(d) {
        return '' + (d as TradeFeedItem).uuid
      })
      .join(
        enter =>
          enter
            .append('svg:image')
            .attr('class', d => `annotated-trade-user trade-user-${d.uuid}`)
            .attr('xlink:href', d => replaceMediaUrls(d.resources.users[d.user_uuid].picture, 96, 96))
            .attr('clip-path', d => `url(#avatar-clip-${d.uuid})`)
            .attr('width', rootSize * 1.5)
            .attr('height', rootSize * 1.5)
            .attr('x', d => positionFunction(d, 1, -rootSize * 0.75, -rootSize * 2).x)
            .attr('y', d => positionFunction(d, 1, -rootSize * 0.75, -rootSize * 2).y)
            .call(enter =>
              enter
                .transition()
                .ease(easeCircleIn)
                .duration(DURATION)
                .attr('width', rootSize * 1.5)
                .attr('height', rootSize * 1.5)
                .attr('x', d => positionFunction(d, 1, -rootSize * 0.75, -rootSize * 2).x)
                .attr('y', d => positionFunction(d, 1, -rootSize * 0.75, -rootSize * 2).y)
            ),
        update =>
          update.call(update =>
            update
              .attr('class', d => `annotated-trade-user trade-user-${d.uuid}`)
              .attr('clip-path', d => `url(#avatar-clip-${d.uuid})`)
              .transition()
              .ease(easeCircleIn)
              .duration(DURATION)
              .attr('width', rootSize * 1.5)
              .attr('height', rootSize * 1.5)
              .attr('x', d => positionFunction(d, 1, -rootSize * 0.75, -rootSize * 2).x)
              .attr('y', d => positionFunction(d, 1, -rootSize * 0.75, -rootSize * 2).y)
          ),
        exit => {
          return exit.call(exit => exit.remove())
        }
      )

    select(lineChartRef.current)
      .selectAll('g')
      .selectAll('.annotated-trade')
      .data(trades, function(d) {
        return '' + (d as TradeFeedItem).uuid
      })
      .style('cursor', 'pointer')
      .style('pointer-events', 'auto')
      .on('click', showTrades)
      .on('mouseover', highlightAvatar)
      .on('mouseout', dehighlightAvatar)

    select(lineChartRef.current)
      .selectAll('g')
      .selectAll('.annotated-trade-user')
      .data(trades, function(d) {
        return '' + (d as TradeFeedItem).uuid
      })
      .style('cursor', 'pointer')
      .style('pointer-events', 'auto')
      .on('click', showTrades)
      .on('mouseover', highlightAvatar)
      .on('mouseout', dehighlightAvatar)
  }, [trades, disabledAnimations, showTrades, highlightAvatar, dehighlightAvatar, rootSize, positionFunction])

  useEffect(() => {
    if (!tradesPending && trades) {
      updatePath()
      setDisplayedTrades({})
      removePopoverLine()
    }
  }, [chartData, chartData.period_title, data, trades, tradesPending, updatePath, removePopoverLine])
  if (!trades) return null
  return (
    <>
      <svg
        className={cx('annotatedLineChartSvg')}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        ref={lineChartRef}
      >
        <g></g>
        <defs></defs>
      </svg>
      {Object.entries(displayedTrades).map(([key, trades]) => (
        <AnnotatedTradePopover
          key={key}
          trades={trades}
          xPosition={getTooltipXPosition(trades[0].coordinate?.x || 0)}
          yPosition={trades[0].coordinate?.y || 0}
          onClickClose={() => {
            removePopoverLine()
            setDisplayedTrades(prev => {
              delete prev[key]
              return { ...prev }
            })
          }}
        />
      ))}
    </>
  )
}

export default React.memo(InnerAnnotatedLineChartOverlay)
