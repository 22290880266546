import React from 'react'
import { SectionCard } from '../components/styles'
import { UnstyledButton } from '../composing/CSButton'
import { TextCaption, TextDisplaySmall } from '../composing/CSText'
import { FlexRow } from '../composing/Flex'
import { Colors } from '../theme/constants'
import useLoggedOutModal from 'src/scopes/public/useLoggedOutModal'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { JoinModalPublic } from 'src/scopes/public/JoinModalPublic'
import CSLink from 'src/composing/CSLink'
import { UrlObject } from 'url'

type Props = {
  children: React.ReactNode
  onClick?: () => void
  link?: string | UrlObject
  title: string
  actionText?: string
  interceptLoggedOutLocation?: string
}

function PreviewCard({ children, onClick, link, title, actionText = 'See all', interceptLoggedOutLocation }: Props) {
  const loggedOutModal = useLoggedOutModal(interceptLoggedOutLocation || '')

  const _onClick = (e: any) => {
    if (interceptLoggedOutLocation && !isLoggedIn()) {
      e.preventDefault()
      loggedOutModal('Join', <JoinModalPublic />)
    } else {
      onClick && onClick()
    }
  }

  return (
    <SectionCard>
      <FlexRow justifyBetween>
        <TextDisplaySmall>{title}</TextDisplaySmall>
        {interceptLoggedOutLocation || onClick ? (
          <UnstyledButton onClick={_onClick} aria-label={actionText}>
            <TextCaption color={Colors.TextLinks}>{actionText}</TextCaption>
          </UnstyledButton>
        ) : link ? (
          <CSLink href={link} aria-label={actionText} unstyled>
            <TextCaption color={Colors.TextLinks}>{actionText}</TextCaption>
          </CSLink>
        ) : null}
      </FlexRow>
      {children}
    </SectionCard>
  )
}

export default PreviewCard
