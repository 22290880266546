import { css } from '@linaria/core';
import React, { MouseEvent } from 'react';
import { UnstyledButton } from 'src/composing/CSButton';
import { TextBodyRegular } from 'src/composing/CSText';
import { Colors } from 'src/theme/constants';
type Props = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => any;
  prompt: string;
};
export default function PseudoInputButton({
  onClick,
  prompt
}: Props) {
  return <UnstyledButton onClick={onClick} className={fakeInputClass}>
      <TextBodyRegular color={Colors.TextSecondary} ellipsis>
        {prompt}
      </TextBodyRegular>
    </UnstyledButton>;
}
const fakeInputClass = "f1s1yyi8";

require("../../.linaria-cache/packages/oxcart/src/components/PseudoInputButton.linaria.module.css");