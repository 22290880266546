import { createUseFetch, createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods, CacheType } from '@commonstock/client/src/constants'

export const REDIRECT_URL = typeof location !== 'undefined' && location.protocol + '//' + location.host + '/broker-auth'

export type Broker = {
  identifier: string
  name: string
  asset_types: Array<string>
  is_enabled: boolean
  is_plaid: boolean
}

type AuthenticateBroker = {
  token: string
}

type AuthenticateBrokerParams = {
  json: {
    broker: string
    auth_data: {
      token: string
      redirect_uri: string
    }
    is_hidden_default?: boolean
  }
}

type BrokerRedirect = {
  auth_url: string
}

type BrokerRedirectParams = {
  meta: {
    broker: string
  }
}

export type Account = {
  id: number
  currency: string
  number: string
  name: string
  available_cash: number
  buying_power: number
  total_value: number
}

export type PreviewBroker = {
  identifier: string
  account_identifier: string
  order_type: string
  asset_symbol: string
  asset_type: string
  quantity: number
  price: number
  fee: number
}

export type PreviewBrokerParams = {
  json: {
    account_identifier: string
    order_type: string
    asset_symbol: string
    asset_type: string
    quantity: number
    broker: string
  }
}

export type ConfirmParams = {
  json: {
    broker: string
    order_data: PreviewBroker
  }
}

export type Brokerage = {
  id: number
  canonical: string
  name: string
  website: string
  status: string
  is_synced: boolean
}

export type ConnectedAccount = {
  id: number
  available_funds: number
  brokerage: Brokerage
  buying_power: number
  is_fractional: boolean
  last_updated_at: string
  session_id: number
  source_id: string
  uuid: string
  asset_type: string
  is_hidden: boolean
  is_removed: boolean
  last_updated_at_timestamp: number
  total_value: number
}

const useGetBrokerAccounts = createUseFetch<ConnectedAccount[]>({
  key: 'broker-accounts',
  path: '/pds/accounts',
  refetchInterval: 10000,

  cache: CacheType.Disk
})

export type ConnectionAccount = {
  id: number
  available_funds: number
  buying_power: number
  is_fractional: boolean
  last_updated_at: string
  session_id: number
  source_id: string
  uuid: string
  asset_type: string
  is_hidden: boolean
  is_removed: boolean
  updated_at: string
  updated_at_timestamp: number
  total_value: number
}
export enum ConnectionStatus {
  Good = 'good',
  Syncing = 'syncing',
  Login = 'login'
}
export type Connection = {
  name: string
  canonical: string
  identifier: string
  website: string
  status: ConnectionStatus
  is_synced: boolean
  accounts: Array<ConnectionAccount>
}
const useGetBrokerConnections = createUseFetch<Connection[]>({
  key: 'broker-connections',
  path: '/pds/connections',
  refetchInterval: 10000,
  cache: CacheType.Disk
})

const useGetBrokerList = createUseFetch<Broker[]>({
  key: 'broker-list',
  path: '/brokers/broker-list/v2',
  cache: CacheType.Disk
})

const useGetBrokerRedirectAction = createUseFetchAction<BrokerRedirect, BrokerRedirectParams>({
  key: 'broker-redirect',
  path: params => `/brokers/auth-url?broker=${params.meta.broker}&callback_url=${REDIRECT_URL}`
})

const usePostAuthenticateBrokerAction = createUseFetchAction<AuthenticateBroker, AuthenticateBrokerParams>({
  key: 'broker-authenticate',
  method: HttpMethods.Post,
  path: '/brokers/authenticate'
})

const usePreviewOrder = createUseFetch<PreviewBroker, PreviewBrokerParams>({
  key: 'preview-order',
  method: HttpMethods.Post,
  path: '/brokers/preview-order',
  independent: false
})

export type ConfirmPayload = {
  order_id: number
}
const usePostConfirmBrokerOrderAction = createUseFetchAction<ConfirmPayload, ConfirmParams>({
  key: 'broker-confirm',
  method: HttpMethods.Post,
  path: '/brokers/confirm-order'
})

type AccountRemove = {
  uuid: string
  remove: boolean
}
type AccountRemovePayload = {
  json: AccountRemove
}

const useRemoveAccount = createUseFetchAction<AccountRemovePayload, AccountRemovePayload>({
  key: 'account-remove',
  method: HttpMethods.Post,
  path: '/pds/account/remove'
})

type ConnectionDisconnectPayload = {
  success: boolean
}

type ConnectionDisconnectParams = {
  json: {
    identifier: string
  }
}

const useDisconnectConnectionAction = createUseFetchAction<ConnectionDisconnectPayload, ConnectionDisconnectParams>({
  key: 'disconnect-connection',
  method: HttpMethods.Post,
  path: '/pds/disconnect'
})

type AccountHide = {
  uuid: string
  hide: boolean
}
type AccountHidePayload = {
  json: AccountHide
}
const useToggleAccount = createUseFetchAction<AccountHidePayload, AccountHidePayload>({
  key: 'account-hideuui',
  method: HttpMethods.Post,
  path: '/pds/account/hide'
})

type PlaidLinkPayload = {
  link_token: string
}
const usePlaidLinkToken = createUseFetch<PlaidLinkPayload>({
  key: 'plaid-link',
  method: HttpMethods.Post,
  path: '/pds/plaid/link-token'
})

type PlaidPublicTokenParams = {
  json: {
    public_token: string
  }
}
const usePostPlaidPublicToken = createUseFetchAction<void, PlaidPublicTokenParams>({
  key: 'plaid-public-token',
  method: HttpMethods.Post,
  path: '/pds/plaid/sync-new-session'
})

export {
  useGetBrokerAccounts,
  useGetBrokerConnections,
  useGetBrokerList,
  useGetBrokerRedirectAction,
  usePostAuthenticateBrokerAction,
  usePostConfirmBrokerOrderAction,
  usePreviewOrder,
  useRemoveAccount,
  useToggleAccount,
  usePlaidLinkToken,
  usePostPlaidPublicToken,
  useDisconnectConnectionAction
}
