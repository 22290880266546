import { track } from '../mixpanel'
import { UserProfile } from '@commonstock/common/src/api/profile'

export function userFollowed(data: UserProfile) {
  track('User Followed', {
    followed_follower_assets: data.follower_assets,
    followed_follower_count: data.followers,
    followed_following_count: data.following,
    followed_is_private: data.private,
    followed_is_verified: data.verified,
    followed_user_joined_at: data.joined_at,
    followed_username: data.username,
    followed_uuid: data.user_uuid,
    follows_me: data.follows_me
  })
}
