import { Colors } from '../../theme/constants';
import { X } from '../../theme/NewIcons';
import React from 'react';
import { UnstyledButton } from '../../composing/CSButton';
import { css } from '@linaria/core';
export function CloseDetailCard({
  onClose
}: {
  onClose?: () => void;
}) {
  return <UnstyledButton onClick={onClose} className={close} aria-label={'Close modal'}>
      <X color={Colors.BackgroundPrimary} />
    </UnstyledButton>;
}
const close = "chvruts";

require("../../../.linaria-cache/packages/oxcart/src/scopes/modal/CloseDetailCard.linaria.module.css");