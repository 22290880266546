import { useFlags as useFlagsLD } from 'launchdarkly-react-client-sdk'
import { OVERRIDE_FLAGS_KEY } from '../dev/FeatureFlagModal'

export const flagsReady = Symbol('flags ready')

const flagOverrides =
  typeof localStorage !== 'undefined' && JSON.parse(localStorage.getItem(OVERRIDE_FLAGS_KEY) || '{}')

// these singleton stabilize the return object, and allow the flagsReady and FlagGate to be more performant
let ldFlagsSingleton = {}
let flagsSingleton: { [key: string]: boolean; [flagsReady]: boolean } = { [flagsReady]: false }
export const useFlags = () => {
  const ldFlags = useFlagsLD()
  if (ldFlags !== ldFlagsSingleton) {
    // ld doesnt tell us if its ready, so we infer it as long as there is 1 or more flag known
    flagsSingleton = { ...ldFlags, ...flagOverrides, [flagsReady]: !!Object.keys(ldFlags).length }
    ldFlagsSingleton = ldFlags
  }

  return flagsSingleton
}
