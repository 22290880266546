import React, { useCallback, useEffect, useRef, useState } from 'react';
import LiveDot from './LiveDot';
import { LineChartProvider, LineChartType, useLineChart } from './LineChartContext';
import TooltipScrubber from './TooltipScrubber';
import ScrubbingDot from './ScrubbingDot';
import InnerLineChart from './InnerLineChart';
import ScrubbingLabel from './ScrubbingLabels';
import { css } from '@linaria/core';
import { useAnimationFrameEventListener } from '@commonstock/common/src/utils/useEventListener';
import InnerAnnotatedLineChartOverlay from 'src/scopes/charts/InnerAnnotatedLineChartOverlay';

function LineChart({
  showTooltip,
  ...rest
}: LineChartType) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(rest.width);
  const updateWidth = useCallback(() => {
    if (!wrapperRef.current) return;
    const currentWidth = wrapperRef.current.clientWidth;
    const parentElement = wrapperRef.current.parentElement;
    const width = parentElement && parentElement.clientWidth - parseFloat(getComputedStyle(parentElement, null).paddingLeft) - parseFloat(getComputedStyle(parentElement, null).paddingRight);
    const pWidth = width || rest.width;
    if (pWidth < rest.width) setWidth(pWidth);else if (currentWidth !== rest.width) setWidth(rest.width);
  }, [rest.width]);
  useEffect(() => {
    updateWidth();
  }, [updateWidth]);
  useAnimationFrameEventListener('resize', updateWidth);
  const {
    chartData
  } = rest;
  return <LineChartProvider {...rest} width={width}>
      <div className={`chart-wrapper ${chartWrapper}`} style={{
      width: `${width}px`,
      height: `${rest.height}px`
    }} ref={wrapperRef}>
        <div className={innerChartWrapper}>
          <InnerLineChart />
          {showTooltip && <Tolltip live={chartData.live} />}
        </div>
        <div className={innerChartOverlayWrapper}>
          <InnerAnnotatedLineChartOverlay />
        </div>
      </div>
    </LineChartProvider>;
}

function Tolltip({
  live
}: {
  live: boolean;
}) {
  const {
    isFlatLine
  } = useLineChart();
  return <>
      {live && !isFlatLine && <LiveDot />}
      <TooltipScrubber />
      <ScrubbingLabel />
      {!isFlatLine && <ScrubbingDot />}
    </>;
}

const chartWrapper = "csowli1";
const innerChartWrapper = "iopftm6";
const innerChartOverlayWrapper = "i1jm6uws";
export default React.memo(LineChart);

require("../../../.linaria-cache/packages/oxcart/src/scopes/charts/LineChart.linaria.module.css");