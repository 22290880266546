import React, { Fragment } from 'react';
import { css } from '@linaria/core';
import { FlexRow, Expander } from '../composing/Flex';
import { TextDisplaySmall } from '../composing/CSText';
import { RightSliderButton, LeftSliderButton } from './Slider';
import { SpacerH } from '../composing/Spacing';
type Props = {
  title: string;
  showSliderButtons?: boolean;
  button?: React.ReactNode;
};

function ListRowHeader({
  showSliderButtons,
  title,
  button
}: Props) {
  return <FlexRow alignCenter shrink>
      <TextDisplaySmall>{title}</TextDisplaySmall>
      <Expander />
      {showSliderButtons && <FlexRow shrink gap={0.375}>
          <LeftSliderButton className={sliderButtonClass} />
          <RightSliderButton className={sliderButtonClass} />
        </FlexRow>}
      {button && <Fragment>
          <SpacerH rem={0.875} />
          {button}
        </Fragment>}
    </FlexRow>;
}

export default ListRowHeader;
const sliderButtonClass = "s1akklvl";

require("../../.linaria-cache/packages/oxcart/src/components/ListRowHeader.linaria.module.css");