import useEventListener from '@commonstock/common/src/utils/useEventListener'
import useKeyPress from '@commonstock/common/src/utils/useKeyPress'

const useCloseMenu = (id: string, cb: () => void) => {
  useEventListener('click', (e: any) => !e.target.closest(id) && cb())
  useKeyPress(['Escape'], () => {
    cb()
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  })
}

export default useCloseMenu
