import { HydratedUserProfile } from '@commonstock/common/src/api/profile';
import { css } from '@linaria/core';
import Skeleton from 'src/components/Skeleton';
import { DetailCard } from 'src/components/styles';
import UserBlock from 'src/components/UserBlock';
import { TextBodyRegular, TextDisplayLarge } from 'src/composing/CSText';
import { Spacing } from 'src/composing/Spacing';
import { SpacerV } from 'src/composing/Spacing';
type Props = {
  user: HydratedUserProfile;
  title: string;
  subtitle: string;
};

function PrivateContentCard({
  user,
  title,
  subtitle
}: Props) {
  return <DetailCard>
      <UserBlock user={user} disableTooltips />
      <SpacerV />
      <Skeleton noShimmer h="auto" borderRadius={0.8125} className={textContainerClass}>
        <TextDisplayLarge>{title}</TextDisplayLarge>
        <SpacerV />
        <TextBodyRegular>{subtitle}</TextBodyRegular>
      </Skeleton>
      <SpacerV />
      <Spacing margin={[0, 0, 1]}>
        <TextSkeleton w="85%" />
        <TextSkeleton w="100%" />
        <TextSkeleton w="100%" />
        <TextSkeleton w="100%" />
        <TextSkeleton w="60%" />
      </Spacing>
    </DetailCard>;
}

function TextSkeleton({
  w
}: {
  w: string;
}) {
  return <>
      <SpacerV rem={1.125} />
      <Skeleton h={1.17} w={w} noShimmer borderRadius={1.17} />
    </>;
}

const textContainerClass = "tm2sm0p";
export default PrivateContentCard;

require("../../../.linaria-cache/packages/oxcart/src/scopes/profile/PrivateContentCard.linaria.module.css");