import { FontWeights } from './constants'

export const REM496 = '31rem'
export const REM368 = '23rem'
export const REM160 = '10rem'
export const REM144 = '9rem'
export const REM64 = '4rem'
export const REM48 = '3rem'
export const REM42 = '2.625rem'
export const REM36 = '2.25rem'
export const REM32 = '2rem'
export const REM28 = '1.75rem'
export const REM24 = '1.5rem'
export const REM22 = '1.375rem'
export const REM20 = '1.25rem'
export const REM18 = '1.125rem'
export const REM17 = '1.0625rem'
export const REM16 = '1rem'
export const REM15 = '0.938rem'
export const REM14 = '0.875rem'
export const REM13 = '0.813rem'
export const REM12 = '0.75rem'
export const REM11 = '0.688rem'
export const REM10 = '0.625rem'
export const REM8 = '0.5rem'
export const REM4 = '0.25rem'

export const ROOT_FONT_SIZE = '16px'
export const ROOT_MOBILE_FONT_SIZE = '15px'
export const ROOT_LINE_HEIGHT = 1.25

export const defaultFontFamily: CSSProperties = {
  fontFamily: "'Inter', sans-serif"
}

// @TODO: Organize this
export const $DisplayXLarge: CSSProperties = {
  fontWeight: FontWeights.Weight600,
  fontSize: REM36,
  lineHeight: REM36,
  letterSpacing: '-0.01em'
}

export const $DisplayLarge: CSSProperties = {
  fontWeight: FontWeights.Weight600,
  fontSize: REM24,
  lineHeight: REM28,
  letterSpacing: '-0.015em'
}

export const $DisplayMedium: CSSProperties = {
  fontWeight: FontWeights.Weight600,
  fontSize: REM20,
  lineHeight: REM24,
  letterSpacing: '-0.0035em'
}

export const $DisplaySmall: CSSProperties = {
  fontWeight: FontWeights.Weight500,
  fontSize: REM17,
  lineHeight: REM22,
  letterSpacing: '-0.01em'
}

export const $BodyRegular: CSSProperties = {
  fontSize: REM15,
  lineHeight: REM20
}

export const $BodyEmphasis: CSSProperties = {
  fontWeight: FontWeights.Weight600,
  fontSize: REM15,
  lineHeight: REM20
}

export const $Subhead: CSSProperties = {
  fontSize: REM14,
  lineHeight: REM20
}

export const $SubheadEmphasis: CSSProperties = {
  fontWeight: FontWeights.Weight400,
  fontSize: REM14,
  lineHeight: REM16
}

export const $CaptionEmphasis: CSSProperties = {
  fontWeight: FontWeights.Weight600,
  fontSize: REM12,
  lineHeight: REM16
}
export const $Caption: CSSProperties = {
  fontSize: REM12,
  lineHeight: REM16
}
export const $MiniRegular: CSSProperties = {
  fontSize: REM10,
  lineHeight: REM16,
  fontWeight: FontWeights.Weight400
}
export const $MiniEmphasis: CSSProperties = {
  fontWeight: FontWeights.Weight600,
  fontSize: REM10,
  lineHeight: REM16
}
