import React, { Fragment } from 'react'
import { Periods, TagItemType } from '@commonstock/common/src/types'
import { useGetTopPosts, PostFeedItem, FeedItem, FeedOutput, isPostFeedItem } from '@commonstock/common/src/api/feed'
import { SpacerV } from '../../composing/Spacing'
import { DiscoverTopPostsSkeleton } from './Discover.skeleton'
import PostCardSmall from '../post/PostCardSmall'
import { SkeletonWrapper } from '../../components/SuspenseSkeleton'
import { useGetUsersPosts } from '@commonstock/common/src/api/post'
import { useGetTaggedContent } from '@commonstock/common/src/api/tag'
import PreviewCard from 'src/components/PreviewCard'
import { Routes } from '../nav/constants'
import { destructContentFeedItem } from '../content/utils'
import { UrlObject } from 'url'
import { useGoToHome } from '../nav/navHooks'
import { PagedReturnTuple } from '@commonstock/client/src/react/createUsePagedFetch'

export enum RelatedContentTypes {
  ByAuthor = 'ByAuthor',
  ByAsset = 'ByAsset',
  ByTrending = 'ByTrending'
}

type GetTitleAndLinkProps = {
  relatedBy: RelatedContentTypes
  userUuid?: string
  symbol?: string
  type?: string
  homeLink: UrlObject
}
function getTitleAndLink({
  relatedBy,
  userUuid,
  symbol,
  type,
  homeLink
}: GetTitleAndLinkProps): [string, string | UrlObject | undefined] {
  switch (relatedBy) {
    case RelatedContentTypes.ByAuthor:
      return ['By this author', userUuid ? Routes.userPosts(userUuid) : undefined]
    case RelatedContentTypes.ByAsset:
      return ['More on this asset', symbol && type ? Routes.assetPosts(symbol, type) : undefined]
    case RelatedContentTypes.ByTrending:
      return ['Trending this week', homeLink]
    default:
      return ['', undefined]
  }
}

function useRelatedBy(
  relatedBy: RelatedContentTypes,
  { user_uuid, resources }: PostFeedItem
): PagedReturnTuple<FeedOutput, any> | null {
  // ByAuthor
  const userPosts = useGetUsersPosts(
    { query: { limit: 2, target_uuid: user_uuid || '' } },
    { paused: !user_uuid || relatedBy !== RelatedContentTypes.ByAuthor }
  )

  // ByAsset
  const { symbol, type } = Object.values(resources.assets)[0] || { symbol: '', type: '' }
  const taggedContent = useGetTaggedContent({
    query: { tag: `ASSET|${symbol}:${type}`, content_types: TagItemType.POST, limit: 3 }
  })

  // ByTrending
  const topPosts = useGetTopPosts({ query: { period: Periods['1W'], limit: 3 } })

  if (relatedBy === RelatedContentTypes.ByAuthor) return userPosts
  if (relatedBy === RelatedContentTypes.ByAsset && !symbol && !type) return taggedContent
  if (relatedBy === RelatedContentTypes.ByTrending) return topPosts
  return null
}

export default function RelatedBy({ relatedBy, feedItem }: { relatedBy: RelatedContentTypes; feedItem: PostFeedItem }) {
  const resource = useRelatedBy(relatedBy, feedItem)

  const { homeLink } = useGoToHome()
  const { user } = destructContentFeedItem(feedItem)
  const { symbol, type } = Object.values(feedItem.resources.assets)[0] || { symbol: '', type: '' }
  const [title, link] = getTitleAndLink({ relatedBy, userUuid: user?.username, symbol, type, homeLink })

  if (!resource) return null
  const items: FeedItem[] | undefined = resource.pages?.flatMap(item => item.items) || []

  return (
    <SkeletonWrapper
      pending={!resource.firstPageLoaded}
      skeleton={
        <>
          <DiscoverTopPostsSkeleton />
          <SpacerV />
        </>
      }
      failed={resource.fail || (items && !items.length)}
    >
      {items?.length && (
        <PreviewCard title={title || ''} link={link}>
          {items.filter(isPostFeedItem).map(item => (
            <Fragment key={item.uuid}>
              <SpacerV />
              <PostCardSmall postFeedItem={item} />
            </Fragment>
          ))}
        </PreviewCard>
      )}
      <SpacerV />
    </SkeletonWrapper>
  )
}
