import React, { useCallback } from 'react'
import { useUnarchiveCommentAction } from '@commonstock/common/src/api/comment'
import { useUnarchivePostAction } from '@commonstock/common/src/api/post'
import { ArchivedContentType } from '@commonstock/common/src/types'
import { MenuItem } from './styles'

import AnimatedEllipsis from './AnimatedEllipsis'
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'
import useFetchSubmit from '../hooks/useFetchSubmit'

type Props = {
  uuid: string
  contentType: ArchivedContentType
}

function Unarchive({ contentType, uuid }: Props) {
  const unarchiveComment = useUnarchiveCommentAction()
  const unarchivePost = useUnarchivePostAction()
  const refetchDependents = useRefetchByKeys('get-comment', 'get-post', 'trade')
  const { submit, pending, fail, success } = useFetchSubmit(
    contentType === ArchivedContentType.COMMENT ? unarchiveComment : unarchivePost
  )

  const onClick = useCallback(() => {
    submit({ meta: { uuid } })
    refetchDependents()
  }, [submit, uuid, refetchDependents])

  return (
    <MenuItem isModerator disabled={!!(pending || success)} onClick={pending || success ? undefined : onClick}>
      {fail ? 'Unarchive Failed' : success ? 'Unarchived' : 'Unarchive'}
      {pending && <AnimatedEllipsis />}
    </MenuItem>
  )
}

export default Unarchive
