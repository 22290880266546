// from https://gist.github.com/fupslot/5015897#gistcomment-1580216
export default function uriToBlob(dataUri: string) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataUri.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataUri.split(',')[1])
  else byteString = unescape(dataUri.split(',')[1])

  // separate out the mime component
  let mimeString = dataUri
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}
