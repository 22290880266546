import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'

export const useParams = <Params extends { [K in keyof Params]?: string } = {}>() => {
  return parseNextParams(useRouter().query) as Params
}

type ParamObject = Record<string, string>

// NOTE: if the number of colons in the key doesn’t match the ones in the value then we end up chopping off the value
const parseNextParams = (params: ParsedUrlQuery) => {
  let returnParams = {} as ParamObject
  let parsedKeys, parsedValues
  // @TODO: identify catch all parameters
  for (const [keys, values] of Object.entries(params)) {
    parsedKeys = keys.split(':')
    parsedValues = (Array.isArray(values) ? values[0] : values)?.split(':') || []
    for (const index in parsedKeys) {
      returnParams[parsedKeys[index]] = parsedValues[index]
    }
  }
  return returnParams
}
