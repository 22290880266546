import React, { useCallback, Ref } from 'react';
import { css, cx } from '@linaria/core';
import { EventObject, track } from '../scopes/analytics/mixpanel';
import Link, { LinkProps } from 'next/link';
import { format } from 'url';
import { addProtocol } from '../scopes/text-editor/csMarkdown';
import { HTMLAnchorProps } from 'src/utils/types';
type Props = Omit<HTMLAnchorProps, 'href'> & React.PropsWithChildren<LinkProps> & {
  external?: boolean;
  unstyled?: boolean;
  capture?: boolean;
  clickEvent?: EventObject;
  className?: string;
  onClick?: (e: any) => void;
};

function CSLink({
  children,
  href,
  external,
  className,
  unstyled,
  clickEvent,
  capture,
  onClick,
  ...rest
}: Props, ref: Ref<HTMLAnchorElement>) {
  const _onClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (clickEvent) {
      let {
        event,
        ...eventData
      } = clickEvent;
      track(event, eventData);
    }

    if (capture) e.stopPropagation();
    if (onClick) onClick(e);
  }, [clickEvent, capture, onClick]);

  const stringUrl = typeof href === 'string' ? addProtocol(href) : format(href || '');
  const classList = cx(className, unstyled && linkDestyle);
  const formattedHref = typeof href === 'string' ? addProtocol(href) : href;
  const externalProps = external ? {
    target: '_blank',
    rel: 'nofollow noopener noreferrer'
  } : {};
  return <Link href={formattedHref} {...rest} passHref={external}>
      <a ref={ref} href={stringUrl} className={classList} onClick={_onClick} {...externalProps} {...rest}>
        {children}
      </a>
    </Link>;
}

export default React.forwardRef(CSLink);
const linkDestyle = "lehlhed";

require("../../.linaria-cache/packages/oxcart/src/composing/CSLink.linaria.module.css");