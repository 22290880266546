import { ActionModal } from '../modal/Modals'
import { TextBodyRegular } from 'src/composing/CSText'
import { SpacerV } from 'src/composing/Spacing'
import { useModal } from 'src/scopes/modal/Modal'
import { DangerButton } from 'src/composing/CSButton'
import { useState } from 'react'
import { useDeleteUser, UserProfile } from '@commonstock/common/src/api/profile'
import { captureException } from '@sentry/react'

export enum DeleteAction {
  Delete = 'delete'
}

type Props = {
  profile: UserProfile
  action: DeleteAction
}

function DeleteModal({ profile }: Props) {
  const { actions: modalActions } = useModal()
  const deleteUser = useDeleteUser()
  const [pending, setPending] = useState(false)
  const [fail, setFail] = useState(false)

  const onClick = async () => {
    try {
      setPending(true)
      const params = { meta: { uuid: profile.user_uuid } }
      await deleteUser(params)
      modalActions.close()
    } catch (err) {
      captureException(err)
      setFail(true)
      setPending(false)
    }
  }

  const text = `@${profile.username} will be deleted from Commonstock`

  return (
    <ActionModal title={`Delete User`}>
      <TextBodyRegular centered>{text}</TextBodyRegular>
      <SpacerV rem={1.5} />
      <DangerButton block large onClick={onClick} loading={pending}>
        {fail ? 'Delete Failed' : 'Delete'}
      </DangerButton>
    </ActionModal>
  )
}

export default DeleteModal
