import React from 'react'
import { Message } from './types'
import { useMessageComparing } from './chatHooks'
import { Colors } from '../../theme/constants'
import { TextCaption } from '../../composing/CSText'
import { DividerContainer } from './AdminMessage'

type DateDividersProps = {
  message: Message
}
function DateDivider({ message }: DateDividersProps) {
  const { getDateString } = useMessageComparing()

  return (
    <DividerContainer>
      <TextCaption color={Colors.TextSecondary}>{getDateString(message)}</TextCaption>
    </DividerContainer>
  )
}

export default React.memo(DateDivider)
