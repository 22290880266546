import { FeedResourceType, PostFeedItem, PostResource, TradeFeedItem } from '@commonstock/common/src/api/feed'
import { useMemo } from 'react'
import LinkedResourceCarousel from 'src/components/LinkedResourceCarousel'
import { SpacerV } from 'src/composing/Spacing'
import PostCardSmall from './PostCardSmall'

type Props = {
  tradeFeedItem: TradeFeedItem
  posts: PostResource[]
}

function LinkedPosts({ tradeFeedItem, posts }: Props) {
  const postfeedItems: PostFeedItem[] = useMemo(() => {
    return posts.map(item => ({
      ...tradeFeedItem,
      resource_type: FeedResourceType.POST,
      uuid: item.uuid,
      user_uuid: item.user_uuid
    }))
  }, [posts, tradeFeedItem])

  if (!postfeedItems.length) return null
  return (
    <>
      <LinkedResourceCarousel height={5.9375}>
        {postfeedItems.map(item => (
          <PostCardSmall key={item.uuid} postFeedItem={item} noBorder isLinked />
        ))}
      </LinkedResourceCarousel>
      <SpacerV />
    </>
  )
}

export default LinkedPosts
