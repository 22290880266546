import { track } from '../mixpanel'
import { CreatePostPayload } from '@commonstock/common/src/api/post'

type PostPublishedObject = CreatePostPayload & {
  related_trade_count: number
}

export function postPublished(data: PostPublishedObject) {
  track('Memo Published', {
    text_length: data.summary.length,
    memo_id: data.uuid,
    mentioned_asset_count: data.asset_mentions.length,
    mentioned_assets: data.asset_mentions.map(am => `${am.symbol}:${am.type}`),
    mentioned_user_count: data.user_mentions.length,
    mentioned_users: data.user_mentions.map(um => um.uuid),
    related_trade_count: data.related_trade_count,
    title: data.title
  })
}
