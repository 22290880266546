import React from 'react'
import { useParams } from '../../utils/useParams'
import { DetailCard } from '../../components/styles'
import { useGetTrade } from '@commonstock/common/src/api/trade'
import { BrandSpinner } from '../../components/Spinner'
import { Spacing } from '../../composing/Spacing'
import { ParentTypes, useGetComment } from '@commonstock/common/src/api/comment'
import { cx } from '@linaria/core'
import RootComments from '../comments/RootComments'
import { useTrackTradeDetailImpression } from '../analytics/events.v1'
import CommentItem from '../comments/CommentItem'
import { useAuth } from '../auth/AuthContext'
import { TradeFeedItem } from '@commonstock/common/src/api/feed'
import NotFound from '../../components/NotFound'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { TradeContent } from './TradeCard'
import { DetailContentWrapper } from '../content/DetailContentWrapper'
import { CardLayoutTypes } from '../feed/constants'

type Params = { tradeId: string; commentId?: string }

type TradeDetailProps = {
  isModal?: boolean
  tradeFeedItem?: TradeFeedItem
  uuid?: string
  cardLayout: CardLayoutTypes
}
function TradeDetail({ isModal, tradeFeedItem, uuid, cardLayout }: TradeDetailProps) {
  const { commentId, tradeId } = useParams<Params>()
  const { isModerator } = useAuth()

  const tradeUuid = uuid || tradeId || tradeFeedItem?.uuid || ''

  const [fetchTrade, failTrade] = useGetTrade(
    { meta: { tradeId: tradeUuid } },
    { paused: (tradeFeedItem && !isLoggedIn()) || !tradeUuid }
  )

  const [fetchComment, failComment] = useGetComment({ meta: { uuid: commentId || '' } }, { paused: !commentId })
  tradeFeedItem = fetchTrade || tradeFeedItem
  const initialComments = tradeFeedItem?.resources.trades[tradeFeedItem.uuid]?.comments
  const rootUserUuid = tradeFeedItem?.user_uuid || ''

  useTrackTradeDetailImpression(tradeFeedItem)

  if (!isModal && (failTrade || failComment)) {
    return <NotFound />
  }
  if (isModal && failTrade) {
    return (
      <DetailCard className={isModal && 'modal'}>
        <NotFound />
      </DetailCard>
    )
  }
  if (!tradeFeedItem || (commentId && !fetchComment))
    return (
      <DetailCard className={cx(isModal && 'modal', 'loading')}>
        <BrandSpinner />
      </DetailCard>
    )
  if (isModerator && fetchComment?.archived_at) {
    return (
      <Spacing padding={[0, 1, 1]}>
        <DetailCard>
          <CommentItem
            comment={fetchComment}
            rootType={ParentTypes.TRADE}
            rootUuid={tradeUuid}
            rootUserUuid={rootUserUuid}
            cardLayout={cardLayout}
          />
        </DetailCard>
      </Spacing>
    )
  }
  return (
    <DetailContentWrapper feedItem={tradeFeedItem} isModal={isModal} cardLayout={cardLayout}>
      <TradeContent tradeFeedItem={tradeFeedItem} detail cardLayout={cardLayout} />
      <RootComments
        initialComments={initialComments}
        uuid={tradeUuid}
        type={ParentTypes.TRADE}
        isModal={!!isModal}
        rootUserUuid={rootUserUuid}
        cardLayout={cardLayout}
      />
    </DetailContentWrapper>
  )
}

export default TradeDetail
