import { TextSubhead, TextSubheadEmphasis } from '../../composing/CSText';
import { Colors } from '../../theme/constants';
import { css } from '@linaria/core';
import { SecondaryButton } from 'src/composing/CSButton';
import { isIphone } from '../mobile/useMobileAppLink';
import { useRouter } from 'next/router';
import config from 'src/config';
import { Routes } from '../nav/constants';
import CSLink from 'src/composing/CSLink';
import { useLoggedOutAction } from '../analytics/events/loggedOutAction';
import { LightMode } from 'src/theme/ThemeContext';

function BannerPublic() {
  const router = useRouter();
  const url = isIphone ? `${config.appLinkDomain}/open?destination=${router.asPath}` : Routes.Login;
  const loggedOutAction = useLoggedOutAction();

  const handleButtonClick = () => {
    loggedOutAction({
      action_type: isIphone ? 'Download' : 'Join',
      tap_location: 'Banner'
    });
  };

  const handleLoginClick = () => {
    loggedOutAction({
      action_type: 'Login',
      tap_location: 'Banner'
    });
  };

  return <LightMode className={containerClass}>
      <CSLink href={url} external={isIphone} unstyled onClick={handleButtonClick}>
        <SecondaryButton large>{isIphone ? 'Download Commonstock' : 'Join Commonstock'}</SecondaryButton>
      </CSLink>
      <TextSubhead>
        Already have an account?{' '}
        <CSLink href={url} external={isIphone} unstyled onClick={handleLoginClick}>
          <TextSubheadEmphasis>Log in</TextSubheadEmphasis>
        </CSLink>
      </TextSubhead>
    </LightMode>;
}

const containerClass = "c7ayedf";
export default BannerPublic;

require("../../../.linaria-cache/packages/oxcart/src/scopes/public/BannerPublic.linaria.module.css");