import React, { createContext, ReactNode, useState, useMemo, useContext, useCallback } from 'react'

type ChartHoverValues = {
  hoverIndex: number | null
  setHoverIndex?: (n: number | null) => void
}

const ChartHoverContext = createContext<ChartHoverValues>({ hoverIndex: null })
ChartHoverContext.displayName = 'ChartHoverContext'

export function ChartHoverProvider({ children }: { children: ReactNode }) {
  const [hoverIndex, _setHoverIndex] = useState<number | null>(null)

  const setHoverIndex = useCallback((n: number | null) => _setHoverIndex(n), [])

  const value = useMemo(
    () => ({
      hoverIndex,
      setHoverIndex
    }),
    [hoverIndex, setHoverIndex]
  )

  return <ChartHoverContext.Provider value={value}>{children}</ChartHoverContext.Provider>
}

export function useChartHover() {
  const context = useContext(ChartHoverContext)
  return context
}
