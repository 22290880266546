import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Likes } from '@commonstock/common/src/api/like'
import { LikeTypes } from '@commonstock/common/src/api/like'

// Safari doesnt support it yet, but it wont need to change once it starts supporting
const bc =
  typeof window !== 'undefined' && 'BroadcastChannel' in window ? new BroadcastChannel('cs:like-channel') : null

type MappedLike = {
  [parentUuid: string]: Likes
}

type ParentLikeMaps = {
  [key in LikeTypes]: MappedLike
}

type LikeValue = {
  parentLikeMaps: ParentLikeMaps
  updateLikes: (mappedLike: MappedLike, parentType: LikeTypes) => void
  removeLikes: (parentUuid: string, parentType: LikeTypes) => void
}

// @ts-ignore typescript doesnt know fromEntries
const initialParentLikeMaps = Object.fromEntries(Object.values(LikeTypes).map(v => [v, {}])) as ParentLikeMaps

const LikeContext = createContext<LikeValue>({
  parentLikeMaps: initialParentLikeMaps,
  updateLikes: () => null,
  removeLikes: () => null
})
LikeContext.displayName = 'LikeContext'

type UpdateLikesEvent = {
  type: 'updateLikes'
  payload: {
    mappedLike: MappedLike
    parentType: LikeTypes
  }
}
type RemoveLikesEvent = {
  type: 'removeLikes'
  payload: {
    parentUuid: string
    parentType: LikeTypes
  }
}

const LikeProvider = ({ children }: { children: React.ReactNode }) => {
  const [parentLikeMaps, setParentLikeMaps] = useState<ParentLikeMaps>(initialParentLikeMaps)

  const updateLikes = useCallback(
    (mappedLike: MappedLike, parentType: LikeTypes, preventEvent?: boolean) => {
      if (bc && !preventEvent) {
        const updateLikesEvent: UpdateLikesEvent = { type: 'updateLikes', payload: { mappedLike, parentType } }
        bc.postMessage(JSON.stringify(updateLikesEvent))
      }
      setParentLikeMaps(prevParentLikeMaps => ({
        ...prevParentLikeMaps,
        [parentType]: {
          ...prevParentLikeMaps[parentType],
          ...mappedLike
        }
      }))
    },
    [setParentLikeMaps]
  )

  const removeLikes = useCallback(
    (parentUuid: string, parentType: LikeTypes, preventEvent?: boolean) => {
      if (bc && !preventEvent) {
        const removeLikesEvent: RemoveLikesEvent = { type: 'removeLikes', payload: { parentUuid, parentType } }
        bc.postMessage(JSON.stringify(removeLikesEvent))
      }
      setParentLikeMaps(prevParentLikeMaps => {
        const newMap = { ...prevParentLikeMaps }
        delete newMap[parentType][parentUuid]
        return newMap
      })
    },
    [setParentLikeMaps]
  )

  useEffect(() => {
    if (!bc) return
    bc.onmessage = function(ev) {
      const data: UpdateLikesEvent | RemoveLikesEvent = JSON.parse(ev.data)
      if (data.type === 'removeLikes') removeLikes(data.payload.parentUuid, data.payload.parentType, true)
      if (data.type === 'updateLikes') updateLikes(data.payload.mappedLike, data.payload.parentType, true)
    }
  }, [removeLikes, updateLikes])

  const value = useMemo(
    () => ({
      parentLikeMaps,
      updateLikes,
      removeLikes
    }),
    [parentLikeMaps, updateLikes, removeLikes]
  )

  return <LikeContext.Provider value={value}>{children}</LikeContext.Provider>
}

const useLikeMap = () => {
  const context = useContext(LikeContext)
  if (!context) throw new Error('useLikeMap mising parent LikeProvider')
  return context
}

export { LikeProvider, useLikeMap }
