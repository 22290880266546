import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { UserMessage } from 'sendbird'

type EditMessageValue = {
  editMessage: UserMessage | null
  setEditMessage: (message: UserMessage | null) => void
}

const EditMessageContext = createContext<EditMessageValue>({
  editMessage: null,
  setEditMessage: () => {}
})
EditMessageContext.displayName = 'EditMessageContext'

const EditMessageProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState<UserMessage | null>(null)

  const setEditMessage = useCallback(message => {
    setMessage(message)
  }, [])

  const value: EditMessageValue = useMemo(
    () => ({
      editMessage: message,
      setEditMessage
    }),
    [message, setEditMessage]
  )

  return <EditMessageContext.Provider value={value}>{children}</EditMessageContext.Provider>
}

const useEditMessage = () => {
  const context = useContext(EditMessageContext)
  if (!context) throw new Error('useEditMessage missing parent EditMessageProvider')
  return context
}

export { EditMessageProvider, useEditMessage }
