import { useRef, useReducer, MouseEvent } from 'react'
import { reducer, initialState } from './reducer'
import { pan, startPan, zoom } from './actions'

const ZOOM_FACTOR = 2

const useImageZoom = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const containerRef = useRef<HTMLDivElement>(null)

  const onMouseMoveInWindow = (event: globalThis.MouseEvent) => {
    event.preventDefault()
    // @ts-ignore
    dispatch(pan(event))
  }
  const onMouseUpInWindow = () => {
    window.removeEventListener('mouseup', onMouseUpInWindow)
    window.removeEventListener('mousemove', onMouseMoveInWindow)
  }
  const onMouseDown = (event: MouseEvent) => {
    const isRightClick = event.button !== 0
    if (isRightClick) {
      return
    }

    // @ts-ignore
    dispatch(startPan(event))
    window.addEventListener('mouseup', onMouseUpInWindow)
    window.addEventListener('mousemove', onMouseMoveInWindow)
  }
  const onZoomClick = (event: MouseEvent) => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect()
      dispatch(zoom(event, containerRect, ZOOM_FACTOR))
    }
  }
  return {
    ...state,
    containerRef,
    onMouseDown,
    onZoomClick
  }
}

export default useImageZoom
