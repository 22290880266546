import { TradeFeedItem, PostFeedItem, FeedResourceType } from '@commonstock/common/src/api/feed'

export function convertResourcesToFeedItems(feedItem: PostFeedItem | TradeFeedItem) {
  const { resources } = feedItem
  const postFeedItems: PostFeedItem[] = Object.values(resources.memos).map(item => ({
    ...feedItem,
    created_at: item.created_at,
    user_uuid: item.user_uuid,
    uuid: item.uuid,
    resource_type: FeedResourceType.POST
  }))
  const tradeFeedItems: TradeFeedItem[] = Object.values(resources.trades).map(trade => ({
    ...feedItem,
    created_at: trade.created_at,
    user_uuid: trade.user_uuid,
    uuid: trade.uuid,
    resource_type: FeedResourceType.TRADE
  }))
  return { postFeedItems, tradeFeedItems }
}
