import React from 'react';
import { ActionModal } from '../modal/Modals';
import { SpacerH, SpacerV } from '../../composing/Spacing';
import { PrimaryButton, SecondaryButton } from '../../composing/CSButton';
import { CSText } from '../../composing/CSText';
import { useModal } from '../modal/Modal';
import { flexJustified } from '../../theme/AtomicClasses';
import { css } from '@linaria/core';
import { Colors } from '../../theme/constants';

const DiscardPost = ({
  onDiscard
}: {
  onDiscard?: () => void;
}) => {
  const {
    actions
  } = useModal();

  const onClickCloseAll = () => {
    onDiscard && onDiscard(); // Only close two modals. 1. the discard modal, 2. the edit post modal.

    actions.close();
    actions.close();
  };

  const onClickClose = () => actions.close();

  return <ActionModal title="Leave Post?">
      <CSText centered>You didn’t share your post yet. Are you sure you want to leave without posting?</CSText>
      <SpacerV />
      <div className={flexJustified}>
        <SecondaryButton block large onClick={onClickClose}>
          Continue Editing
        </SecondaryButton>
        <SpacerH rem={1} />
        <PrimaryButton className={redButton} block large onClick={onClickCloseAll}>
          Discard
        </PrimaryButton>
      </div>
    </ActionModal>;
};

export default DiscardPost;
const redButton = "rjtyrn9";

require("../../../.linaria-cache/packages/oxcart/src/scopes/post/DiscardPost.linaria.module.css");