import { useCallback } from 'react'
import { useAssetSearchAction } from '@commonstock/common/src/api/market'
import { useProfileSearchAction } from '@commonstock/common/src/api/profile'
import { Asset } from '@commonstock/common/src/types'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { usePersistedObjectState } from '@commonstock/common/src/utils/usePersistedState'

const RecentSearchesKey = 'cs:recent-searches:2'

export type RecentSearch = {
  name: string
  asset?: Asset
  profile?: HydratedUserProfile
}

export const useSearch = () => {
  const [recentSearches, setRecentSearches] = usePersistedObjectState<RecentSearch[]>(RecentSearchesKey, [])

  const addToRecentSearches = useCallback(
    (recentSearch: RecentSearch) => {
      const newRecentSearches = recentSearches.length
        ? [recentSearch, ...recentSearches.filter((item: RecentSearch) => item.name !== recentSearch.name)].slice(0, 3)
        : [recentSearch]
      setRecentSearches(newRecentSearches)
    },
    [recentSearches, setRecentSearches]
  )

  const removeFromRecentSearches = useCallback(
    (recentSearch: RecentSearch) => {
      const newRecentSearches = recentSearches.filter((item: RecentSearch) => item.name !== recentSearch.name)
      setRecentSearches(newRecentSearches)
    },
    [recentSearches, setRecentSearches]
  )

  return { addToRecentSearches, removeFromRecentSearches, recentSearches }
}

export const useSearchResults = () => {
  const assetSearch = useAssetSearchAction()
  const profileSearch = useProfileSearchAction()

  const search = useCallback(
    async (search: string) => {
      try {
        let assetRes, profileRes
        try {
          assetRes = await assetSearch({ query: { term: search, limit: 3 } })
        } catch (error) {
          console.log('## Error on assetSearch', error)
        }
        try {
          profileRes = await profileSearch({ meta: { search } })
        } catch (error) {
          console.log('## Error on profileSearch', error)
        }
        return {
          assets: assetRes?.success?.payload ?? [],
          profiles: profileRes?.success?.payload.slice(0, 10) ?? []
        }
      } catch (err) {
        console.error('## searchTerm err:', err)
      }
    },
    [assetSearch, profileSearch]
  )

  return search
}
